import React, { useState } from "react";
import {
  Typography,
  Modal,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  alpha,
} from "@mui/material";
import { COLORS } from "../utils/colors";
import RedClose from "./RedClose";
import DeleteIcon from "@mui/icons-material/Delete"; // Add this import
import toast from "react-simple-toasts";

const SelectFolderModal = ({
  open,
  note,
  deleteOnly,
  currentState,
  setActiveFolder,
  notes,
  onClose,
  removeRender,
  setRemoveRender,
  folder,
  handleRemoveNoteFromFolder,
  folders,
  handleSelectFolder,
  handleDeleteNote,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showError, setShowError] = useState(false);
  
  const processHandleSelectFolder = (folder, note) => {
    // update the folder in the db
    handleSelectFolder(folder, note);
    setConfirmDelete(false);
    setActiveFolder(null);
    // close the modal
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setRemoveRender(false);
        setConfirmDelete(false);
        setShowError(false);
        setActiveFolder(null);
        onClose();
      }}
      sx={{
        display: "flex",
        
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          maxHeight: '50%',
          flexDirection: "column",
          backgroundColor: "#1E1E1E",
          borderRadius: "16px",
          width: isMobile ? "90%" : "400px",
          padding: "32px",
          boxShadow: "0 4px 30px rgba(0,0,0,0.3)",
          position: "relative",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: alpha(COLORS.nicePurple, 0.5),
            borderRadius: "2px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: COLORS.nicePurple,
          },
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <RedClose
            handleClose={() => {
              setConfirmDelete(false);
              setShowError(false);
              setActiveFolder(null);
              onClose();
            }}
          />
        </Box>

        <Typography
          variant="h5"
          sx={{ mb: 3, fontWeight: 700, textAlign: "center", color: "#FFFFFF" }}
        >
          {removeRender ? "Remove from folder" :  deleteOnly ? "Delete Note" : "Add to Folder"}
        </Typography>

        {!deleteOnly && !removeRender && (
          <List>
            {folders &&
              Object.values(folders).map((folder) => (
                <ListItem
                  button
                  key={folder.id}
                  onClick={() => processHandleSelectFolder(folder, note)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                  }}
                >
                  <ListItemText
                    primary={folder.name}
                    sx={{ color: "#FFFFFF" }}
                  />
                </ListItem>
              ))}
          </List>
        )}

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => {
              if (removeRender) {
                console.log("removeRender", folder, note);
                handleRemoveNoteFromFolder(folder, note);
                setConfirmDelete(false);
                setShowError(false);
                setActiveFolder(null);
                onClose();
                return;
              }

              if (confirmDelete) {
                handleDeleteNote(note);
                setConfirmDelete(false);
                setShowError(false);
                setActiveFolder(null);
                onClose();
              } else {
                // if the user is trying to delete the only note open, then we should not allow it
                if (
                  currentState.openFiles.length === 1 &&
                  currentState.openFiles[0].id === note.id
                ) {
                  setShowError(true);
                  return;
                }
                setConfirmDelete(true);
              }
            }}
            sx={{
              color: confirmDelete ? COLORS.primaryRed : COLORS.primaryRed,
              marginTop: "1rem",
              borderColor: confirmDelete
                ? COLORS.primaryRed
                : COLORS.primaryRed,
              "&:hover": {
                backgroundColor: "rgba(255, 0, 0, 0.1)",
                borderColor: COLORS.primaryRed,
              },
            }}
          >
            {removeRender ? "Remove from folder" : confirmDelete ? "Confirm Delete" : "Delete Note"}
          </Button>
        </Box>
        {showError && (
          <Typography
            sx={{
              textAlign: "center",
              color: "white",
              fontSize: "12px",
              marginTop: "8px",
            }}
          >
            Must have atleast 1 note open
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default SelectFolderModal;
