import React, { useContext, useRef, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import GlobalStateContext from "../context/globalStateContext";
import UserContext from "../context/userContext";
import { firebase, firestore } from "../firebaseConfig";
import toast from "react-simple-toasts";

const InvitePeopleModal = ({ open, onClose }) => {
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { user } = useContext(UserContext);
  const [invitees, setInvitees] = useState([""]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRefs = useRef([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [message, setMessage] = useState("");

  const handleKeyPress = (event, index) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index === invitees.length - 1 && invitees[index].trim() !== "") {
        handleAddInvitee();
        setTimeout(() => {
          inputRefs.current[index + 1]?.focus();
        }, 0);
      }
    }
  };

  const handleAddInvitee = () => {
    setInvitees([...invitees, ""]);
  };

  const handleRemoveInvitee = (index) => {
    const newInvitees = invitees.filter((_, i) => i !== index);
    setInvitees(newInvitees);
  };

  const handleInviteeChange = (index, value) => {
    const newInvitees = [...invitees];
    newInvitees[index] = value;
    setInvitees(newInvitees);
  };

  const handleFinish = async () => {
    try {
      setIsLoading(true);
  
      const filteredInvitees = invitees.filter((email) => email.trim() !== "");
  
      if (filteredInvitees.length === 0) {
        toast("Please enter at least one valid email address.", { position: "top-center" });
        setIsLoading(false);
        return;
      }
  
      const inviteMeRef = firestore.collection("generalInvites").doc(user.id);
  
      // Create a new entry for this invitation
      await inviteMeRef.set({
        [`invitations.${Date.now()}`]: {
          emails: filteredInvitees,
          message: message.trim(),
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        }
      }, { merge: true });
  
      setGlobalState({
        ...globalState,
        showInvitePeopleModal: false
      })
      setInvitees([""]);
      setMessage("");
      toast("Invites sent successfully", { position: "top-right" });
  
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Error sending invites:", error);
      toast("Error sending invites. Please try again.", {
        position: "top-center",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      open={globalState?.showInvitePeopleModal}
      onClose={() => setGlobalState({ ...globalState, showInvitePeopleModal: false })}
      sx={styles.modal}
    >
      <Box sx={styles.modalContent(isMobile)}>
        <Typography variant="h4" sx={styles.title}>
          Invite Your People
        </Typography>
        <Box sx={styles.formContainer}>
          {invitees.map((invitee, index) => (
            <Box key={index} sx={styles.inviteeRow}>
              <TextField
                fullWidth
                autoComplete="off"
                label="Enter email"
                value={invitee}
                onChange={(e) => handleInviteeChange(index, e.target.value)}
                onKeyPress={(e) => handleKeyPress(e, index)}
                inputRef={(el) => (inputRefs.current[index] = el)}
                margin="normal"
                sx={styles.input}
              />
              <IconButton
                onClick={() => handleRemoveInvitee(index)}
                sx={styles.actionButton}
                disabled={invitees.length === 1}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              {index === invitees.length - 1 && (
                <IconButton onClick={handleAddInvitee} sx={styles.actionButton}>
                  <AddCircleOutlineIcon />
                </IconButton>
              )}
            </Box>
          ))}
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Add a personal message (optional)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            sx={styles.messageInput}
          />
        </Box>
        <Box sx={styles.fixedFooter}>
          <Button
            variant="contained"
            disabled={isLoading}
            sx={styles.submitButton}
            onClick={handleFinish}
            fullWidth
          >
            <Typography sx={{ color: "white", fontWeight: 600 }}>
              {isLoading ? "Processing..." : "Send Invites"}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    bgcolor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: (isMobile) => ({
    bgcolor: "background.paper",
    borderRadius: isMobile ? "0px" : "16px",
    width: isMobile ? "100%" : "500px",
    height: "70%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    p: 3,
  }),
  messageInput: {
    marginTop: 2,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E0E0E0",
      },
      "&:hover fieldset": {
        borderColor: "#2196F3",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#21CBF3",
      },
    },
  },
  inviteeRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 1,
  },
  actionButton: {
    color: "#2196F3",
    "&:hover": {
      color: "#21CBF3",
    },
  },
  formContainer: {
    flex: 1,
    overflowY: "auto",
    marginTop: 2,
    paddingRight: 2,
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "linear-gradient(45deg, #2196F3, #21CBF3)",
      borderRadius: "4px",
    },
  },
  submitButton: {
    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
    textTransform: "none",
    padding: "10px 20px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(33, 150, 243, 0.2)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "linear-gradient(45deg, #21CBF3, #2196F3)",
      boxShadow: "0 4px 6px rgba(33, 150, 243, 0.3)",
      transform: "translateY(-2px)",
    },
  },
  title: {
    mb: 3,
    fontWeight: 700,
    textAlign: "center",
    color: "#333",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E0E0E0",
      },
      "&:hover fieldset": {
        borderColor: "#2196F3",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#21CBF3",
      },
    },
  },
  fixedFooter: {
    marginTop: 2,
  },
};

export default InvitePeopleModal;