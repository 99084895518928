import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { COLORS } from "../utils/colors";
import styled, { keyframes, css } from "styled-components";
import GlobalStateContext from "../context/globalStateContext";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import ReactPlayer from "react-player";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Footer from "../components/Footer";
import { Box } from "@mui/material";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css";
import { auth, googleProvider } from "../firebaseConfig"
import { useLocation, useNavigate } from "react-router-dom";
import {
  companyLogos,
  FAQuestions,
  MOST_POWERFUL_AI_MODEL,
  quotes,
  uniLogos,
  videoFeatures,
} from "../utils/constants";
import SignupModal from "../components/SignupModal";
import UserContext from "../context/userContext";
import {
  fetchUserInfo,
  handleSubmit,
  logoutFirebase,
} from "../utils/helperMethods";
import LogoutModal from "../components/LogoutModal";
import FAQSection from "../components/FAQSection";
import GoogleSignInButton from "../components/GoogleSignInButton";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  background-color: ${COLORS.niceDeepBlack};
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${COLORS.niceWhite};
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  text-align: center;
  z-index: 1;
`;

const Title = styled.h1`
  font-size: clamp(3rem, 10vw, 8rem);

  font-weight: 900;
  letter-spacing: -0.02em;
  margin-bottom: 0.5rem;
  animation: ${fadeIn} 0.8s ease-out;
`;

const AnimatedTextField = styled(TextField)`
  animation: ${fadeIn} 0.8s ease-out 0.6s both;
`;

const Dot = styled.span`
  color: ${COLORS.nicePink};
  display: inline-block;
  animation: ${fadeIn} 0.8s ease-out 0.2s both;
`;

const GradientButton = styled.button`
  background: linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  color: ${COLORS.niceWhite};
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  animation: ${fadeIn} 0.8s ease-out 0.6s both;

  &:hover {
    opacity: 0.9;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(188, 144, 207, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(227, 132, 163, 0.4);
  }
`;


const Subtitle = styled.h2`
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-weight: 400;
  color: ${COLORS.nicePurple};
  margin-bottom: 2rem;
  opacity: 0.8;
  animation: ${fadeIn} 0.8s ease-out 0.4s both;
`;

const Button = styled.button`
  background-color: ${COLORS.nicePink};
  color: ${COLORS.niceDeepBlack};
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  animation: ${fadeIn} 0.8s ease-out 0.6s both;

  &:hover {
    background-color: ${COLORS.niceLightPurple};
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(188, 144, 207, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(227, 132, 163, 0.4);
  }
`;

const AccentShape = styled.div`
  position: absolute;
  background-color: ${COLORS.nicePurple};
  width: 300px;
  height: 300px;
  border-radius: 50%;
  opacity: 0.05;
  top: -10%;
  left: -10%;
  animation: ${fadeIn} 1s ease-in 0.8s both;
`;

const FloatingSpeckle = styled.div`
  position: absolute;
  background-color: ${COLORS.nicePink};
  width: ${(props) => props.$size || 6}px;
  height: ${(props) => props.$size || 6}px;
  border-radius: 50%;
  opacity: ${(props) => props.$opacity || 0.6};
  ${({ $top, $left, $delay }) => css`
    top: ${$top}%;
    left: ${$left}%;
    animation: ${fadeIn} 1.2s ease-in ${$delay}s both;
  `}
`;

const Header = styled.header`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  gap: 2rem; // Add gap between elements
`;

const SignupButton = styled(GradientButton)``;

const LogoutButton = styled(GradientButton)``;

const MainSection = styled.section`
  min-height: 100vh;
  background-color: ${COLORS.niceDeepBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; // Add this line
  max-width: 100vw; // Add this line
  overflow-x: hidden; // Add this line
`;

const VideoSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8rem;
  margin: 5rem 0;
  width: 100%;
  max-width: 1400px;
  padding: 0 2rem;
  box-sizing: border-box; // Add this line
`;

const VideoContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 5rem;
  padding: 3rem;
  background: transparent;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 100%; // Add this line
  box-sizing: border-box; // Add this line

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: transparent;
    z-index: -1;
    border-radius: 32px;
    opacity: 0.5;
    filter: blur(10px);
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 0.7;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    &:nth-child(even) {
      flex-direction: column;
    }
  }
`;

const VideoWrapper = styled(motion.div)`
  width: 55%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const StyledReactPlayer = styled(ReactPlayer)`
  border-radius: 20px;
  overflow: hidden;
`;

const TextContent = styled(motion.div)`
  width: 45%;
  color: ${COLORS.niceWhite};

  @media (max-width: 1024px) {
    width: 100%;
    text-align: center;
  }
`;

const VideoTitle = styled.h3`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`;

const VideoDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  opacity: 0.9;
  margin-bottom: 2rem;
`;

const FeatureButton = styled(motion.button)`
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  border: none;
  padding: 0.8rem 1.5rem;
  color: ${(props) => props.color || COLORS.niceWhite};
  font-size: 1rem;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
`;

const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin: 4rem 0;
  padding: 0rem 2rem 0rem 2rem;
  background: transparent;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  //   height: 80px;
  color: ${COLORS.niceWhite};
  opacity: 0.6;
  transition: all 0.4s ease;

  &:hover {
    opacity: 1;
    transform: translateY(-5px);
    color: ${COLORS.nicePink};
  }

  svg {
    font-size: 2.5rem;
  }
`;

const PricingLink = styled(motion.span)`
  color: ${COLORS.niceWhite};
  cursor: pointer;
  position: relative;
  font-weight: 500;
  animation: ${fadeIn} 0.8s ease-out 0.6s both;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: ${COLORS.nicePink};
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: ${(props) => props.fontSize || "inherit"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
  margin-top: ${(props) => props.marginTop || "0"};
  opacity: 0.8;
    font-weight: ${(props) => props.fontWeight || "inherit"};
  text-align: "center"
  animation: ${fadeIn} 0.8s ease-out 0.4s both;
`;

const AnimatedGradientText = styled(GradientText)`
  animation: ${fadeIn} 0.8s ease-out 0.8s both;
`;

const CenteredGradientText = styled(GradientText)`
  text-align: center;
  width: 100%;
  padding: 2rem 0rem 0rem 0rem;
  box-sizing: border-box;
`;

const QuoteSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
  width: 100%;
  max-width: 800px;
`;

const QuoteContainer = styled(motion.div)`
  text-align: center;
`;

const QuoteText = styled(GradientText)`
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  color: ${COLORS.niceWhite};
`;

const QuoteAuthor = styled(Typography)`
  font-style: italic;
`;

const NotesButton = styled(GradientButton)`
  background: linear-gradient(90deg, ${COLORS.nicePink}, ${COLORS.nicePurple});
`;

const FeatureContainer = ({ feature, index, onClick }) => {
  const navigate = useNavigate()
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, delay: index * 0.2 },
    },
  };

  const videoVariants = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: { duration: 0.6, delay: 0.2 },
    },
  };

  const textVariants = {
    hidden: { x: index % 2 === 0 ? 50 : -50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.6, delay: 0.4 },
    },
  };

  return (
    <VideoContainer
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={controls}
    >
      <VideoWrapper variants={videoVariants}>
        <StyledReactPlayer
          url={feature.videoUrl}
          width="100%"
          height="100%"
          playing={true}
          loop={true}
          muted={true}
          controls={false}
          playsinline={true}
          config={{
            file: {
              attributes: {
                preload: "auto",
              },
            },
          }}
        />
      </VideoWrapper>
      <TextContent variants={textVariants}>
        <VideoTitle>{feature.title}</VideoTitle>
        <VideoDescription>{feature.description}</VideoDescription>
        <FeatureButton
          onClick={index === 0 ? () => navigate("/discover") : () => onClick()}
          color={COLORS.niceDeepBlack}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {index === 0 ? "Browse" : "Try it for free"}
        </FeatureButton>
      </TextContent>
    </VideoContainer>
  );
};

const LandingPage = () => {
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [mounted, setMounted] = useState(false);
  const [currentQuote, setCurrentQuote] = useState(0);
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      if (user?.id && !user?.email) {
        await getUserProfile(user.id);
      } else if (!user?.id) {

      }
    };

    checkUser();
  }, [user?.id]);

  useEffect(() => {
    setMounted(true);
  }, []);

  // Change quote every 5 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentQuote((prev) => (prev + 1) % quotes.length);
    }, 5000); // Change quote every 5 seconds

    return () => clearInterval(timer);
  }, []);

  const getUserProfile = async (userId) => {
    let userData = await fetchUserInfo(userId);
    setUser((prevUser) => ({ ...prevUser, ...userData }));

    // if user is logged in, redirect to home
    if (userData?.id && userData?.email) {
      toast("Welcome!", { position: "top-right", duration: 1000 });
      navigate("/home");
    }
  };

  const handleSignupClick = () => {
    setGlobalState({ showSignupModal: true });
  };

  const handleLogoutClick = () => {
    setLogoutModalOpen(true);
  };

  const handleLogoutConfirm = async () => {
    await logoutFirebase();
    setUser(null);
    setLogoutModalOpen(false);
  };

  const handleLogoutCancel = () => {
    setLogoutModalOpen(false);
  };

  const handleNotesClick = () => {
    if (user?.id) {
      navigate("/home");
    } else {
      setGlobalState({ showSignupModal: true });
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const handleClick = (event) => {
    event.preventDefault();
    handleSubmit(
      event,
      email,
      setLoading,
      setGlobalState,
      globalState,
      location,
      toast
    );
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await auth.signInWithPopup(googleProvider);
      const user = result.user;
      if (user) {
        setUser({
          id: user.uid,
          email: user.email,
          // Add any other user information you want to store
        });
        toast("Signed in with Google successfully!", {
          position: "top-right",
          duration: 3000,
        });
        
        navigate("/home");
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
      toast("Error signing in with Google. Please try again.", {
        position: "top-right",
        duration: 3000,
      });
    }
  };


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Banner pink speckles
  const speckles = useMemo(() => {
    const generatedSpeckles = [];
    for (let i = 0; i < 30; i++) {
      generatedSpeckles.push(
        <FloatingSpeckle
          key={i}
          $top={Math.random() * 100}
          $left={Math.random() * 100}
          $delay={Math.random() * 3}
          $size={Math.random() * 8 + 4}
          $opacity={Math.random() * 0.5 + 0.1}
        />
      );
    }
    return generatedSpeckles;
  }, []);

  return (
    <>
      <Container>
        {/* ======= MODALS ======== */}
        {globalState?.showSignupModal && <SignupModal setUser={setUser} user={user}/>}
        <LogoutModal
          user={user}
          open={logoutModalOpen}
          onClose={handleLogoutCancel}
          onConfirm={handleLogoutConfirm}
        />
        {/* ======== HEADER ======== */}
        <Header>
          <PricingLink
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => (window.location.href = "/pricing")}
          >
            Pricing
          </PricingLink>
          {user?.id && (
            <NotesButton onClick={handleNotesClick}>My Notes</NotesButton>
          )}
          {user?.id ? (
            <LogoutButton onClick={handleLogoutClick}>Logout</LogoutButton>
          ) : (
            <SignupButton onClick={handleSignupClick}>Login</SignupButton>
          )}
        </Header>
        {/* ======== BANNER ======== */}
        <AccentShape />
        {mounted && speckles}
        <ContentWrapper>
          <Title>
            noted<Dot>.</Dot>fm
          </Title>
          <Subtitle>
            <GradientText>Note taking app for visual thinkers</GradientText>
          </Subtitle>
          <Box
            sx={{
              pt: 10,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {user?.id ? (
              <NotesButton onClick={handleNotesClick}>My Notes</NotesButton>
            ) : (
              <>
                <form onSubmit={handleClick}>
                  <AnimatedTextField
                    label="Email"
                    autoComplete="off"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            onClick={handleClick}
                            sx={{
                              color: COLORS.niceWhite,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          >
                            {loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <GradientText
                                fontWeight="900"
                                gradientColor1={COLORS.niceWhite}
                                gradientColor2={COLORS.nicePink}
                              >
                                GET STARTED
                              </GradientText>
                            )}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(255, 255, 255, 0.1)",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#BBBBBB",
                      },
                      "& .MuiOutlinedInput-input": {
                        color: "#FFFFFF",
                      },
                    }}
                  />
                </form>
                <GoogleSignInButton setUser={setUser} user={user} onClose={() => setGlobalState({ showSignupModal: false })}/>
              </>
            )}
            <AnimatedGradientText marginTop="1rem" fontWeight="900">
              Try it for free
            </AnimatedGradientText>
          </Box>
        </ContentWrapper>
      </Container>
      {/* ======== MAIN SECTION ======== */}
      <MainSection>
        {/* <CenteredGradientText
          fontSize="clamp(1.5rem, 5vw, 2rem)"
          marginBottom="2rem"
        >
          Trusted by 1000+ of students and creatives at
        </CenteredGradientText>
        <LogoSection>
          {companyLogos &&
            companyLogos.map(({ name, Icon }) => (
              <LogoWrapper key={name}>
                <Icon titleAccess={name} />
              </LogoWrapper>
            ))}
         
        </LogoSection> */}

        <Title style={{ fontSize: "clamp(2rem, 5vw, 4rem)" }}>
          <GradientText>How it works</GradientText>
        </Title>

        <VideoSection>
          {videoFeatures &&
            videoFeatures.map((feature, index) => (
              <FeatureContainer key={index} feature={feature} index={index} />
            ))}
        </VideoSection>

        {/* TESTIMONIALS  */}
        <QuoteSection>
          <AnimatePresence mode="wait">
            <QuoteContainer
              key={currentQuote}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <QuoteText>
                "{<GradientText>{quotes[currentQuote].text}</GradientText>}"
              </QuoteText>
              <QuoteAuthor variant="h6" color={COLORS.niceWhite}>
                - <GradientText>{quotes[currentQuote].author}</GradientText>
              </QuoteAuthor>
            </QuoteContainer>
          </AnimatePresence>
        </QuoteSection>

        {/* FAQ  */}
        <FAQSection
          FAQuestions={FAQuestions}
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange}
        />
      </MainSection>
      <Footer />
    </>
  );
};

export default LandingPage;
