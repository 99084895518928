import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import toast, { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';
import { CURRENT_PRICE } from "../utils/constants";
import { trackCustomEvent } from "../utils/pixels";

const Success = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const sessionId = query.get("session_id");

  useEffect(() => {
    if (sessionId) {
      const confirmPayment = async () => {
        try {
          const response = await fetch(
            `https://us-central1-is-everything-toxic.cloudfunctions.net/confirmPayment?session_id=${sessionId}`
          );
          const result = await response.json();

          if (result.success) {
            toast("✅ Payment successful! You are now a premium user.", { position: 'top-right' });
        
            
            trackCustomEvent('Purchase', {
              value: 100.00, // replace with the actual value
              currency: 'USD', // replace with the actual currency
            });
            
            navigate("/"); // Redirect to home page or desired page
        } else {
            toast("🚨 Payment failed. Please try again.", { position: 'top-right' });
            navigate("/"); // Redirect to home page or desired page
          }
        } catch (error) {
          console.error("Error confirming payment:", error);
          toast("🚨 An error occurred. Please try again.", { position: 'top-right' });
          navigate("/"); // Redirect to home page or desired page
        }
      };

      confirmPayment();
    } else {
        navigate("/"); // Redirect to home page or desired page
    }
  }, [sessionId, navigate]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        dislay: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Success;
