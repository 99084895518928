import React, { useContext, useState } from "react";
import {
  Typography,
  Modal,
  Box,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/colors";
import { firestore } from "../firebaseConfig";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import UserContext from "../context/userContext";

const OnboardingModal = () => {
  const { user, setUser } = useContext(UserContext);
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [orgName, setOrgName] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleOrgNameChange = (event) => {
    setOrgName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!orgName || !username) {
      toast("Business name and username cannot be empty");
      setLoading(false);
      return;
    }

    try {
      await firestore.collection("users").doc(user.id).update({
        orgName: orgName,
        username: username,
      });

      setUser((prevUser) => ({
        ...prevUser,
        orgName: orgName,
        username: username,
      }));

      toast("Profile updated successfully!");
      setGlobalState((prevState) => ({
        ...prevState,
        showOnboardingModal: false,
      }));
      window.location.reload()
    } catch (error) {
      console.error("Error updating profile:", error);
      toast("Failed to update profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={globalState?.showOnboardingModal}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          borderRadius: "16px",
          width: isMobile ? "90%" : "400px",
          padding: "32px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: "linear-gradient(90deg, #FF6B6B, #4ECDC4)",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{ mb: 3, fontWeight: 700, textAlign: "center" }}
        >
          Almost done..
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="What's your business name"
            autoComplete="off"
            type="text"
            value={orgName}
            onChange={handleOrgNameChange}
            required
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
              mb: 2,
            }}
          />
          <TextField
            label="Username"
            autoComplete="off"
            type="text"
            value={username}
            onChange={handleUsernameChange}
            required
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{
              marginTop: "1rem",
              fontWeight: "bold",
              background: "linear-gradient(45deg, #2196F3, #21CBF3)",
              color: "#FFFFFF",
              py: 1.5,
              borderRadius: "8px",
              transition: "background 0.3s ease, transform 0.3s ease",
              "&:hover": {
                background: "linear-gradient(45deg, #21CBF3, #2196F3)",
                transform: "translateY(-2px)",
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "FINISH"
            )}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default OnboardingModal;
