import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import { COLORS } from "../utils/colors";
import { motion } from "framer-motion";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { storage } from "../firebaseConfig";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css"; // choose your theme

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background-color: ${COLORS.niceDeepBlack};
    color: ${COLORS.niceWhite};
    border-radius: 20px;
    box-shadow: 0 0 30px rgba(188, 144, 207, 0.2);
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const RecordButton = styled(motion.div)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) =>
    props.isRecording
      ? `linear-gradient(45deg, ${COLORS.niceRed}, ${COLORS.nicePink})`
      : `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink})`};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const StatusText = styled(Typography)`
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TimeText = styled(Typography)`
  margin-top: 0.5rem;
  font-size: 1rem;
  color: ${COLORS.niceWhite};
  opacity: 0.8;
`;

const StyledButton = styled(Button)`
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  color: ${COLORS.niceWhite};
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  text-transform: none;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(188, 144, 207, 0.2);
  }

  &:disabled {
    background: linear-gradient(45deg, #666, #999);
    color: #ccc;
  }
`;

const ActionButton = styled(motion.button)`
  background: ${(props) =>
    props.color ||
    `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink})`};
  color: ${COLORS.niceWhite};
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    background: linear-gradient(45deg, #666, #999);
    color: #ccc;
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const PulsatingCircle = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${(props) =>
    props.isRecording
      ? `linear-gradient(45deg, ${COLORS.niceRed}, ${COLORS.nicePink})`
      : `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink})`};
  opacity: 0.3;
`;

const VoiceRecordModal = ({
  user,
  open,
  onClose,
  onSave,
  setIsVoiceNoteSaving,
  isVoiceNoteSaving,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [hasRecording, setHasRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const MAX_RECORDING_TIME = 30; // Maximum recording time in seconds
  const [isTimerReached, setIsTimerReached] = useState(false);

  // BECAUSE OF GOOGLE TRANSCRIPTION TIMEOUT WE HAVE TO LIMIT THE RECORDING TIME
  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setRecordingTime((prevTime) => {
          if (prevTime >= MAX_RECORDING_TIME - 1) {
            handleStopRecording();
            setIsTimerReached(true);
            return MAX_RECORDING_TIME;
          }
          return prevTime + 1;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  const handleStartRecording = async () => {
    if (isTimerReached) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        setAudioBlob(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setHasRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      // Handle error (e.g., show a message to the user)
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleRestart = () => {
    setRecordingTime(0);
    setIsRecording(false);
    setHasRecording(false);
    setAudioBlob(null);
    audioChunksRef.current = [];
    setIsTimerReached(false);
  };

  const clearFields = () => {
    setRecordingTime(0);
    setIsRecording(false);
    setHasRecording(false);
    setAudioBlob(null);
    audioChunksRef.current = [];
  };

  useEffect(() => {
    const upload = async () => {
      if (audioBlob && user) {
        const fileName = `voice_notes/${user.id}/${Date.now()}.webm`;
        const storageRef = storage.ref(fileName);

        try {
          const snapshot = await storageRef.put(audioBlob);
          const downloadURL = await snapshot.ref.getDownloadURL();
          onSave(downloadURL);
        } catch (error) {
          console.error("Error uploading file:", error);
          // Handle error (e.g., show a message to the user)
          toast("We're encounting an error, please tell the dev", {
            position: "top-right",
          });
        }
      }
    };

    upload();
  }, [audioBlob, user]);

  const handleSave = async () => {
    setIsVoiceNoteSaving(true);
    if (isRecording) {
      handleStopRecording();
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={() => {
        clearFields();
        onClose();
      }}
    >
      <StyledDialogTitle>Record Voice Note</StyledDialogTitle>
      <StyledDialogContent>
        <RecordButton
          isRecording={isRecording}
          onClick={isRecording ? handleStopRecording : handleStartRecording}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {isRecording ? (
            <StopIcon fontSize="large" />
          ) : (
            <MicIcon fontSize="large" />
          )}
        </RecordButton>
        <StatusText variant="h6">
          {isRecording
            ? `Recording... (${MAX_RECORDING_TIME - recordingTime}s left)`
            : hasRecording
            ? "Recording Paused"
            : "Ready to Record"}
        </StatusText>
        {isRecording ? <TimeText variant="body1">
          {`${Math.floor(recordingTime / 60)}:${(recordingTime % 60)
            .toString()
            .padStart(2, "0")}`}
        </TimeText> : <Typography variant="body1">
            30 sec max
        </Typography> }
        <ButtonGroup>
          <ActionButton
            as={motion.button}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleRestart}
            color={COLORS.niceRed}
            disabled={!hasRecording || open}
          >
            <RestartAltIcon /> Restart
          </ActionButton>
          <ActionButton
            as={motion.button}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSave}
            disabled={!hasRecording || isVoiceNoteSaving}
          >
            {isVoiceNoteSaving ? (
              <CircularProgress size={24} />
            ) : (
              <CheckCircleIcon />
            )}{" "}
            Done
          </ActionButton>
        </ButtonGroup>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default VoiceRecordModal;
