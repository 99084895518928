import React, { useState } from "react";
import {
  Typography,
  Modal,
  Box,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { COLORS } from "../utils/colors";
import RedClose from "./RedClose";

const FolderNameModal = ({ open, onClose, onSubmit , folderNameRef }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

 

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#1E1E1E",
          borderRadius: "16px",
          width: isMobile ? "90%" : "400px",
          padding: "32px",
          boxShadow: "0 4px 30px rgba(0,0,0,0.3)",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <RedClose handleClose={onClose} />
        </Box>

        <Typography
          variant="h5"
          sx={{ mb: 3, fontWeight: 700, textAlign: "center", color: "#FFFFFF" }}
        >
          Create New Folder
        </Typography>

        <form onSubmit={onSubmit}>
          <TextField
            label="Folder Name"
            autoComplete="off"
            inputRef={folderNameRef}
            required
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "rgba(255, 255, 255, 0.05)",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.1)",
              },
              "& .MuiInputLabel-root": {
                color: "#BBBBBB",
              },
              "& .MuiOutlinedInput-input": {
                color: "#FFFFFF",
              },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              marginTop: "1rem",
              fontWeight: "bold",
              color: "#FFFFFF",
              py: 1.5,
              borderRadius: "8px",
              transition: "all 0.3s ease",
              background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
              "&:hover": {
                background: `linear-gradient(45deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                transform: "translateY(-2px)",
                boxShadow: "0 4px 20px rgba(78, 205, 196, 0.4)",
              },
            }}
          >
            Create Folder
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default FolderNameModal;