import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../utils/colors";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const HeaderContainer = styled.header`
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  width: auto;
  max-width: ${(props) => (props.noLogin ? "100%" : "1200px")};
  margin: 0 auto;
`;

const SignupButton = styled.button`
  background-color: ${COLORS.nicePink};
  color: ${COLORS.niceDeepBlack};
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;

  &:hover {
    background-color: ${COLORS.niceLightPurple};
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(188, 144, 207, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(227, 132, 163, 0.4);
  }
`;

const VisitorHeader = ({
  user,
  globalState,
  setGlobalState,
  noLogin,
  showToggle,
  setMobileView,
  mobileView,
}) => {
  const handleSignupClick = () => {
    setGlobalState({ ...globalState, showSignupModal: true });
  };

  return (
    <HeaderContainer noLogin={noLogin}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: mobileView ? "90vw" : "100%",
        }}
      >
        <Typography
          onClick={() => (window.location.href = "/")}
          variant="h6"
          sx={{ ...styles.logo, cursor: noLogin ? "default" : "cursor" }}
        >
          noted<span style={{ color: COLORS.nicePink }}>.fm</span>
        </Typography>
        {!noLogin && (
          <SignupButton
            onClick={
              user
                ? () => (window.location.href = "/home")
                : () => handleSignupClick()
            }
          >
            {user?.email ? "Go to notes" : "Login"}
          </SignupButton>
        )}
        {showToggle && (
          <Box sx={styles.toggleContainer}>
            <Typography
              sx={{
                ...styles.toggleText,
                color:
                  mobileView === "map" ? COLORS.nicePink : COLORS.niceDeepBlack,
              }}
              onClick={() =>
                setMobileView(mobileView === "map" ? "notes" : "map")
              }
            >
               Map
            </Typography>
            <Typography
              sx={{
                ...styles.toggleText,
                color:
                  mobileView === "notes"
                    ? COLORS.nicePink
                    : COLORS.niceDeepBlack,
              }}
              onClick={() =>
                setMobileView(mobileView === "map" ? "notes" : "map")
              }
            >
              Notes
            </Typography>
          </Box>
        )}
      </Box>
    </HeaderContainer>
  );
};

const styles = {
  logo: {
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "1.5rem",
    color: COLORS.niceWhite,
    fontFamily: "Arial, sans-serif",
  },
  toggleContainer: {
    backgroundColor: COLORS.niceWhite,
    display: "flex",
    minWidth: "25%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "16px",
    padding: "4px 12px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: COLORS.niceWhite,
    },
  },
  toggleText: {
    color: COLORS.niceDeepBlack,
    fontSize: "0.8rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
};

export default VisitorHeader;
