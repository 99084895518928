
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/database"; 
import "firebase/compat/auth";


const firebaseConfig = {
  apiKey: "AIzaSyD6VPKWpGkTewqbkW76RDB2ZHozUpN2T24",
  authDomain: "notedfm-f6490.firebaseapp.com",
  projectId: "notedfm-f6490",
  storageBucket: "notedfm-f6490.appspot.com",
  messagingSenderId: "872850079663",
  appId: "1:872850079663:web:1191443a7a06b13562395a",
  databaseURL: "https://notedfm-f6490-default-rtdb.firebaseio.com/"

};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();

const functions = firebase.functions();

const database = firebase.database(); 

const storage = firebase.storage();

const auth = firebase.auth();

const googleProvider = new firebase.auth.GoogleAuthProvider();

export { firebase, firestore, functions, database, storage , auth, googleProvider};


