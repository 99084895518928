import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Popover,
  Link,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import InfoIcon from "@mui/icons-material/Info";
import { COLORS } from "../utils/colors";
import NoteIcon from "@mui/icons-material/Note";
import React, { useEffect, useRef, useState } from "react";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import CreateNewFolderOutlined from "@mui/icons-material/CreateNewFolderOutlined";
import styled from "styled-components";
import SortIcon from "@mui/icons-material/Sort";
import CustomTooltip from "./CustomTooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FolderNameModal from "./FolderNameModal";
import LinkIcon from "@mui/icons-material/Link";
import MicNoneIcon from "@mui/icons-material/MicNone";
import Collapse from "@mui/material/Collapse";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SelectFolderModal from "./SelectFolderModal";
import {
  fetchNotesForFolders,
  formatTitle,
  formatTranscription,
  getRandomBrightColor,
  guidGenerator,
  sortFilesByPinned,
  typewriterEffect,
} from "../utils/helperMethods";
import { database } from "../firebaseConfig";
import SearchIcon from "@mui/icons-material/Search";
import { IosShare, PushPin } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-simple-toasts";
import { useNavigate } from "react-router-dom";
import VoiceRecordModal from "./VoiceRecordModal";
import ShareMindmapModal from "./ShareMindmapModal";
import "react-simple-toasts/dist/theme/light.css";
import { set } from "lodash";
import MindMapModal from "./MindMapModal";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FolderOptionsModal from "./FolderOptionsModal";

// overflow-y: scroll; // removed not sure what it did but put a annoying little chevron down on right side of renderFolder

const ScrollableList = styled.div`
  flex-grow: 1;
  padding: 0;
  max-height: calc(
    100vh - 100px
  ); 
  overflow-y: auto;

  /* Always show scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
`;

const FileExplorer = ({
  files,
  isEditMode,
  activeFolder,
  setActiveFolder,
  updateFolder,
  setIsEditMode,
  activeFile,
  handleDeleteFolder,
  showFolderSettingsModal,
  setShowFolderSettingsModal,
  setShowShareMindmapModal,
  showShareMindmapModal,
  showFolderOptionsModal,
  setShowFolderOptionsModal,
  currentState,
  user,
  setUser,
  handlePinNote,
  folders,
  setFolders,
  folderName,
  folderNameRef,
  setFolderName,
  expanded,
  showFolderModal,
  handleDeleteNote,
  setShowFolderModal,
  setActiveFile,
  handleCreateFolder,
  setFiles,
  handleTabChange,
  handleSelectFolder,
  handleRemoveNoteFromFolder,
  showMindMapModal,
  setShowMindMapModal,
  isMindMapLoading,
  setIsMindMapLoading,
  mindMapProgress,
  setMindMapProgress,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [showNoteOptionsModal, setShowNoteOptionsModal] = useState(false);
  const [sortCriteria, setSortCriteria] = useState("nameAsc"); // New state for sorting criteria
  const [selectedNote, setSelectedNote] = useState(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [animatedTitles, setAnimatedTitles] = useState({});
  const [lastAddedNoteId, setLastAddedNoteId] = useState(null);
  const prevFilesRef = useRef();
  const [lastUpdatedNoteId, setLastUpdatedNoteId] = useState(null);
  const [isVoiceModalOpen, setIsVoiceModalOpen] = useState(false);
  const [isVoiceNoteSaving, setIsVoiceNoteSaving] = useState(false);

  const filterFiles = (files, searchTerm) => {
    if (!searchTerm) return files;
    return files.filter(
      (file) =>
        file.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        file.content.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const filteredFiles = filterFiles(files, searchTerm);

  useEffect(() => {
    if (prevFilesRef.current) {
      const updatedNote = files.find((file, index) => {
        const prevFile = prevFilesRef.current[index];
        return (
          prevFile && file.id === prevFile.id && file.title !== prevFile.title
        );
      });

      if (updatedNote) {
        setLastUpdatedNoteId(updatedNote.id);
        typewriterEffect(formatTitle(updatedNote.title), updatedNote.id);
      }
    }
    prevFilesRef.current = files;
  }, [files]);

  const typewriterEffect = async (text, noteId) => {
    for (let i = 0; i <= text.length; i++) {
      setAnimatedTitles((prev) => ({
        ...prev,
        [noteId]: text.slice(0, i),
      }));
      await new Promise((resolve) => setTimeout(resolve, 50)); // Adjust speed as needed
    }
  };

  const handleSearchFocus = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleSearchBlur = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    // TEMP - allow as FREEMIUM for users/creators to be motivated to share
    // if(!user?.premiumUser || !user?.premiumUser?.isActive){
    //   toast("Please upgrade to premium to use this feature", {
    //     position: "top-right",
    //   });
    //   navigate("/pricing")
    //   return;
    // }
    setSearchTerm(event.target.value);
  };

  const toggleFolder = (folderId) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNoteOptionsModal = (note, event) => {
    event.stopPropagation();
    setSelectedNote(note);
    setShowNoteOptionsModal(true);
  };

  const sortFiles = (criteria) => {
    let sortedFiles = [...files];
    switch (criteria) {
      case "nameAsc":
        sortedFiles.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case "nameDesc":
        sortedFiles.sort((a, b) => b.title.localeCompare(a.title));
        break;
      case "modifiedAsc":
        sortedFiles.sort((a, b) => a.updatedAt - b.updatedAt);
        break;
      case "modifiedDesc":
        sortedFiles.sort((a, b) => b.updatedAt - a.updatedAt);
        break;
      case "createdAsc":
        sortedFiles.sort((a, b) => a.createdAt - b.createdAt);
        break;
      case "createdDesc":
        sortedFiles.sort((a, b) => b.createdAt - a.createdAt);
        break;
      default:
        break;
    }
    setFiles(sortedFiles);
    setSortCriteria(criteria); // Update the current sort criteria
  };

  const handleCreateNote = async () => {
    const newNote = {
      id: guidGenerator(),
      title: "Start writing...",
      content: `<h1>Start writing...</h1>${"<p><br></p>".repeat(12)}`,
      slug: "",
      createdAt: Date.now(),
      updatedAt: Date.now(),
      color: getRandomBrightColor(),
    };

    // EDIT MODE
    if (!isEditMode) {
      toast("You are in view mode. Please switch to edit mode.", {
        position: "top-right",
      });
      return;
    }

    // Save the new note to the database
    await database.ref(`notes/${user.id}/notes/${newNote.id}`).set(newNote);

    // add to files
    setFiles((prevFiles) => {
      const updatedFiles = [newNote, ...prevFiles];
      return sortFilesByPinned(updatedFiles);
    });
    setLastUpdatedNoteId(newNote.id);
    handleTabChange(newNote.id, newNote); // Update the active tab
  };

  const handleCreateVoiceNote = () => {
    // EDIT MODE
    if (!isEditMode) {
      toast("You are in view mode. Please switch to edit mode.", {
        position: "top-right",
      });
      return;
    }

    // premium feature
    if (!user?.premiumUser || !user?.premiumUser?.isActive) {
      // not sub or inactive
      toast("Please upgrade to premium to use voice notes.", {
        position: "top-right",
      });
      navigate("/pricing");
      return;
    }
    // This function will be implemented later for voice note creation
    setIsVoiceModalOpen(true);
  };

  const genAudioNoteContent = (transcriptionText, summary, audioUrl) => {
    return `
<h1>${summary.title || "Voice Note"} (voice)</h1>
<p><strong>AUDIO FILE:</strong> <a href="${audioUrl}" target="_blank" rel="noopener noreferrer">Listen to Audio</a></p>
<audio controls src="${audioUrl}"></audio>

<h2>Executive Summary</h2>
<p>${summary.executiveSummary || ""}</p>

<h2>Main Takeaways</h2>
<ul>
  ${
    summary.mainTakeaways?.map((takeaway) => `<li>${takeaway}</li>`).join("") ||
    ""
  }
</ul>

<h2>Key Concepts</h2>
${
  summary.keyConcepts
    ?.map(
      (concept) => `
  <h3>${concept.concept}</h3>
  <p>${concept.explanation}</p>
`
    )
    .join("") || ""
}

<h2>Supporting Details</h2>
<ul>
  ${
    summary.supportingDetails?.map((detail) => `<li>${detail}</li>`).join("") ||
    ""
  }
</ul>

<h2>Practical Applications</h2>
<ul>
  ${
    summary.practicalApplications?.map((app) => `<li>${app}</li>`).join("") ||
    ""
  }
</ul>

<h2>Critical Analysis</h2>
<h3>Strengths</h3>
<ul>
  ${
    summary.criticalAnalysis?.strengths
      ?.map((strength) => `<li>${strength}</li>`)
      .join("") || ""
  }
</ul>
<h3>Limitations</h3>
<ul>
  ${
    summary.criticalAnalysis?.limitations
      ?.map((limitation) => `<li>${limitation}</li>`)
      .join("") || ""
  }
</ul>
<h3>Counterarguments</h3>
<ul>
  ${
    summary.criticalAnalysis?.counterarguments
      ?.map((counterargument) => `<li>${counterargument}</li>`)
      .join("") || ""
  }
</ul>

<h2>Expansion Ideas</h2>
<ul>
  ${summary.expansionIdeas?.map((idea) => `<li>${idea}</li>`).join("") || ""}
</ul>

<h2>FULL TRANSCRIPT: </h2>
<p>${transcriptionText}</p>
`;
  };

  const handleSaveVoiceNote = async (audioUrl) => {
    setIsVoiceNoteSaving(true);
    // transcribe the audio using google cloud
    try {
      // Call the Cloud Function to transcribe the audio
      const response = await fetch(
        " https://us-central1-notedfm-f6490.cloudfunctions.net/transcribeAudio",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any necessary authentication headers here
          },
          body: JSON.stringify({ audioUrl, userId: user.id }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to transcribe audio");
      }

      const { transcriptionText, wordTimestamps, summary } =
        await response.json();

      const uTranscriptionText = formatTranscription(wordTimestamps); // format the transcription
      const uSummary = { ...summary, audioUrl: audioUrl }; // add in audio file

      const newNoteContent = genAudioNoteContent(
        uTranscriptionText,
        uSummary,
        audioUrl
      );

      const newNote = {
        id: guidGenerator(),
        title: summary.title || "Voice Note",
        content: newNoteContent,
        slug: summary.title.toLowerCase().replace(/\s+/g, "-"),
        createdAt: Date.now(),
        updatedAt: Date.now(),
        color: getRandomBrightColor(),
      };

      // Save the new note to the database
      await database.ref(`notes/${user.id}/notes/${newNote.id}`).set(newNote);

      // Add to files
      setFiles((prevFiles) => {
        const updatedFiles = [newNote, ...prevFiles];
        return sortFilesByPinned(updatedFiles);
      });
      setLastUpdatedNoteId(newNote.id);
      handleTabChange(newNote.id, newNote); // Update the active tab
      setIsVoiceModalOpen(false);
      toast("Voice note transcribed and saved successfully!", {
        position: "top-right",
      });
    } catch (error) {
      console.error("Error saving voice note:", error);
      // Handle error (e.g., show a message to the user)
    } finally {
      setIsVoiceNoteSaving(false);
    }
  };

  const renderSortMenu = () => {
    return (
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "sort-button",
          sx: styles.menuList, // Apply styles from the styles object
        }}
      >
        {[
          "nameAsc",
          "nameDesc",
          "modifiedDesc",
          "modifiedAsc",
          "createdDesc",
          "createdAsc",
        ].map((criteria, index) => (
          <MenuItem
            key={criteria}
            onClick={() => {
              sortFiles(criteria);
              handleClose();
            }}
            sx={styles.menuItem} // Apply styles from the styles object
          >
            {criteria === "nameAsc" && "File name (A to Z)"}
            {criteria === "nameDesc" && "File name (Z to A)"}
            {criteria === "modifiedDesc" && "Modified time (new to old)"}
            {criteria === "modifiedAsc" && "Modified time (old to new)"}
            {criteria === "createdDesc" && "Created time (new to old)"}
            {criteria === "createdAsc" && "Created time (old to new)"}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const handleShareClick = (folder) => {
    setShowShareMindmapModal(true);
    setActiveFolder(folder);
  };

  const handleCreateMindMap = (e, values) => {
    e.preventDefault();
    handleCreateFolder(e, {
      folderName: values?.mapName,
      description: values?.description,
    });
  };

  const renderFolders = () => {
    return (
      folders &&
      Object.values(folders)?.map((folder) => (
        <React.Fragment key={folder.id}>
          {expanded && (
            <ListItem
              sx={{
                // ...styles.folderItem(expanded),
                padding: "0px 0px",
                minWidth: 0,
              }}
              onClick={() => {
                toggleFolder(folder.id);
                setActiveFolder(folder);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minWidth: 0,
                }}
              >
                {/* SHOW FOLDER NAME  */}
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      // textAlign: "center",
                      color: COLORS.lightGrey,
                      fontSize: ".8rem",
                      pl: "12px",
                      py: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    {folder.name}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", pr: "6px" }}>
                  <CustomTooltip title={`Make mind map public`}>
                    <IconButton
                      onClick={() => {
                        // if created by other user, cant share
                        if (
                          folder.createdBy == null ||
                          folder.createdBy === user.id
                        ) {
                          setShowShareMindmapModal(true);
                          setActiveFolder(folder);
                        } else {
                          toast(
                            "Can't share imported folders. Only ones you crate",
                            {
                              position: "top-right",
                            }
                          );
                        }
                      }}
                      sx={{ p: 0, m: 0 }}
                    >
                      <LinkIcon
                        sx={{
                          color: folder?.public
                            ? COLORS.niceBlue
                            : COLORS.lightGrey,
                          fontSize: "1.3rem",
                        }}
                      />
                    </IconButton>
                  </CustomTooltip>
                  {/* <IconButton sx={{ p: 0, m: 0 }}>
                    <ExpandMoreIcon sx={{ color: COLORS.lightGrey }} />
                  </IconButton> */}
                  <IconButton
                    size="small"
                    sx={{
                      color: COLORS.lightGrey,
                      padding: 0,
                      flexShrink: 0,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      // show folder settings (delete, etc)
                      setShowFolderSettingsModal(true)
                      setActiveFolder(folder);
                    }}
                  >
                    <MoreVertIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                </Box>
              </Box>
            </ListItem>
          )}
          <Collapse
            in={expandedFolders[folder.id]}
            timeout="auto"
            unmountOnExit
          >
            {folder?.notes &&
              Object.values(folder.notes)?.map((note, index) => (
                <CustomTooltip
                  key={index}
                  title={expanded ? "" : formatTitle(note.title)}
                >
                  <ListItem
                    sx={{
                      ...styles.listItem(expanded),
                      padding: "4px 8px",
                      minWidth: 0,
                    }}
                    onClick={() => setActiveFile(note)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "24px",
                        width: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        padding: 0,
                        flexShrink: 0,
                      }}
                    >
                      <NoteIcon
                        sx={{
                          color: folder.folderColor || COLORS.niceDarkGrey,
                          fontSize: "1rem",
                        }}
                      />
                    </ListItemIcon>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minWidth: 0,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            noWrap
                            sx={{ ...styles.titleText, flex: 1 }}
                          >
                            {formatTitle(note.title)}
                          </Typography>
                        }
                        sx={{ ...styles.text, minWidth: 0, marginRight: "8px" }}
                      />
                  
                    </Box>
                  </ListItem>
                </CustomTooltip>
              ))}
          </Collapse>
        </React.Fragment>
      ))
    );
  };

  return (
    <Box sx={styles.sidebar(expanded)}>
      <FolderOptionsModal
        open={showFolderSettingsModal}
        onClose={() => setShowFolderSettingsModal(false)}
        onDeleteFolder={(folder) => {
          handleDeleteFolder(folder);
          setShowFolderOptionsModal(false);
        }}
        folder={activeFolder}
      />
      <MindMapModal
        user={user}
        open={showMindMapModal}
        onClose={() => !isMindMapLoading && setShowMindMapModal(false)}
        onSubmit={(e, values) => handleCreateMindMap(e, values)}
        isLoading={isMindMapLoading}
        progress={mindMapProgress}
      />
      <ShareMindmapModal
        user={user}
        folders={folders}
        setUser={setUser}
        updateFolder={updateFolder}
        open={showShareMindmapModal}
        onClose={() => {
          setShowShareMindmapModal(false);
          setActiveFolder(null);
        }}
        folder={activeFolder}
        setFolders={setFolders}
      />
      <VoiceRecordModal
        user={user}
        open={isVoiceModalOpen}
        isVoiceNoteSaving={isVoiceNoteSaving}
        setIsVoiceNoteSaving={setIsVoiceNoteSaving}
        onClose={() => setIsVoiceModalOpen(false)}
        onSave={handleSaveVoiceNote}
      />
      <SelectFolderModal
        deleteOnly={false}
        setActiveFolder={setActiveFolder}
        folder={activeFolder}
        removeRender={showFolderOptionsModal}
        setRemoveRender={setShowFolderOptionsModal}
        note={selectedNote}
        currentState={currentState}
        handleDeleteNote={handleDeleteNote}
        handleSelectFolder={handleSelectFolder}
        handleRemoveNoteFromFolder={(folder, note) => {
          handleRemoveNoteFromFolder(folder, note);
        }}
        open={showNoteOptionsModal}
        onClose={() => {
          setShowNoteOptionsModal(false);
          setSelectedNote(null);
        }}
        folders={folders}
      />
      <Box sx={styles.header}>
        <Box sx={styles.searchContainer}>
          <SearchIcon sx={styles.searchIcon} />
          <input
            type="text"
            value={searchTerm}
            onChange={
              user?.premiumUser
                ? (e) => handleSearchChange(e)
                : () => {
                    toast("Become a member to use this feature", {
                      position: "top-right",
                    });
                    navigate("/pricing");
                  }
            }
            placeholder={expanded ? "Search..." : ""}
            style={styles.searchInput}
          />
          {searchTerm && (
            <IconButton
              size="small"
              onClick={handleClearSearch}
              sx={styles.clearButton}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        {/* SEARCH POPOVER  */}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleSearchBlur}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: styles.popoverPaper,
          }}
        >
          <Box sx={styles.searchOptionsHeader}>
            <Typography variant="body2">Search options</Typography>
            <InfoIcon fontSize="small" sx={{ color: "#6e6e6e" }} />
          </Box>
          <Box sx={styles.searchOptionsList}>
            <Typography variant="body2">
              path: match path of the file
            </Typography>
            <Typography variant="body2">file: match file name</Typography>
            <Typography variant="body2">tag: search for tags</Typography>
            <Typography variant="body2">
              line: search keywords on same line
            </Typography>
            <Typography variant="body2">
              section: search keywords under same heading
            </Typography>
            <Typography variant="body2">[property] match property</Typography>
          </Box>
        </Popover>
      </Box>
      {/* TOOL OPTIONS  */}
      <Box sx={styles.controls}>
        {expanded ? (
          // Expanded view
          <Box
            sx={{
              width: "90%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {/* TODO - BRING BACK WHEN CAN DO >30 second recordings */}
            {/* <CustomTooltip title="Create Voice Note">
              <IconButton
                onClick={handleCreateVoiceNote}
                size="small"
                sx={styles.controlButton}
              >
                <MicNoneIcon fontSize="small" />
              </IconButton>
            </CustomTooltip> */}
            <CustomTooltip title="Generate AI Mind Map">
              <IconButton
                onClick={(e) => setShowMindMapModal(true)}
                size="small"
                sx={styles.controlButton}
              >
                <AutoAwesomeIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>

            <CustomTooltip title="New Folder">
              <IconButton
                onClick={() => setShowFolderModal(true)}
                size="small"
                sx={styles.controlButton}
              >
                <CreateNewFolderOutlined fontSize="small" />
              </IconButton>
            </CustomTooltip>

            <CustomTooltip title="New Note">
              <IconButton
                onClick={handleCreateNote}
                size="small"
                sx={styles.controlButton}
              >
                <NoteAddOutlinedIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>

            <CustomTooltip title="Sort">
              <IconButton
                size="small"
                sx={styles.controlButton}
                onClick={handleClick}
                aria-controls={open ? "sort-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <SortIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
            {renderSortMenu()}
          </Box>
        ) : (
          // Collapsed view
          <Box sx={styles.collapsedControls}>
            <CustomTooltip title="New Note">
              <IconButton size="small" sx={styles.controlButton}>
                <NoteAddOutlinedIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="New Folder">
              <IconButton size="small" sx={styles.controlButton}>
                <CreateNewFolderOutlined fontSize="small" />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="Sort">
              <IconButton
                size="small"
                sx={styles.controlButton}
                onClick={handleClick}
                aria-controls={open ? "sort-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <SortIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
            {renderSortMenu()}
          </Box>
        )}
      </Box>
      {/* FILE LIST  */}
      <ScrollableList>
        {renderFolders()}
        <Divider sx={{ borderColor: "#2a2a2a" }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            onClick={() => setActiveFolder(null)}
            sx={{
              cursor: "pointer",
              color: COLORS.lightGrey,
              fontSize: ".8rem",
              pl: "12px",
              py: "8px",
              fontWeight: "bold",
            }}
          >
            All notes
          </Typography>
        </Box>
        {/* <Divider sx={{ borderColor: "#2a2a2a" }} />  */}
        {filteredFiles &&
          filteredFiles.map((note, index) => (
            <CustomTooltip
              key={index}
              title={expanded ? "" : formatTitle(note.title)}
            >
              <ListItem
                sx={{
                  ...styles.listItem(expanded),
                  padding: expanded ? "4px 4px 4px 4px" : "4px", // Reduce padding when expanded
                  minWidth: 0, // Allow the ListItem to shrink below its content size
                }}
                onClick={() => setActiveFile(note)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: expanded ? "24px" : "100%",
                    width: expanded ? "24px" : "100%",
                    height: expanded ? "auto" : "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 0,
                    padding: 0,
                    flexShrink: 0, // Prevent the icon from shrinking
                  }}
                >
                  <NoteIcon
                    sx={{
                      color: note?.color,
                      fontSize: expanded ? "1rem" : "1.2rem",
                      margin: 0,
                      padding: 0,
                    }}
                  />
                </ListItemIcon>
                {expanded && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minWidth: 0, // Allow the Box to shrink below its content size
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          noWrap
                          sx={{ ...styles.titleText, flex: 1 }}
                        >
                          {animatedTitles[note.id] || formatTitle(note.title)}
                        </Typography>
                      }
                      sx={{ ...styles.text, minWidth: 0, marginRight: "8px" }} // Allow text to shrink and add right margin
                    />
                    <IconButton
                      size="small"
                      sx={{
                        padding: 0,
                        marginRight: "4px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePinNote(note);
                      }}
                    >
                      <PushPin
                        sx={{
                          fontSize: "1rem",
                          color: note.pinned ? note.color : COLORS.niceDarkGrey,
                        }}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{
                        color: COLORS.niceSuperLightGrey,
                        padding: 0,
                        marginRight: 0,
                        flexShrink: 0, // Prevent the button from shrinking
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the ListItem click
                        setShowNoteOptionsModal(true);
                        openNoteOptionsModal(note, e);
                      }}
                    >
                      <MoreVertIcon sx={{ fontSize: "1.2rem" }} />
                    </IconButton>
                  </Box>
                )}
              </ListItem>
            </CustomTooltip>
          ))}
      </ScrollableList>
      <FolderNameModal
        open={showFolderModal}
        onClose={() => setShowFolderModal(false)}
        onSubmit={handleCreateFolder}
        folderNameRef={folderNameRef}
      />
    </Box>
  );
};

const styles = {
  sidebar: (expanded) => ({
    minWidth: expanded ? "15rem" : "5rem",
    maxWidth: expanded ? "15rem" : "5rem",
    height: "100vh",
    backgroundColor: COLORS.niceDarkGray,
    transition: "all 0.3s ease",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  }),
  menuList: {
    backgroundColor: "#2A2A2A", // Dark background for the dropdown
    color: "#FFFFFF", // White text color
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "2px",
      background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
    },
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Light hover effect
    },
  },
  header: {
    minHeight: "50px",
    backgroundColor: "#2a2a2a",
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#1e1e1e",
    borderRadius: "4px",
    padding: "4px 4px",
    width: "100%",
    height: "30px",
  },
  searchIcon: {
    color: "#6e6e6e",
    marginRight: "8px",
    fontSize: "1.2rem",
  },
  searchInput: {
    backgroundColor: "transparent",
    border: "none",
    color: "#ffffff",
    fontSize: "0.9rem",
    width: "100%",
    outline: "none",
    "&::placeholder": {
      color: "#6e6e6e",
    },
  },
  clearButton: {
    color: "#6e6e6e",
    padding: "2px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  folderItem: (expanded) => ({
    padding: "12px 16px",
    cursor: "pointer",
    justifyContent: expanded ? "space-between" : "center",
    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
  }),
  nestedItem: (expanded) => ({
    padding: "8px 16px 8px 32px",
    cursor: "pointer",
    justifyContent: expanded ? "flex-start" : "center",
    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.05)" },
  }),
  list: { padding: 0, overflowY: "auto", flexGrow: 1 },
  listItem: (expanded) => ({
    cursor: "pointer",
    justifyContent: expanded ? "flex-start" : "center",
    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
    overflowY: "auto", // Ensure overflow is handled
    maxHeight: expanded ? "none" : "200px", // Set a max height when not expanded
  }),
  controls: {
    padding: "8px 0",
    borderBottom: "1px solid #333",
    width: "100%",
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-around",
  },

  collapsedControls: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: "4px 0",
    },
  },

  controlButton: {
    color: "#cccccc",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  icon: {
    width: "24px", // Reduced from 40px
    minWidth: "24px", // Added to ensure consistent width
    marginRight: "8px", // Added to create some space between icon and text
  },
  text: {
    "& .MuiListItemText-primary": { color: COLORS.niceDarkGrey },
    "& .MuiListItemText-secondary": { color: "#a0a0a0" },
  },
  titleText: { color: COLORS.niceSuperLightGrey, fontSize: ".9rem" },
};

export default FileExplorer;
