import React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AudioPlayerModal = ({ open, onClose, audioUrl }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <audio controls src={audioUrl} style={{ width: '100%' }} autoPlay>
          Your browser does not support the audio element.
        </audio>
      </DialogContent>
    </Dialog>
  );
};

export default AudioPlayerModal;