import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  InputAdornment,
  Popover,
  TextField,
  CircularProgress,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { alpha } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import { COLORS } from "../utils/colors";
import { Note, NoteAlt } from "@mui/icons-material";
import { useEditor, EditorContent } from "@tiptap/react";
import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { extractWikiLinks, typewriterEffect } from "../utils/helperMethods";
import { formatTitle } from "../utils/helperMethods";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css"; // choose your theme

const MarkdownContent = styled(ReactMarkdown)`
  color: ${COLORS.niceSuperLightGrey};
  font-size: 14px;
  white-space: pre-wrap;
  word-break: break-word;

  p {
    margin-bottom: 1em;
  }

  code {
    background-color: ${COLORS.niceBlack};
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: "Courier New", Courier, monospace;
  }

  pre {
    background-color: ${COLORS.niceBlack};
    padding: 1em;
    border-radius: 5px;
    overflow-x: auto;
  }

  blockquote {
    border-left: 4px solid ${COLORS.nicePurple};
    padding-left: 1em;
    margin-left: 0;
    font-style: italic;
  }

  ul,
  ol {
    padding-left: 2em;
  }
`;

const EditorContainer = styled(Box)`
  background-color: #2a2a2a;
  padding: 8px;
  border-radius: 8px;
  width: 90%;
  margin-top: 8px;
  min-height: 50px;
  overflow: auto;

  /* WebKit browsers */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background-color: ${COLORS.niceDarkGray};
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .ProseMirror {
    height: 100%;
    min-height: 50px;
    outline: none;
    color: ${COLORS.niceSuperLightGrey};
    background-color: transparent;

    &:focus {
      outline: none;
    }

    p {
      margin: 0;
    }

    a {
      color: ${COLORS.niceWhite};
      font-weight: 700;
      line-height: 1.5;
    }

    &.is-empty::before {
      content: attr(data-placeholder);
      float: left;
      color: rgba(255, 255, 255, 0.5);
      pointer-events: none;
      height: 0;
    }

    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: rgba(255, 255, 255, 0.5);
      pointer-events: none;
      height: 0;
    }
  }
`;

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: COLORS.niceDeepBlack,
    border: `1px solid ${COLORS.nicePurple}40`,
    borderRadius: "8px",
    overflow: "hidden",
  },
}));

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: COLORS.niceSuperLightGrey,
    backgroundColor: `${COLORS.nicePurple}10`,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: `${COLORS.nicePurple}20`,
    },
  },
  "& .MuiInputBase-input": {
    padding: "8px 12px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const LinkDropdown = ({
  showLinkDropdown,
  linkDropdownAnchorEl,
  filteredNotes,
  handleNoteSelection,
  allNotes,
  setShowLinkDropdown,
  currentFile,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const listRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);

  const searchedNotes = useMemo(() => {
    return (
      filteredNotes &&
      filteredNotes
        .filter((note) => note.id !== currentFile.id)
        .filter((note) =>
          note.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
  }, [filteredNotes, searchTerm, currentFile]);

  useEffect(() => {
    if (showLinkDropdown && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [showLinkDropdown]);

  const handleInputClick = (e) => {
    e.stopPropagation();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!showLinkDropdown) return;

      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) => (prevIndex + 1) % searchedNotes.length
          );
          break;
        case "ArrowUp":
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) =>
              (prevIndex - 1 + searchedNotes.length) % searchedNotes.length
          );
          break;
        case "Enter":
          e.preventDefault();
          handleNoteSelection(searchedNotes[selectedIndex]);
          break;
        case "Escape":
          e.preventDefault();
          setShowLinkDropdown(false);
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [
    showLinkDropdown,
    searchedNotes,
    selectedIndex,
    handleNoteSelection,
    setShowLinkDropdown,
  ]);

  useEffect(() => {
    if (listRef.current) {
      const selectedElement = listRef.current.children[selectedIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: "nearest" });
      }
    }
  }, [selectedIndex]);

  return (
    <StyledPopover
      open={showLinkDropdown}
      anchorReference="anchorPosition"
      anchorPosition={linkDropdownAnchorEl}
      onClose={() => setShowLinkDropdown(false)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          backgroundColor: alpha(COLORS.niceDeepBlack, 0.95),
          borderRadius: "8px",
          width: "350px",
          overflow: "hidden",
          boxShadow: `0 4px 20px rgba(0, 0, 0, 0.3)`,
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: "8px",
            padding: "1px",
            background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
            mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            maskComposite: "exclude",
            pointerEvents: "none",
          },
        },
      }}
    >
      <StyledTextField
        fullWidth
        size="small"
        placeholder="Search notes..."
        value={searchTerm}
        autoComplete="off"
        onChange={(e) => setSearchTerm(e.target.value)}
        onClick={handleInputClick}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: COLORS.niceSuperLightGrey }} />
            </InputAdornment>
          ),
        }}
        inputRef={inputRef}
      />
      <List
        ref={listRef}
        sx={{
          maxHeight: "200px",
          overflowY: "auto",
          padding: "8px 0",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: alpha(COLORS.nicePurple, 0.5),
            borderRadius: "2px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: COLORS.nicePurple,
          },
        }}
      >
        {searchedNotes &&
          searchedNotes.map((note, index) => (
            <ListItem
              key={note.id}
              button
              selected={index === selectedIndex}
              onClick={() => handleNoteSelection(note)}
              sx={{
                padding: "4px 16px",
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor: alpha(COLORS.niceBlack, 0.1),
                  color: COLORS.niceBlack,
                  "& .MuiListItemText-primary": {
                    color: COLORS.niceSuperLightGrey,
                    fontWeight: 500,
                    fontSize: "14px",
                  },
                },
                "&.Mui-selected": {
                  backgroundColor: alpha(COLORS.niceBlack, 0.2),
                  color: note.color || COLORS.niceBlue,
                  "& .MuiListItemText-primary": {
                    color: note.color || COLORS.niceBlue,
                    fontWeight: 500,
                    fontSize: "14px",
                  },
                },
                "&.Mui-selected:hover": {
                  backgroundColor: alpha(COLORS.niceBlack, 0.3),
                  color: note.color || COLORS.niceBlue,
                  "& .MuiListItemText-primary": {
                    color: note.color || COLORS.niceBlue,
                    fontWeight: 500,
                    fontSize: "14px",
                  },
                },
              }}
            >
              <ListItemText
                primary={formatTitle(note.title)}
                primaryTypographyProps={{
                  noWrap: true,
                  sx: {
                    color: COLORS.niceSuperLightGrey,
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                }}
              />
            </ListItem>
          ))}
      </List>
    </StyledPopover>
  );
};

const ScrollableBox = styled(Box)`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  /* WebKit browsers */
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.1);
`;
const MessageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px; // Increased bottom padding
  width: 100%;
  height: 100%;
  max-width: 800px; // Optional: set a maximum width for larger screens
`;

const ChatView = forwardRef(({ currentFile, files, user }, ref) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showLinkDropdown, setShowLinkDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [linkDropdownAnchorEl, setLinkDropdownAnchorEl] = useState({
    top: 0,
    left: 0,
  });
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [selectedNoteColor, setSelectedNoteColor] = useState(COLORS.niceBlue);
  const editorRef = useRef(null);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [animatedMessages, setAnimatedMessages] = useState({});
  const scrollRef = useRef(null);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages, animatedMessages]);

  useEffect(() => {
    const newMessage = messages[messages.length - 1];
    if (newMessage && newMessage.sender === "ai") {
      typewriterEffect(newMessage.message, (typedText) => {
        setAnimatedMessages((prev) => ({
          ...prev,
          [newMessage.id]: typedText,
        }));
      });
    }
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (files) {
      setFilteredNotes(files.filter((note) => note.id !== files.id));
      if (editorRef.current) {
        checkForWikiLink(editorRef.current);
      }
    }
  }, [files]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "paragraph") {
            return "Ask anything (Ctrl + L)";
          }
          return "";
        },
        showOnlyCurrent: false,
      }),
    ],
    content: "",
    editorProps: {
      attributes: {
        class: "custom-editor-class",
      },
    },
    onUpdate: ({ editor }) => {
      // if user is not premiumUser navigate to /pricing
      if (!user?.premiumUser || !user?.premiumUser?.isActive) {
        toast("Become a member to use this feature", {
          position: "top-right",
        });
        navigate("/pricing");
      }
      setInputMessage(editor.getText());
      checkForWikiLink(editor);
    },
    editorStyle: {
      height: "100%",
      color: COLORS.cursorShWhite,
      fontSize: "12px",
    },
  });

  useImperativeHandle(ref, () => ({
    focusEditor: () => {
      if (editor) {
        editor.commands.focus("end");
      }
    },
  }));

  const checkForWikiLink = (editor) => {
    const { selection } = editor.state;
    const { $from } = selection;
    const currentLine = $from.nodeBefore?.text || "";
    const lastTwoChars = currentLine.slice(-2);
    if (lastTwoChars === "[[") {
      const rect = editor.view.coordsAtPos($from.pos);
      setLinkDropdownAnchorEl({ top: rect.bottom, left: rect.left });

      setShowLinkDropdown(true);
    } else {
      setShowLinkDropdown(false);
    }
  };

  const handleNoteSelection = (selectedNote) => {
    setShowLinkDropdown(false);
    setSelectedNoteColor(selectedNote.color || COLORS.niceBlue);

    setSelectedNotes((prevNotes) => {
      if (!prevNotes.some((note) => note.id === selectedNote.id)) {
        return [...prevNotes, selectedNote];
      }
      return prevNotes;
    });

    if (editor) {
      const { state } = editor;
      const { selection } = state;
      const { $from } = selection;

      // Find the position of the last '[['
      const currentLine = $from.nodeBefore?.text || "";
      const lastBracketIndex = currentLine.lastIndexOf("[[");

      if (lastBracketIndex !== -1) {
        // Delete the '[[' characters
        editor
          .chain()
          .focus()
          .setTextSelection({ from: $from.pos - 2, to: $from.pos })
          .deleteSelection()
          .run();

        // Insert the note title (without brackets)
        const noteTitle = selectedNote.title;
        editor.chain().focus().insertContent(noteTitle).run();

        // Set the link on the inserted title
        const linkStart = $from.pos - 2;
        const linkEnd = linkStart + noteTitle.length;

        editor
          .chain()
          .focus()
          .setTextSelection({ from: linkStart, to: linkEnd })
          .setLink({ href: `[[${selectedNote.slug}]]` })
          .run();

        // Move the cursor to the end of the link
        editor.commands.setTextSelection(linkEnd);

        // Unset the selection to prevent further text from being part of the link
        editor.commands.unsetMark("link");
      }
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "") {
      setLoading(true);
      const editorContent = editor.getText();

      try {
        const response = await fetch(
          "https://us-central1-notedfm-f6490.cloudfunctions.net/getChatGPTResponse",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              agent: {
                name: "AI Assistant",
                role: "assistant",
                responseCriteria: {
                  personality: ["humorous", "empathetic"],
                  length: "medium",
                  responseStructure: {
                    strict: "",
                  },
                },
              },
              chatLog: messages,
              message: editorContent,
              currentFile: currentFile
                ? { title: currentFile.title, content: currentFile.content }
                : null,
              userId: user.id,
              selectedNotes: selectedNotes.map((note) => ({
                title: note.title,
                content: note.content,
              })),
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Add the user's message and AI's response to the chat log
        setMessages((prevMessages) => [
          ...prevMessages,
          { id: `user-${Date.now()}`, sender: "user", message: editorContent },
          { id: `ai-${Date.now()}`, sender: "ai", message: data.response },
        ]);
      } catch (error) {
        console.error("Error getting AI response:", error);
      } finally {
        setLoading(false);
      }

      setInputMessage("");
      editor.commands.setContent("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const renderEditorOptions = () => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showLinkDropdown && (
          <LinkDropdown
            currentFile={currentFile}
            showLinkDropdown={showLinkDropdown}
            linkDropdownAnchorEl={linkDropdownAnchorEl}
            filteredNotes={filteredNotes}
            handleNoteSelection={handleNoteSelection}
            allNotes={files}
            setShowLinkDropdown={setShowLinkDropdown}
          />
        )}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "transparent",
            width: "90%",
            borderRadius: "8px",
            gap: 1,
          }}
        >
          <Box>
            <Typography
              variant="caption"
              sx={{ color: "rgba(255, 255, 255, 0.5)" }}
            >
              Type [[ to include notes or templates
            </Typography>
          </Box>
          <IconButton onClick={handleSendMessage}>
            {loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : (
              <SendIcon sx={{ color: "white", fontSize: "16px" }} />
            )}{" "}
          </IconButton>
        </Box>

        <Box
          sx={{
            width: "90%",
            flexGrow: 1,
            overflowY: "auto",
            borderRadius: "8px",
          }}
        >
          <Typography
            sx={{ color: "rgba(255, 255, 255, 0.5)", fontSize: "9px" }}
          >
            {loading ? "Using..." : "Will use:"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <NoteAlt
              sx={{ fontSize: "12px", color: "rgba(255, 255, 255, 0.5)" }}
            />
            <Typography
              sx={{
                pt: 0.5,
                color: "rgba(255, 255, 255, 0.5)",
                fontSize: "13px",
              }}
            >
              {currentFile ? `${currentFile?.title}` : "No file selected"}
            </Typography>
            <Typography
              sx={{
                pt: 0.5,
                color: "rgba(255, 255, 255, 0.5)",
                fontSize: "13px",
              }}
            >
              (current file)
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {selectedNotes.map((note) => (
              <>
                <NoteAlt
                  sx={{ fontSize: "12px", color: "rgba(255, 255, 255, 0.5)" }}
                />
                <Typography
                  key={note.id}
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    fontSize: "13px",
                    pt: 0.5,
                  }}
                >
                  {note.title}
                </Typography>
              </>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        borderLeft: "1px solid #333",
        flexDirection: "column",
        height: "100%",
        backgroundColor: COLORS.niceDeepBlack,
        alignItems: "center",
      }}
    >
      <MessageContainer>
        <EditorContainer noteColor={selectedNoteColor}>
          <EditorContent editor={editor} onKeyDown={handleKeyPress} />
        </EditorContainer>
        {renderEditorOptions()}
        <ScrollableBox ref={scrollRef}>
          <Box
            sx={{
              width: "90%",
              mt: 2,
              overflowY: "auto",
              flexGrow: 1,
            }}
          >
            {messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  mb: 1,
                  pb: 2,
                  backgroundColor: "transparent",
                }}
              >
                {message.sender === "user" ? (
                  <Typography
                    sx={{
                      color: COLORS.niceSuperLightGrey,
                      fontStyle: "italic",
                      textAlign: "right",
                      fontSize: "14px",
                    }}
                  >
                    {message.message}
                  </Typography>
                ) : (
                  <MarkdownContent
                    remarkPlugins={[remarkGfm]}
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || "");
                        return !inline && match ? (
                          <SyntaxHighlighter
                            style={vscDarkPlus}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                          >
                            {String(children).replace(/\n$/, "")}
                          </SyntaxHighlighter>
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        );
                      },
                    }}
                  >
                    {animatedMessages[message.id] || ""}
                  </MarkdownContent>
                )}
              </Box>
            ))}
          </Box>
        </ScrollableBox>
      </MessageContainer>
    </Box>
  );
});

export default ChatView;
