import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  useForkRef,
  CardContent,
  Card,
  Chip,
  CardActionArea,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { COLORS } from "../utils/colors";
import Header from "../components/Header";
import GlobalStateContext from "../context/globalStateContext";
import UserContext from "../context/userContext";
import { keyframes } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { fetchUserInfo, logoutFirebase } from "../utils/helperMethods";
import { auth } from "../firebaseConfig";
import SignupModal from "../components/SignupModal";
import { publicTopics } from "../utils/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { motion } from "framer-motion";
import Footer from "../components/Footer";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;
const hoverAnimation = keyframes`
  0%, 100% { 
    color: ${COLORS.niceWhite};
    border-bottom: 2px solid transparent;
    transform: translateY(0);
  }
  50% { 
    color: ${COLORS.nicePurple};
    border-bottom: 2px solid ${COLORS.nicePurple};
    transform: translateY(-2px);
  }
`;

const PageWrapper = styled(Box)({
  backgroundColor: COLORS.niceDeepBlack,
  minHeight: "100vh",
  color: COLORS.niceWhite,
});

const PageContainer = styled(Box)(({ theme }) => ({
  minHeight: "70vh",
  backgroundColor: COLORS.niceDeepBlack,
  color: COLORS.niceWhite,
  display: "flex",
  flexDirection: "column",
  overflowX: "hidden",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  animation: `${fadeIn} 0.8s ease-out`,
}));

// TODO add the view count for PEAK CURISOTY
const ViewCount = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: COLORS.niceWhite,
  opacity: 0.7,
  fontSize: "0.8rem",
}));

const MainContent = styled(Container)(({ theme }) => ({
  flex: 1,
  display: "flex",
  minHeight: "50vh",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "clamp(3rem, 10vw, 5rem)",
  fontWeight: 900,
  cursor: "default",
  letterSpacing: "-0.02em",
  marginBottom: theme.spacing(2),
  background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  animation: `${fadeIn} 0.8s ease-out`,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: "clamp(1rem, 3vw, 1.5rem)",
  fontWeight: 400,
  cursor: "default",
  color: COLORS.nicePurple,
  marginBottom: theme.spacing(4),
  opacity: 0.8,
  animation: `${fadeIn} 0.8s ease-out 0.2s`,
  animationFillMode: "both",
}));

const TopicScroller = styled(Box)({
  display: "flex",
  overflowX: "auto",
  whiteSpace: "nowrap",
  padding: "20px 0",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
});

const TopicItem = styled(Typography)(
  ({ theme, isAnimating, index, isActive }) => ({
    padding: "10px 20px",
    margin: "0 10px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    // borderBottom: '2px solid transparent',
    color: isActive ? COLORS.nicePurple : COLORS.niceWhite,
    borderBottom: isActive
      ? `2px solid ${COLORS.nicePurple}`
      : "2px solid transparent",
    transform: isActive ? "translateY(-2px)" : "translateY(0)",
    "&:hover": {
      color: COLORS.nicePurple,
      borderBottom: `2px solid ${COLORS.nicePurple}`,
      transform: "translateY(-2px)",
    },
    animation: isAnimating
      ? `${hoverAnimation} 1s ease ${index * 0.2}s`
      : "none",
  })
);

const FolderCategory = styled(Typography)(({ theme }) => ({
  color: COLORS.nicePurple,
  fontSize: "0.8rem",
  fontWeight: 600,
  textTransform: "uppercase",
  letterSpacing: "0.5px",
}));

const FolderCard = styled(motion.div)(({ theme, folderColor }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  borderRadius: "12px",
  padding: "1rem 1.5rem 0.75rem",
  cursor: "pointer",
  position: "relative",
  overflow: "hidden",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  height: "auto",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",

  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "2px",
    background: folderColor || COLORS.nicePurple,
  },

  "&:hover::before": {
    height: "6px",
    background: folderColor || COLORS.nicePurple,
  },

  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
  },
}));

const FolderTitle = styled(Typography)(({ theme }) => ({
  color: COLORS.niceWhite,
  fontWeight: 600,
  fontSize: "1.2rem",
  marginBottom: "0.5rem",
}));

const FolderDescription = styled(Typography)(({ theme }) => ({
  color: COLORS.niceWhite,
  opacity: 0.7,
  fontSize: "0.8rem",
  lineHeight: "1.2rem",
  paddingBottom: "1rem",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
}));

const FolderMeta = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "auto",
}));

const FolderChip = styled(Chip)(({ theme }) => ({
  backgroundColor: COLORS.nicePurple,
  color: COLORS.niceWhite,
}));

const FolderGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  width: "75%",
  alignSelf: "center",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
  gap: theme.spacing(3),
}));

const FolderCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const DiscoverPage = () => {
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [activeFolderType, setActiveFolderType] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const animationTimeoutRef = useRef(null);
  const [currentFolders, setCurrentFolders] = useState([]);
  const [isContentLoading, setIsContentLoading] = useState(true);

  const userInfoRef = useRef(null);

  useEffect(() => {
    if (activeFolderType) {
      const fetchPublicFolders = async () => {
        try {
          const response = await fetch(
            `https://us-central1-notedfm-f6490.cloudfunctions.net/getPublicFolders`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ category: activeFolderType }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch public folders");
          }

          const data = await response.json();

          setCurrentFolders(data.folders);
          setIsContentLoading(false);
        } catch (error) {
          console.error("Error fetching public folders:", error);
        }
      };

      fetchPublicFolders();
    }
  }, [activeFolderType]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // User is signed in
        const userInfo = await fetchUserInfo(authUser.uid);
        userInfoRef.current = userInfo;
        setUser((prevUser) => {
          const newUser = { ...prevUser, ...userInfo };
          return newUser;
        });
      } else {
        // User is signed out
        userInfoRef.current = null;
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [setUser]);

  useEffect(() => {
    setIsAnimating(true);
    animationTimeoutRef.current = setTimeout(() => {
      setIsAnimating(false);
      setActiveFolderType(publicTopics[0]); // Set the first topic as active after animation
    }, publicTopics.length * 200 + 1000); // Total animation duration

    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    console.log("User state updated:", user);
  }, [user]);

  const renderFolders = () => {
    if(isContentLoading)return   <Box
    sx={{
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: 'hidden',
    }}
  >
    <CircularProgress color="secondary" sx={{ alignSelf: "center" }} />
  </Box>

    return (
      <FolderGrid>
        {  currentFolders.map((folder) => (
            <FolderCard
              onClick={() => {
                const url = `${window.location.origin}/${folder?.username}/${folder?.slug}`;
                window.open(url, "_blank", "noopener,noreferrer");
              }}
              key={folder.id}
              folderColor={folder.folderColor}
            >
              <FolderCardContent>
                <FolderTitle variant="h6">{folder.name}</FolderTitle>
                <FolderDescription>
                  {folder.notes.length} note
                  {folder.notes.length !== 1 ? "s" : ""}
                </FolderDescription>
                <FolderMeta>
                  <FolderCategory>{folder.category.name}</FolderCategory>
                  <Typography
                    onClick={() => {
                      const url = `${window.location.origin}/${folder?.username}/all`;
                      window.open(url, "_blank", "noopener,noreferrer");
                    }}
                    sx={{ cursor: "pointer" }}
                    variant="caption"
                    color={COLORS.niceGrey}
                  >
                    by{" "}
                    {folder.username &&
                      (folder.username.length > 15
                        ? `${folder.username.slice(0, 15)}...`
                        : folder.username)}
                  </Typography>
                </FolderMeta>
              </FolderCardContent>
            </FolderCard>
          ))}
      </FolderGrid>
    );
  };

  return (
    <PageWrapper>
      <PageContainer>
        <HeaderWrapper>
          <Header
            user={userInfoRef.current}
            globalState={globalState}
            setGlobalState={setGlobalState}
            noLogin={false}
          />
        </HeaderWrapper>
        <MainContent maxWidth="lg">
          <Title variant="h1">Discover</Title>
          <Subtitle variant="h2">
            mind maps made by users on various topics
          </Subtitle>
        </MainContent>
        <Box
          sx={{
            display: "flex",
            width: "100vw",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TopicScroller>
            {publicTopics.map((topic, index) => (
              <TopicItem
                isActive={activeFolderType === topic}
                key={index}
                variant="h6"
                onClick={() => setActiveFolderType(topic)}
                isAnimating={isAnimating}
                index={index}
              >
                {topic.name}
              </TopicItem>
            ))}
          </TopicScroller>
        </Box>
        <Box sx={{ mt: "1rem", mb: "1rem" }} />
        {renderFolders()}
      </PageContainer>
      {globalState?.showSignupModal && (
        <SignupModal setUser={setUser} user={user} />
      )}
      <Box sx={{ mt: "1rem", mb: "1rem" }} />
      <Footer />
    </PageWrapper>
  );
};

export default DiscoverPage;
