import { useState } from "react";
import { Button, Typography, CircularProgress } from "@mui/material";
import AutoLinkIcon from '@mui/icons-material/AutoAwesome';
import { COLORS } from "../utils/colors";
import CustomTooltip from "./CustomTooltip";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css"; // choose your theme
import { useNavigate } from "react-router-dom";

const SmartLinkButton = ({ user, note, handleGenerateSmartLinks }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const navigate = useNavigate();
    const handleClick = async () => {
      // check if user is premium user or inactive
      if (!user?.premiumUser || !user?.premiumUser?.isActive) {
        toast("Become a member to use this feature", {
          position: "top-right",
        });

        navigate("/pricing");
        return;
      }
      setIsGenerating(true);
      toast('Linking similar notes...', { position: 'top-right'})
      await handleGenerateSmartLinks();
      setIsGenerating(false);
    };
  
    return (
      <CustomTooltip title="Hyperlink related notes to this one">
        <Button
          onClick={handleClick}
          startIcon={isGenerating ? <CircularProgress size={16} color="inherit" /> : <AutoLinkIcon />}
          sx={{
            p: '4px 8px',
            m: 0,
            backgroundColor: COLORS.niceBlack,
            color: note?.color || COLORS.nicePurple,
            borderRadius: '12px',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: `${COLORS.nicePurple}20`,
              transform: 'translateY(-2px)',
              boxShadow: `0 4px 8px ${COLORS.nicePurple}40`,
            },
          }}
        >
          <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
            {isGenerating ? 'Generating...' : 'Auto Link'}
          </Typography>
        </Button>
      </CustomTooltip>
    );
  };
  

  export default SmartLinkButton;
