import React, { useState, useContext, useEffect } from "react";
import {
  Modal,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/colors";
import RedClose from "./RedClose";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import styled from "styled-components";
import { database, firestore } from "../firebaseConfig";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css";
import LinkIcon from "@mui/icons-material/Link";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { publicTopics } from "../utils/constants";

const GradientButton = styled(Button)`
  background: linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue});
  color: ${COLORS.niceWhite};
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;

  &:hover {
    opacity: 0.9;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(188, 144, 207, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(227, 132, 163, 0.4);
  }
`;

const ShareMindmapModal = ({
  user,
  setUser,
  open,
  onClose,
  folder,
  setFolders,
  folders,
}) => {
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [username, setUsername] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLinkUrl, setShowLinkUrl] = useState(false);
  const [showBlindingPublicStatus, setShowBlindingPublicStatus] =
    useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const [shareableUrl, setShareableUrl] = useState("");
  const [selectedTopic, setSelectedTopic] = useState(
    publicTopics.find((topic) => topic.id === folder?.category) || null
  );

  useEffect(() => {
    // make the setShowLinkUrl (false) unless its true as a prp
    if(folder && folder?.public)setShowLinkUrl(true)
      else setShowLinkUrl(false)
  }, [folder])

  useEffect(() => {
    if (user?.username) {
      if (folder && folder.public) {
        setShowLinkUrl(true);
        setShowBlindingPublicStatus(true);
      }
    }
  }, [user?.username, folder]);

  const updateFolderPublicStatus = async (folderId, isPublic, user) => {
    if (!user?.id || !folderId) {
      console.error("User ID or Folder ID is missing");
      return;
    }

    try {
      // Update the folder in the database
      const folderRef = database.ref(`notes/${user.id}/folders/${folderId}`);
      await folderRef.update({ public: isPublic, category: selectedTopic, username: user?.username });

      // Update local state
      setFolders((prevFolders) => ({
        ...prevFolders,
        [folderId]: {
          ...prevFolders[folderId],
          public: isPublic,
          category: selectedTopic,
          username: user?.username,
        },
      }));

      setShowLinkUrl(isPublic);
      setShowBlindingPublicStatus(isPublic);
    } catch (error) {
      console.error("Error updating folder public status:", error);
      toast("Error updating folder status", {
        position: "top-right",
      });
    }
  };

  const isValidUsername = (username) => {
    return /^[a-z0-9](?:[a-z0-9_-]*[a-z0-9])?$/.test(username);
  };

  const handleUsernameChange = (e) => {
    const newValue = e.target.value.toLowerCase().replace(/[^a-z0-9_-]/g, "");
    setUsername(newValue);
  };

  const handleUsernameSubmit = async () => {
    if (!isValidUsername(username)) {
      toast("Invalid username format");
      return;
    }

    setIsSubmitting(true);

    try {
      const userRef = firestore.collection("users").doc(user.id);

      // Check if the username is already taken
      const usernameQuery = await firestore
        .collection("users")
        .where("username", "==", username)
        .get();

      if (!usernameQuery.empty) {
        toast("Username is already taken");
        setIsSubmitting(false);
        return;
      }

      // Update the user document with the new username
      await userRef.update({
        username: username,
        updatedAt: Date.now(),
      });

      // Update local user state
      setUser((prevUser) => ({
        ...prevUser,
        username: username,
      }));

      // Update all the folders of this user with .username property
      const updatedUsersFolders = Object.entries(folders).reduce(
        (acc, [folderId, folderData]) => {
          acc[folderId] = { ...folderData, username: username };
          return acc;
        },
        {}
      );

      // Update folders in the database
      const folderUpdates = Object.entries(updatedUsersFolders).map(
        ([folderId, folderData]) => {
          return database
            .ref(`notes/${user.id}/folders/${folderId}`)
            .update({ username: username });
        }
      );

      await Promise.all(folderUpdates);

      // Update local folders state
      setFolders(updatedUsersFolders);

      toast("Username successfully set!");
      onClose();
    } catch (error) {
      console.error("Error setting username:", error);
      toast("Error setting username. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTopicChange = (event) => {
    const selectedTopicObject = publicTopics.find(
      (topic) => topic.id === event.target.value
    );
    setSelectedTopic(selectedTopicObject);
  };

  const handleMakePublic = () => {
    // check if category selected
    if (!selectedTopic) {
      toast("Please select a category");
      return;
    }
 
    updateFolderPublicStatus(folder.id, true, user);
    setShowLinkUrl(true);
  };

  const handleMakePrivate = () => {
    updateFolderPublicStatus(folder.id, false, user);
    setShowLinkUrl(false);
  };

  const renderCaptureUsername = () => (
    <Box sx={{ padding: "16px" }}>
      <Typography
        variant="h6"
        sx={{ mb: 2, color: "#FFFFFF", textAlign: "center" }}
      >
        Enter a username first
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        value={username}
        onChange={handleUsernameChange}
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root": {
            color: "#FFFFFF",
            "& fieldset": {
              borderColor: "rgba(255, 255, 255, 0.23)",
            },
            "&:hover fieldset": {
              borderColor: "rgba(255, 255, 255, 0.5)",
            },
            "&.Mui-focused fieldset": {
              borderColor: COLORS.niceBlue,
            },
          },
        }}
      />
      <GradientButton
        variant="contained"
        onClick={handleUsernameSubmit}
        fullWidth
      >
        NEXT
      </GradientButton>
    </Box>
  );

  const renderShareContent = () => (
    <Box
      sx={{
        pb: 2,
        width: "90%",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontWeight: "bold",
          color: COLORS.niceWhite,
          textAlign: "center",
        }}
      >
        Are you sure you want your {folder?.name && folder.name.toLowerCase()}{" "}
        notes & mind map to be public?
      </Typography>
      <FormControl
        fullWidth
        sx={{
          mb: 3,
          "& .MuiOutlinedInput-root": {
            color: COLORS.niceWhite,
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            "& fieldset": {
              borderColor: "rgba(255, 255, 255, 0.23)",
            },
            "&:hover fieldset": {
              borderColor: "rgba(255, 255, 255, 0.5)",
            },
            "&.Mui-focused fieldset": {
              borderColor: COLORS.niceBlue,
            },
          },
          "& .MuiInputLabel-root": {
            color: COLORS.niceWhite,
          },
          "& .MuiSelect-icon": {
            color: COLORS.niceWhite,
          },
        }}
      >
        <InputLabel id="topic-select-label">Select a category</InputLabel>
        <Select
          labelId="topic-select-label"
          id="topic-select"
          value={selectedTopic ? selectedTopic.id : ""}
          onChange={handleTopicChange}
          label="Select a category"
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: "#2C2C2C",
                "& .MuiMenuItem-root": {
                  color: COLORS.niceWhite,
                  "&:hover": {
                    bgcolor: "rgba(255, 255, 255, 0.08)",
                  },
                  "&.Mui-selected": {
                    bgcolor: "rgba(255, 255, 255, 0.16)",
                    "&:hover": {
                      bgcolor: "rgba(255, 255, 255, 0.24)",
                    },
                  },
                },
              },
            },
          }}
        >
          {publicTopics.map((topic) => (
            <MenuItem key={topic.id} value={topic.id}>
              {topic.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          onClick={handleMakePublic}
          sx={{
            bgcolor: COLORS.niceGreen,
            color: COLORS.niceWhite,
            fontWeight: "bold",
            "&:hover": {
              bgcolor: COLORS.niceWhite,
              color: COLORS.niceGreen,
              opacity: 0.9,
            },
          }}
        >
          Yes, make it public
        </Button>
      </Box>
    </Box>
  );

  const renderLinkUrl = () => (
    <Box
      sx={{
        pb: 2,
        width: "90%",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* BEAUTIFUL GREEN YOU"RE LIVE  */}
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontWeight: "bold",
          color: COLORS.niceGreen,
          textAlign: "center",
        }}
      >
        YOU'RE LIVE!
      </Typography>
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontWeight: "bold",
          color: COLORS.niceWhite,
          textAlign: "center",
        }}
      >
        Share your mind map
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          borderRadius: "12px",
          padding: "12px 16px",
          marginBottom: "16px",
          width: "90%",
          cursor: "pointer",
          transition: "all 0.3s ease",
          border: `1px solid ${COLORS.nicePurple}40`,
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            boxShadow: `0 0 15px ${COLORS.nicePurple}40`,
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          // copy to clipboard
          navigator.clipboard.writeText(
            `https://noted.fm/${user?.username}/${folder?.slug}`
          );
          setShowCopied(true);
          setTimeout(() => {
            setShowCopied(false);
          }, 2000);
        }}
      >
        <LinkIcon sx={{ color: COLORS.nicePurple, marginRight: "12px" }} />
        <Typography
          sx={{
            color: COLORS.niceWhite,
            flexGrow: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: 500,
          }}
        >
          {`https://noted.fm/${user?.username}/${folder?.slug}`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: COLORS.nicePurple,
            borderRadius: "8px",
            padding: "6px 12px",
            cursor: "pointer",
            transition: "all 0.3s ease",
            "&:hover": {
              backgroundColor: COLORS.nicePink,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(
              `https://noted.fm/${user?.username}/${folder?.slug}`
            );
            setShowCopied(true);
            setTimeout(() => {
              setShowCopied(false);
            }, 2000);
          }}
        >
          <ContentCopyIcon
            sx={{
              color: COLORS.niceWhite,
              fontSize: "18px",
              marginRight: "4px",
            }}
          />
        </Box>
        {/* ADD A SMALL OPENBUTTON TO OPEN THE LINK IN A NEW TAB */}
        <IconButton
          onClick={() =>
            window.open(
              `https://noted.fm/${user?.username}/${folder?.slug}`,
              "_blank"
            )
          }
          size="small"
          sx={{ color: COLORS.niceWhite }}
        >
          <OpenInNewOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
      {showCopied && (
        <Typography
          sx={{ color: COLORS.niceWhite, fontSize: "14px", fontWeight: "bold" }}
        >
          Copied!
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 10,
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          onClick={handleMakePrivate}
          sx={{
            bgcolor: COLORS.primaryRed,
            color: COLORS.niceWhite,
            fontWeight: "bold",
            "&:hover": {
              bgcolor: COLORS.niceWhite,
              color: COLORS.primaryRed,
              opacity: 0.9,
            },
          }}
        >
          MAKE MIND MAP PRIVATE
        </Button>
      </Box>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        setShowLinkUrl(false);
        onClose();
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#1E1E1E",
          borderRadius: "16px",
          width: isMobile ? "90%" : "400px",
          maxHeight: "90vh",
          overflow: "auto",
          boxShadow: "0 4px 30px rgba(0,0,0,0.3)",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2.5 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {folder?.name && (
              <Typography
                variant="h6"
                sx={{
                  color: COLORS.niceSuperLightGrey,
                  textAlign: "center",
                }}
              >
                {folder.name}
              </Typography>
            )}
            {showBlindingPublicStatus && (
              <>
                <Box
                  sx={{
                    ...styles.syncingDot,
                    ml: 1.5,
                    backgroundColor: "#4CAF50",
                  }}
                />
                <Typography sx={styles.syncingText}>Public</Typography>{" "}
              </>
            )}
          </Box>

          <RedClose
            handleClose={() => {
              setShowLinkUrl(false);
              onClose();
            }}
          />
        </Box>
        {user?.username
          ? showLinkUrl
            ? renderLinkUrl()
            : renderShareContent()
          : renderCaptureUsername()}
      </Box>
    </Modal>
  );
};
const styles = {
  syncingDot: {
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    backgroundColor: COLORS.niceSyncingGreen,
    marginLeft: "6px",
    animation: "flicker 1s infinite",
    "@keyframes flicker": {
      "0%, 100%": { opacity: 1 },
      "50%": { opacity: 0.5 },
    },
  },
  syncingText: {
    color: COLORS.niceSyncingGreen,
    fontSize: "12px",
    pl: 1,
    animation: "flicker 1s infinite",
    "@keyframes flicker": {
      "0%, 100%": { opacity: 1 },
      "50%": { opacity: 0.5 },
    },
  },
};

export default ShareMindmapModal;
