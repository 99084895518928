import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  CircularProgress,
  alpha,
} from "@mui/material";
import VisitorHeader from "../components/VisitorHeader";
import { COLORS } from "../utils/colors";
import { useNavigate, useParams } from "react-router-dom";
import { auth, database, firestore } from "../firebaseConfig";
import styled from "styled-components";
import { motion } from "framer-motion";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Avatar, { genConfig } from "react-nice-avatar";
import RightSidebar from "../components/RightSidebar";
import ShareIcon from "@mui/icons-material/Share";
import Badge from "@mui/material/Badge";
import { fetchUserInfo, isUserPremium } from "../utils/helperMethods";
import UserContext from "../context/userContext";
import GlobalStateContext from "../context/globalStateContext";
import SignupModal from "../components/SignupModal";
import MainContent from "../components/MainContent";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import useMediaQuery from "@mui/material/useMediaQuery";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";
import MapIcon from "@mui/icons-material/Map";
import NotesIcon from "@mui/icons-material/Notes";
import Youtube from "@tiptap/extension-youtube";
import Underline from "@tiptap/extension-underline";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { lowlight } from "lowlight/lib/core";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import ShareModal from "../components/ShareModal";

const PageContainer = styled(Box)`
  background-color: ${COLORS.niceDeepBlack};
  max-height: 100vh;
  min-height: 100vh;
`;

const BackButton = ({ onClick }) => (
  <Button
    startIcon={<ArrowLeftIcon />}
    onClick={onClick}
    sx={{
      top: "16px",
      color: COLORS.niceSuperLightGrey,
      "&:hover": {
        backgroundColor: `${COLORS.nicePurple}20`,
      },
    }}
  >
    Back to notes
  </Button>
);

const ContentContainer = styled(Box)`
  padding: 2rem;
`;

const NoteGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  padding-right: 1rem;
  max-height: calc(100vh - 300px); // Adjust this value based on your layout
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${COLORS.niceBlack} ${COLORS.niceBlack};

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${COLORS.niceDeepBlack};
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      45deg,
      ${COLORS.nicePurple},
      ${COLORS.nicePink}
    );
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, ${COLORS.nicePink}, ${COLORS.niceWhite});
  }

  // Add padding to the right side of the content
  & > *:last-child {
    padding-right: 1rem;
  }
`;

const NoteCard = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: auto;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: ${COLORS.niceWhite};
  }

  &:hover::before {
    height: 6px;
    background: ${(props) =>
      `linear-gradient(90deg, ${props.noteColor}, ${COLORS.niceWhite})`};
  }

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

const NoteTitle = styled(Typography)`
  color: ${COLORS.niceWhite};
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const NoteContent = styled(Typography)`
  color: ${COLORS.niceWhite};
  opacity: 0.7;
  font-size: 0.7rem;
  line-height: 1.2rem;
  padding-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const NoteFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

const SmallText = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.niceWhite}70;
  font-size: 0.8rem;
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 2rem;
  margin-top: 2rem;
  .MuiTabs-indicator {
    background-color: ${COLORS.niceWhite};
  }
`;

const StyledTab = styled(Tab)`
  color: ${COLORS.niceWhite} !important;
  opacity: 0.7;
  text-transform: none;
  font-size: 0.9rem;
  min-height: 48px;
  padding: 6px 16px;
  &.Mui-selected {
    color: ${COLORS.niceWhite} !important;
    opacity: 1;
  }
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: ${(props) => (props.isMobile ? "0" : "1rem")};
`;

const AvatarWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 1rem;
`;

const UserInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Username = styled(Typography)`
  color: ${COLORS.niceWhite};
  font-size: 1.5rem;
  font-weight: 600;
`;

const FolderCount = styled(Typography)`
  color: ${COLORS.niceWhite}80;
  font-size: 0.9rem;
`;

let DEFAULT_ALL_FOLDER = {
  folderColor: "hsl(285, 100%, 58%)",
  id: "love",
  name: "All Notes",
  slug: "all",
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -12,
    top: 13,
    border: `2px solid ${COLORS.niceDeepBlack}`,
    padding: "0 4px",
    backgroundColor: "transparent",
    color: COLORS.niceWhite,
  },
}));

const ShareButton = styled(FolderCount)`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  transition: color 0.3s ease;

  &:hover {
    color: ${COLORS.nicePink};
  }

  &:hover svg {
    color: ${COLORS.nicePink};
  }
`;

const FollowTypography = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  color: COLORS.nicePink,
  padding: 0,
  margin: 0,
  transition: "color 0.3s ease",
  "&:hover": {
    color: COLORS.niceWhite,
  },
}));

const SimpleEditor = ({ content, fileColor }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      CodeBlockLowlight.configure({
        lowlight,
      }),
      Link.configure({
        openOnClick: false,
        autolink: true,
        linkOnPaste: true,
        HTMLAttributes: {
          class: "tiptap-link",
        },
        bubble: true,
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading" && node.attrs.level === 1) {
            return "Start writing...";
          }
          return "";
        },
        showOnlyCurrent: false,
        includeChildren: true,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      Youtube.configure({
        controls: false,
        nocookie: true,
        modestBranding: true,
      }),
    ],
    content: content,
    editable: false,
  });

  return (
    <Box sx={styles.editorContainer(fileColor)}>
      <EditorContent editor={editor} />
    </Box>
  );
};

const MemoizedSimpleEditor = React.memo(SimpleEditor);

const KnowledgeMapPage = () => {
  // params
  const { username, folderSlug } = useParams();
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [folders, setFolders] = useState([]);
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeRightSidebar, setActiveRightSidebar] = useState("mindMap");
  const [activeNotes, setActiveNotes] = useState([]);
  const [activeFolder, setActiveFolder] = useState(null);
  const [currentNote, setCurrentNote] = useState(null);
  const [avatarConfig, setAvatarConfig] = useState(null);
  const [allPublicNotes, setAllPublicNotes] = useState([]);
  const navigate = useNavigate();
  const [authChecked, setAuthChecked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userInfoExpanded, setUserInfoExpanded] = useState(false);
  const [dialogAction, setDialogAction] = useState("");
  const isMobile = useMediaQuery("(max-width:768px)");
  const userRef = useRef(null);
  const [view, setView] = useState("map"); // 'map' or 'notes'
  const [mobileView, setMobileView] = useState("map");
  const [showNoteDetail, setShowNoteDetail] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // User is signed in
        const userInfo = await fetchUserInfo(authUser.uid);
        userRef.current = userInfo;
        setUser((prevUser) => ({ ...prevUser, ...userInfo }));
      } else {
        // User is signed out
        setUser(null);
      }
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const getUserInfo = async (username) => {
      try {
        const usersRef = firestore.collection("users");
        const querySnapshot = await usersRef
          .where("username", "==", username)
          .limit(1)
          .get();

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          return userDoc; // This is the user's UID
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    };

    const getFolders = async (username) => {
      try {
        const response = await fetch(
          "https://us-central1-notedfm-f6490.cloudfunctions.net/getFolders",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ username }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch folders");
        }
        return await response.json();
      } catch (error) {
        console.error("Error fetching folders:", error);
        throw error;
      }
    };

    const fetchData = async () => {
      if (username && folderSlug) {
        try {
          // Fetch the user's UID
          const userInfo = await getUserInfo(username);
          const userId = userInfo?.id || null;
          // if (!userId) {
          //   console.error("User not found");
          //   return;
          // }

          // Fetch folders
          const { folders, notes } = await getFolders(username);
          // Filter public folders
          const publicFolders = folders.filter(
            (folder) => folder.public === true
          );

          // Get all notes from public folders
          const allPublicNotes = notes.reduce((acc, note) => {
            if (
              publicFolders.some(
                (folder) =>
                  folder.notes && Object.keys(folder.notes).includes(note.id)
              )
            ) {
              acc[note.id] = note;
            }
            return acc;
          }, {});

          // Get folder's notes
          const currentFolder =
            folderSlug === "all"
              ? DEFAULT_ALL_FOLDER
              : folders.find((folder) => folder.slug === folderSlug);

          const currentFolderNotesIds = currentFolder?.notes || {};

          let currentFolderNotes = notes.filter((note) => {
            return Object.keys(currentFolderNotesIds).includes(note.id);
          });

          const uCurrentFolder = currentFolder
            ? {
                ...currentFolder,
                notes: currentFolderNotes,
              }
            : DEFAULT_ALL_FOLDER;

          DEFAULT_ALL_FOLDER.notes = allPublicNotes;

          if (!currentFolder?.public && folderSlug !== "all") {
            // if not public folder, show no notes message
            setActiveNotes([]);
            setAllPublicNotes(allPublicNotes);
            setFolders([DEFAULT_ALL_FOLDER, ...publicFolders]);
            setNotes(notes);
            setIsLoading(false);

            // setActiveFolder(null)
            // setActiveNotes([])
            return;
          }

          // Set local state
          setIsLoading(false);
          setFolders([DEFAULT_ALL_FOLDER, ...publicFolders]);
          setAllPublicNotes(allPublicNotes);
          setNotes(notes);
          setActiveFolder(uCurrentFolder);
          setActiveNotes(currentFolderNotes);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [username, folderSlug]);
  
  useEffect(() => {
    const checkFollowStatus = async () => {
      if (userRef.current && username) {
        const followDoc = await firestore
          .collection('followers')
          .doc(`${userRef.current.id}_${username}`)
          .get();
        setIsFollowing(followDoc.exists);
      }
    };

    checkFollowStatus();
  }, [username, userRef.current]);

  useEffect(() => {
    // Generate avatar config based on username
    setAvatarConfig(genConfig(username));
  }, [username]);

  const handleNoteClick = (noteId) => {
    // if (!userRef.current) {
    //   setGlobalState({ showSignupModal: true });
    //   return;
    // }

    const note = notes.find((note) => note.id === noteId);

    setCurrentNote(note);

    if (isMobile) {
      setShowNoteDetail(true);
      setMobileView("editor");
    }
  };

  const handleTabChange = (event, newValue) => {
    const selectedFolder = folders[newValue];
    navigate(`/${username}/${selectedFolder.slug}`);
  };

  const handleMakeYourOwnClick = () => {
    if (!userRef.current) {
      setGlobalState({ showSignupModal: true });
      return;
    }
    // actual logic
    navigate(`/`);
  };

  const handleSaveMapClick = (type) => {
    if (!userRef.current) {
      setGlobalState({ showSignupModal: true });
      return;
    }
    // cannot add on 'ALL' tab
    if (activeFolder.slug === "all") {
      toast(`Not allowed on ALL tabs - select a folder`, {
        position: "top-right",
      });
      return;
    }

    // actual logic
    setDialogAction(type === "save" ? "save" : "edit");
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogConfirm = () => {
    setOpenDialog(false);

    saveMapToUserProfile(userRef.current, activeFolder, currentNote);
  };

  const saveMapToUserProfile = async (user, activeFolder, currentNote) => {
    if (!user || !activeFolder) {
      console.error("User or active folder is missing");
      return;
    }

    try {
      // Format the folder notes to match the structure used elsewhere
      const formattedFolder = {
        ...activeFolder,
        createdBy: username,
        public: false,
        updatedAt: Date.now(),
        notes: activeFolder.notes.reduce((acc, note) => {
          acc[note.id] = { id: note.id };
          return acc;
        }, {}),
      };

      // 1. Save the folder
      const folderRef = database.ref(
        `notes/${user.id}/folders/${activeFolder.id}`
      );
      await folderRef.set(formattedFolder);

      // 2. Save the notes
      const notesRef = database.ref(`notes/${user.id}/notes`);
      for (const note of activeFolder.notes) {
        await notesRef.child(note.id).set(note);
      }

      toast("Map saved to your profile successfully", {
        position: "top-right",
      });

      setTimeout(() => {
        window.location.href = "/home";
      }, 1000);
    } catch (error) {
      console.error("Error saving map to profile:", error);
      toast("Error saving map to profile", {
        position: "top-right",
      });
    }
  };

  const handleViewToggle = () => {
    setView(view === "map" ? "notes" : "map");
  };


  const handleFollowClick = async () => {
    if (!userRef.current) {
      setGlobalState({ showSignupModal: true });
      return;
    }

    const followerId = userRef.current.id;
    const followeeId = username;
    const followDocId = `${followerId}_${followeeId}`;

    try {
      if (isFollowing) {
        await firestore.collection('followers').doc(followDocId).delete();
        setIsFollowing(false);
        toast("Unfollowed successfully", { position: "top-right" });
      } else {
        await firestore.collection('followers').doc(followDocId).set({
          follower: followerId,
          followee: followeeId,
          createdAt: new Date()
        });
        setIsFollowing(true);
        toast("Followed successfully", { position: "top-right" });
      }
    } catch (error) {
      console.error("Error updating follow status:", error);
      toast("Error updating follow status", { position: "top-right" });
    }
  };

  const renderMobileView = () => (
    <Box sx={styles.mobileContainer}>
      <Box
        sx={styles.mapContainer}
        style={{ display: view === "map" ? "block" : "none" }}
      >
        <VisitorHeader
          noLogin
          showToggle
          mobileView={mobileView}
          setMobileView={setMobileView}
        />

        {mobileView === "map" ? (
          <RightSidebar
            user={username}
            darkTheme={true}
            mobileView={mobileView}
            setMobileView={setMobileView}
            handleMakeYourOwnClick={handleMakeYourOwnClick}
            handleSaveMapClick={handleSaveMapClick}
            nodePositions={activeFolder?.nodePositions || {}}
            activeNoteId={currentNote?.id}
            setExpanded={null}
            activeFolder={activeFolder}
            visitorView={true}
            setExpandedRightSidebar={null}
            expandedRightSidebar={"fullWidth"}
            files={activeNotes}
            currentFile={currentNote}
            setActiveRightSidebar={setActiveRightSidebar}
            activeRightSidebar={activeRightSidebar}
            onNodeClick={(noteId) => handleNoteClick(noteId)}
            isMobile={true}
          />
        ) : mobileView === "notes" ? (
          renderProfileAndNotes()
        ) : (
          renderEditor()
        )}
      </Box>

      <Box sx={styles.actionContainer}>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{
            mb: "1rem",
            "& .MuiSpeedDial-actions": {
              gap: "16px",
            },
            "& .custom-tooltip-label": {
              width: "300px",
              height: "40px",
              fontWeight: 900,
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              color: COLORS.niceWhite,
              display: "flex",
              alignItems: "center",
              paddingLeft: "16px",
              borderRadius: "20px 0 0 20px",
              fontSize: "16px",
            },
            "& .MuiSpeedDialAction-fab": {
              width: "40px",
              height: "40px",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              color: COLORS.niceWhite,
            },
          }}
          icon={<MenuIcon />}
        >
          <SpeedDialAction
            key="edit-map"
            icon={<EditIcon />}
            tooltipTitle="Save & Edit mind map"
            tooltipOpen
            classes={{ staticTooltipLabel: "custom-tooltip-label" }}
            onClick={() => handleSaveMapClick("edit")}
          />
          <SpeedDialAction
            key="make-your-own"
            icon={<AddIcon />}
            tooltipTitle="Create a map"
            tooltipOpen
            classes={{ staticTooltipLabel: "custom-tooltip-label" }}
            onClick={handleMakeYourOwnClick}
          />
        </SpeedDial>

        {mobileView === "map" && (
          <Box onClick={() => setMobileView("notes")} sx={styles.avatarWrapper}>
            {avatarConfig && (
              <Avatar
                style={{ width: "56px", height: "56px" }}
                {...avatarConfig}
              />
            )}
          </Box>
        )}

        {showNoteDetail && (
          <Box onClick={() => setShowNoteDetail(false)} sx={styles.backButton}>
            <ArrowLeftIcon sx={{ color: COLORS.niceWhite }} />
          </Box>
        )}
      </Box>
    </Box>
  );

  const renderEditor = () => {
    return (
      <Box key={currentNote.id} sx={styles.editorWrapper}>
        <BackButton
          onClick={
            isMobile
              ? () => {
                  setShowNoteDetail(false);
                  setMobileView("notes");
                }
              : () => setCurrentNote(null)
          }
        />
        <Box sx={styles.divider} />
        <Box sx={styles.editorScrollContainer}>
          <SimpleEditor
            content={currentNote.content}
            fileColor={currentNote?.color || activeFolder?.color}
          />
        </Box>
      </Box>
    );
  };

  const renderConfirmDialog = () => (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {dialogAction === "save" ? "Save Mind Map" : "Edit Mind Map"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to {dialogAction} this mind map?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDialogConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  // WHEN URL HAS ACTIVE FOLDER IN URL - SHOW PROFILE AND NOTES
  const renderProfileAndNotes = () => {
    return (
      <PageContainer>
        <ContentContainer>
          <ProfileSection isMobile={isMobile}>
            <AvatarWrapper>
              {avatarConfig && (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  {...avatarConfig}
                />
              )}
            </AvatarWrapper>
            <UserInfo>
              <Username variant="h5">{username}</Username>
              <FollowTypography onClick={handleFollowClick}>
                {isFollowing ? "Unfollow" : "Follow"}
              </FollowTypography>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <FolderCount>
                  {folders.length === 0 ? 0 : folders.length - 1} Mind Map
                  {folders.length !== 1 ? "s" : ""}
                </FolderCount>
                <FolderCount
                  onClick={null}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "space-between",
                  }}
                >
                  <ShareButton onClick={() => setShareModalOpen(true)}>
                    <ShareIcon />
                    Share
                  </ShareButton>
                </FolderCount>
              </Box>
            </UserInfo>
          </ProfileSection>
          <StyledTabs
            value={folders.findIndex((folder) => folder.slug === folderSlug)}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {folders &&
              folders?.map((folder) => (
                <StyledTab
                  key={folder.id}
                  label={
                    <StyledBadge
                      badgeContent={Object.keys(folder.notes || {}).length}
                      sx={{ marginRight: "1rem", textAlign: "center" }}
                    >
                      {folder.name}
                    </StyledBadge>
                  }
                />
              ))}
          </StyledTabs>
          <NoteGrid
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {/* LOADING  */}
            {isLoading && (
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {/* LOADING ENDED NO ACTIVENOTES  */}
            {!isLoading && activeNotes.length === 0 && (
              <Typography sx={{ color: COLORS.niceWhite }}>
                {`No public ${folderSlug || ""} notes :(`}
              </Typography>
            )}
            {/* ACTUAL CONTENT */}
            {!isLoading &&
              activeNotes.map((note) => (
                <NoteCard
                  noteColor={note.color}
                  key={note.id}
                  onClick={() => handleNoteClick(note.id)}
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <NoteTitle variant="h6">{note.title}</NoteTitle>
                  <NoteContent>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        lineHeight: "1.2rem",
                        paddingBottom: "1rem",
                        color: COLORS.niceWhite,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "2",
                        "-webkit-box-orient": "vertical",
                      }}
                    >
                      {" "}
                      {note.content.replace(/<[^>]+>/g, "").slice(0, 75)}
                    </Typography>
                  </NoteContent>
                  <NoteFooter>
                    <SmallText>
                      <VisibilityIcon
                        style={{ fontSize: "0.9rem", marginRight: "4px" }}
                      />
                      {Math.floor(Math.random() * 100) + 1}
                    </SmallText>
                    <SmallText>
                      {new Date(note.updatedAt).toLocaleDateString()}
                    </SmallText>
                  </NoteFooter>
                </NoteCard>
              ))}
          </NoteGrid>
        </ContentContainer>
      </PageContainer>
    );
  };

  return (
    <Box>
      {renderConfirmDialog()}
      <ShareModal
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        shareUrl={`https://noted.fm/${username}/${folderSlug}`}
        title={`Check out my public mind map about ${activeFolder?.name}`}
      />
      {globalState?.showSignupModal && (
        <SignupModal setUser={setUser} user={user} />
      )}
      {isMobile ? (
        renderMobileView()
      ) : (
        <Box sx={styles.horizontalMain}>
          <Box sx={styles.halfLeft}>
            <VisitorHeader noLogin />
            {currentNote ? renderEditor() : renderProfileAndNotes()}
          </Box>
          <Box sx={styles.halfRight}>
            <RightSidebar
              user={username}
              darkTheme={true}
              handleMakeYourOwnClick={handleMakeYourOwnClick}
              handleSaveMapClick={handleSaveMapClick}
              nodePositions={activeFolder?.nodePositions || {}}
              activeNoteId={currentNote?.id}
              setExpanded={null}
              activeFolder={activeFolder}
              visitorView={true}
              setExpandedRightSidebar={null}
              expandedRightSidebar={"fullWidth"}
              files={activeNotes}
              currentFile={currentNote}
              setActiveRightSidebar={setActiveRightSidebar}
              activeRightSidebar={activeRightSidebar}
              onNodeClick={(noteId) => handleNoteClick(noteId)}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const styles = {
  horizontalMain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  backButton: {
    position: "absolute",
    bottom: 16,
    left: 16,
    width: 56,
    height: 56,
    borderRadius: "50%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: COLORS.niceWhite,
    cursor: "pointer",
  },
  editorScrollContainer: {
    flex: 1,
    overflowY: "auto",
    paddingBottom: "20px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: alpha(COLORS.niceDeepBlack, 0.5),
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: COLORS.niceDeepBlack,
    },
  },
  verticalMain: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: "100vh",
  },
  fullWidth: {
    width: "100%",
    backgroundColor: COLORS.niceDeepBlack,
  },
  mobileContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: COLORS.niceDeepBlack,
    position: "relative",
  },
  actionContainer: {
    position: "absolute",
    bottom: 16,
    right: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  speedDial: {
    marginBottom: "16px", // Add space between SpeedDial and Avatar
    "& .MuiSpeedDial-fab": {
      backgroundColor: COLORS.nicePurple,
      "&:hover": {
        backgroundColor: COLORS.nicePink,
      },
    },
  },
  speedDialAction: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: COLORS.niceWhite,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
  },
  avatarWrapper: {
    width: 56,
    height: 56,
    borderRadius: "50%",
    overflow: "hidden",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
  },
  compactProfile: {
    position: "absolute",
    bottom: 80,
    left: 16,
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: 20,
    padding: "8px 16px",
    zIndex: 1000,
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: 12,
  },
  profileInfo: {
    display: "flex",
    flexDirection: "column",
  },
  username: {
    color: COLORS.niceWhite,
    fontWeight: "bold",
  },
  folderCount: {
    color: COLORS.niceWhite,
    opacity: 0.7,
  },

  mapContainer: {
    flex: 1,
    overflow: "hidden",
  },
  notesContainer: {
    flex: 1,
    overflow: "auto",
    backgroundColor: COLORS.niceDeepBlack,
  },

  mobileUserInfo: {
    backgroundColor: COLORS.niceDeepBlack,
    borderTop: `1px solid ${COLORS.niceDarkGray}`,
  },
  mobileUserInfoHeader: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
  },
  mobileUserInfoContent: {
    padding: "0 10px 10px",
  },

  fab: {
    position: "fixed",
    bottom: "80px",
    right: "20px",
    backgroundColor: COLORS.nicePurple,
    "&:hover": {
      backgroundColor: COLORS.nicePink,
    },
  },
  mobileRightSidebar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "60px",
    backgroundColor: COLORS.niceDeepBlack,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    borderTop: `1px solid ${COLORS.niceDarkGray}`,
  },
  halfLeft: {
    flex: 1,
    height: "100vh",
    overflow: "auto",
    backgroundColor: COLORS.niceDeepBlack,
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: alpha(COLORS.niceDeepBlack, 0.5),
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: COLORS.niceDeepBlack,
    },
  },
  halfRight: {
    width: "50%",
    backgroundColor: COLORS.niceDeepBlack,
    height: "100vh",
  },
  divider: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: COLORS.niceDarkGray,
    height: "1px",
    marginTop: "40px",
    marginBottom: "40px",
  },

  editorWrapper: {
    backgroundColor: COLORS.niceDeepBlack,
    height: "100vh",
    overflow: "hidden",
    paddingLeft: "20px",
    paddingRight: "20px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  editorContainer: (fileColor) => ({
    color: COLORS.niceWhite,
    "& strong": {
      color: fileColor || COLORS.nicePink,
      fontWeight: 700,
    },
    fontSize: "16px",
    "& .ProseMirror": {
      "& > *": {
        marginBottom: "1em",
      },
      "& h1": {
        fontSize: "2em",
        fontWeight: "bold",
      },
      "& h2": {
        fontSize: "1.5em",
        fontWeight: "bold",
      },
      "& a": {
        color: COLORS.nicePurple,
        textDecoration: "underline",
      },
      "& ul, & ol": {
        paddingLeft: "1.5em",
      },
      "& code": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        padding: "2px 4px",
        borderRadius: "4px",
      },
      "& iframe.youtube-video": {
        maxWidth: "100%",
        aspectRatio: "16 / 9",
        height: "auto",
      },
    },
  }),
  noteTitle: {
    color: COLORS.niceWhite,
    marginBottom: "20px",
  },
};

export default KnowledgeMapPage;
