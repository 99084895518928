import React from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { COLORS } from "../utils/colors";
import { motion } from "framer-motion";
import { keyframes } from "styled-components";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: ${COLORS.niceDeepBlack};
    color: ${COLORS.niceWhite};
    border: 2px solid ${COLORS.nicePurple};
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(${COLORS.nicePurple}, 0.3);
    animation: ${fadeIn} 0.3s ease-out;
  }
`;

const DialogButton = styled(motion.button)`
  background-color: transparent;
  color: ${COLORS.niceWhite};
  border: 1px solid ${COLORS.nicePurple};
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  border-radius: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    background-color: ${COLORS.nicePurple};
    box-shadow: 0 0 10px ${COLORS.nicePurple};
  }
`;

const GradientText = styled.span`
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
    font-size: 1.5rem;
  font-weight: bold;

`;


const IconWrapper = styled.span`
  margin-right: 8px;
`;

const LogoutModal = ({ user, open, onClose, onConfirm }) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        timeout: 300,
      }}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
    >
           <DialogTitle id="logout-dialog-title">
        <GradientText>
          <IconWrapper>🚪</IconWrapper>
          Confirm Logout
        </GradientText>
      {user?.email && <Typography style={{ color: COLORS.niceWhite, fontSize: '1.1rem' }}>
       {user?.email}
      </Typography>}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="logout-dialog-description" style={{ color: COLORS.niceWhite, fontSize: '1.1rem' }}>
          Are you sure you want to log out? We'll miss you!
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '16px' }}>
        <DialogButton
          onClick={onClose}
          whileHover={{ scale: 1.05, boxShadow: `0 0 15px ${COLORS.nicePurple}` }}
          whileTap={{ scale: 0.95 }}
        >
          <IconWrapper>🏠</IconWrapper>
          Stay
        </DialogButton>
        <DialogButton
          onClick={onConfirm}
          whileHover={{ scale: 1.05, boxShadow: `0 0 15px ${COLORS.nicePink}` }}
          whileTap={{ scale: 0.95 }}
          autoFocus
          style={{ backgroundColor: COLORS.nicePink, borderColor: COLORS.nicePink }}
        >
          <IconWrapper>👋</IconWrapper>
          Logout
        </DialogButton>
      </DialogActions>
    </StyledDialog>

  );
};

export default LogoutModal;
