import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';

import HomePage from "./pages/HomePage";
import "./App.css";
import { GlobalStateProvider } from "./context/globalStateContext";
import { UserContextProvider } from "./context/userContext";
import "./global.css";
import FinishSignUp from "./pages/FinishUpPage";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Success from "./pages/Success";
import Profile from "./pages/Profile";
import Pricing from "./pages/Pricing";
import LandingPage from "./pages/LandingPage";
import SummaryPage from "./pages/SummaryPage";
import { useEffect } from "react";
import KnowledgeMapPage from "./pages/KnowledgeMapPage";
import DiscoverPage from "./pages/DiscoverPage";

const theme = createTheme();

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-BV10N9TM29');

    // Send initial pageview
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    // Set up listener for route changes
    const handleRouteChange = () => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);



  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalStateProvider>
          <UserContextProvider>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/finishSignUp" element={<FinishSignUp />} />
              <Route path="/profile/:username" element={<Profile />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/success" element={<Success />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/discover" element={<DiscoverPage />} />
              {/* My mind map routes */}
              <Route path="/:username/:folderSlug" element={<KnowledgeMapPage />} exact />
              {/* YouTube routes */}
              <Route path="youtube.com/*" element={<SummaryPage />} />
              <Route path="www.youtube.com/*" element={<SummaryPage />} />
              <Route path="https://youtube.com/*" element={<SummaryPage />} />
              <Route
                path="https://www.youtube.com/*"
                element={<SummaryPage />}
              />
              <Route path="*" element={<SummaryPage />} />
              {/* PRESET WITH A FILTER - pSEO (nomadList appproach)  */}
              {/* <Route path="/:filter" element={<HomePage />} /> */}
            </Routes>
          </UserContextProvider>
        </GlobalStateProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
