import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Button, Fade, IconButton, Typography } from "@mui/material";
import { COLORS } from "../utils/colors";
import CustomTooltip from "./CustomTooltip";
import {
  ZoomIn,
  ZoomOut,
  CenterFocusStrong,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Shuffle,
  Chat,
  Sort,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import { useStore,getBezierPath } from "reactflow";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle,
  Panel,
} from "reactflow";
import "reactflow/dist/style.css";
import { extractWikiLinks, formatTitle } from "../utils/helperMethods";
import dagre from "dagre";
import ChatView from "./ChatView";
import { keyframes } from "@emotion/react";
import { database } from "../firebaseConfig";
import VisitorHeader from "./VisitorHeader";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  data
}) => {
  const edgePath = `M ${sourceX} ${sourceY} L ${targetX} ${targetY}`;
  const { isHovered, isConnected } = data || {};

  return (
    <>
      <path
        id={id}
        style={{
          ...style,
          strokeWidth: isHovered || isConnected ? 2 : 1,
          stroke: isHovered || isConnected ? COLORS.niceSuperLightGrey : '#555',
          }}
        className="react-flow__edge-path"
        d={edgePath}
      />
      <path
        d={edgePath}
        fill="none"
        strokeOpacity="0"
        // strokeWidth: isHovered || isConnected ? 2 : 1,
        className="react-flow__edge-interaction"
      />
    </>
  );
};


const CustomNode = ({ data, isActive }) => {
  const zoom = useStore((state) => state.transform[2]);
  const isZoomedOut = zoom < 0.7;
  const { isHovered, isConnected } = data;

  const nodeSize = useMemo(() => {
    if (zoom < 0.5) return 30;
    if (zoom < 0.7) return 25;
    if (zoom < 1) return 25;
    return 10;
  }, [zoom]);

  const textStyle = useMemo(
    () => ({
      marginTop: "4px",
      fontSize: data.fontSize || (
        zoom < 0.5 ? "36px" : zoom < 0.7 ? "24px" : zoom < 1 ? "22px" : "16px"
      ),
      ...(isHovered || isConnected && {  fontWeight:'bold', }), // Changed from "58px" to "32px"
      position: "absolute",
      color: isHovered
        ? "#fff"
        : isConnected
        ? "#fff"
        : COLORS.niceSuperLightGrey,
      fontWeight: isHovered || isConnected ? "bold" : "normal",
      maxWidth: isHovered || isConnected ? "300px" : "240px", // Reduced from "500px" to "300px"
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "center",
      transition: "all 0.3s ease",
      pointerEvents: "none",
    }),
    [zoom, isHovered, isConnected]
  );

  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={
        isActive
          ? {
              scale: [1, 1.2, 1],
              transition: { repeat: Infinity, duration: 2 },
            }
          : {}
      }
      whileHover={isZoomedOut ? { scale: 1.5, zIndex: 1000 } : {}}
      // onMouseEnter={() => setIsHovered(true)}
      // onMouseLeave={() => setIsHovered(false)}
    >
      <Box>
        <div
          style={{
            width: nodeSize,
            height: nodeSize,
            borderRadius: "50%",
            backgroundColor: data.color || "#888",
            cursor: isHovered ? "none" : "pointer",
            boxShadow: isActive
              ? `0 0 10px 5px ${data.color || "#888"}`
              : "none",
            transition: "all 0.3s ease",
            position: "relative", // Add this
          }}
        >
          <Handle
            type="source"
            position="bottom"
            style={{ visibility: "hidden", bottom: 0, left: '50%', transform: 'translateX(-50%)' }}
          />
          <Handle
            type="target"
            position="top"
            style={{ visibility: "hidden", top: 0, left: '50%', transform: 'translateX(-50%)' }}
          />
        </div>
        <Typography style={textStyle}>{data.label}</Typography>
      </Box>
    </motion.div>
  );
};
const RightSidebar = ({
  user,
  setExpanded,
  nodePositions,
  visitorView,
  handleMakeYourOwnClick,
  handleSaveMapClick,
  darkTheme,
  activeFolder,
  setExpandedRightSidebar,
  expandedRightSidebar,
  files,
  currentFile,
  notesVersion,
  onNodeClick,
  setActiveRightSidebar,
  activeRightSidebar,
  chatViewRef,
  activeNoteId,
  isMobile,
  mobileView,
  setMobileView,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [hoveredEdge, setHoveredEdge] = useState(null);
  const [hoveredNode, setHoveredNode] = useState(null);
  const [connectedNodes, setConnectedNodes] = useState(new Set());
  const [showPresetView, setShowPresetView] = useState(true);

  const getLayoutedElements = (nodes, edges) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({
      rankdir: "TB",
      ranksep: 30, // Reduce rank separation
      nodesep: 15, // Reduce node separation
      edgesep: 5, // Reduce edge separation
    });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: 120, height: 40 }); // Reduce node size
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const layoutedNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      return {
        ...node,
        position: {
          x: nodeWithPosition.x,
          y: nodeWithPosition.y,
        },
      };
    });

    return { nodes: layoutedNodes, edges };
  };

  const fitView = useCallback(() => {
    if (reactFlowInstance) {
      reactFlowInstance.fitView({ padding: 0.2, duration: 800 });
    }
  }, [reactFlowInstance]);

  useEffect(() => {
    if (files && files.length > 0) {
      let filteredFiles = files;

      // Filter files based on activeFolder
      if (
        activeFolder &&
        activeFolder.notes &&
        Object.keys(activeFolder.notes).length > 0
      ) {
        filteredFiles = Object.values(activeFolder.notes);
      }

      const newNodes = filteredFiles.map((file, index) => {
        return {
          id: file.id,
          data: { color: file.color, label: formatTitle(file.title) },
          position: (activeFolder?.nodePositions &&
            activeFolder?.nodePositions[file.id]) || {
            x: 0,
            y: index * 100,
          },
          type: "custom",
        };
      });

      const newEdges = [];
      filteredFiles.forEach((file) => {
        if(!file?.slug) return
        const links = extractWikiLinks(file.content);
        links.forEach((link) => {
          const targetFile = filteredFiles.find((f) => f.slug === link);
          if (targetFile) {
            newEdges.push({
              id: `e${file.id}-${targetFile.id}`,
              source: file.id,
              target: targetFile.id,
            });
          }
        });
      });

      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(newNodes, newEdges);
      // setNodes(visitorView ? newNodes : activeFolder ? newNodes : layoutedNodes);
      // setEdges(visitorView ? newEdges : activeFolder ? newEdges : layoutedEdges);

      setNodes(
        visitorView
          ? activeFolder && activeFolder?.name === "All Notes"
            ? layoutedNodes
            : newNodes
          : activeFolder
          ? newNodes
          : layoutedNodes
      );
      setEdges(
        visitorView
          ? activeFolder && activeFolder?.name === "All Notes"
            ? layoutedEdges
            : newEdges
          : activeFolder
          ? newEdges
          : layoutedEdges
      );

      // Use setTimeout to ensure the nodes are rendered before fitting the view
      setTimeout(() => {
        fitView();
      }, 50);
    }
  }, [files, notesVersion, activeFolder]);

  useEffect(() => {
    try {
      // Your existing code for creating nodes and edges
    } catch (error) {
      console.error("Error in files useEffect:", error);
    }
  }, [files]);

  useEffect(() => {
    try {
    } catch (error) {
      console.error("Error in nodes useEffect:", error);
    }
  }, [nodes]);

  useEffect(() => {
    try {
    } catch (error) {
      console.error("Error in edges useEffect:", error);
    }
  }, [edges]);

  useEffect(() => {
    if (activeNoteId && reactFlowInstance) {
      const activeNode = nodes.find((node) => node.id === activeNoteId);
      if (activeNode) {
        // Highlight connected edges and nodes
        const connectedEdges = edges.filter(
          (edge) => edge.source === activeNoteId || edge.target === activeNoteId
        );
        const connectedNodeIds = new Set(
          connectedEdges.flatMap((edge) => [edge.source, edge.target])
        );

        const updatedNodes = nodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            isActive: node.id === activeNoteId,
            isConnected: connectedNodeIds.has(node.id),
          },
        }));

        const updatedEdges = edges.map((edge) => ({
          ...edge,
          animated: connectedEdges.some((e) => e.id === edge.id),
          style: {
            ...(edge.style || {}),
            stroke: connectedEdges.some((e) => e.id === edge.id)
              ? COLORS.nicePurple
              : (edge.style && edge.style.stroke) || "#555",
            strokeWidth: connectedEdges.some((e) => e.id === edge.id)
              ? 2
              : (edge.style && edge.style.strokeWidth) || 0.5,
          },
        }));

        // Update nodes and edges in a single batch
        reactFlowInstance.setNodes(updatedNodes);
        reactFlowInstance.setEdges(updatedEdges);
        try {
          // Zoom to the active node
          reactFlowInstance.fitView({
            padding: 0.3, // Slightly more padding for a bit more context
            duration: 1500, // Slower animation for more dramatic effect
            nodes: [activeNode],
            minZoom: 0.4, // Allow slightly more zoom out
            maxZoom: 2.5, // Allow slightly more zoom in
          });
        } catch (error) {
          console.error("Error in zooming to active node:", error);
        }

        // Reset highlights after a delay
        const timeoutId = setTimeout(() => {
          const resetNodes = updatedNodes.map((node) => ({
            ...node,
            data: { ...node.data, isConnected: false },
          }));
          const resetEdges = updatedEdges.map((edge) => ({
            ...edge,
            animated: false,
            style: {
              ...(edge.style || {}),
              stroke: "#555",
              strokeWidth: 0.5,
            },
          }));

          reactFlowInstance.setNodes(resetNodes);
          reactFlowInstance.setEdges(resetEdges);
        }, 3000);

        // Cleanup function
        return () => clearTimeout(timeoutId);
      }
    }
  }, [activeNoteId, reactFlowInstance]);

  // const onNodeMouseEnter = useCallback(
  //   (event, node) => {
  //     setHoveredNode(node.id);
  //     const connected = new Set();
  //     edges.forEach((edge) => {
  //       if (edge.source === node.id) connected.add(edge.target);
  //       if (edge.target === node.id) connected.add(edge.source);
  //     });
  //     setConnectedNodes(connected);
  //   },
  //   [edges]
  // );
  const onNodeMouseEnter = useCallback(
    (event, node) => {
      setHoveredNode(node.id);
      const directConnections = new Set();
      edges.forEach((edge) => {
        if (edge.source === node.id) directConnections.add(edge.target);
        if (edge.target === node.id) directConnections.add(edge.source);
      });
      setConnectedNodes(directConnections);
    },
    [edges]
  );

  const onNodeMouseLeave = useCallback(() => {
    setHoveredNode(null);
    setConnectedNodes(new Set());
  }, []);

  const onEdgeMouseEnter = useCallback((event, edge) => {
    setHoveredEdge(edge.id);
  }, []);

  const onEdgeMouseLeave = useCallback(() => {
    setHoveredEdge(null);
  }, []);

  const getEdgeStyle = useCallback(
    (edge) => {
      if (
        hoveredNode &&
        (edge.source === hoveredNode || edge.target === hoveredNode)
      ) {
        return { stroke: COLORS.niceSuperLightGrey, strokeWidth: 2 };
      }
      return { stroke: "#555", strokeWidth: 0.5 };
    },
    [hoveredNode]
  );

  const saveNodePositions = useCallback(
    (updatedNodes) => {
      if (!visitorView && user && activeFolder && activeFolder.id) {
        const nodePositions = updatedNodes.reduce((acc, node) => {
          if (node && node.id && node.position) {
            acc[node.id] = node.position;
          }
          return acc;
        }, {});

        database
          .ref(`notes/${user?.id}/folders/${activeFolder.id}/nodePositions`)
          .set(nodePositions)
          .then(() => null)
          .catch((error) =>
            console.error("Error saving node positions:", error)
          );
      } else {
      }
    },
    [visitorView, user, activeFolder]
  );

  const customOnNodesChange = useCallback(
    (changes) => {
      const positionChanges = changes.filter((change) => {
        if (change.type !== "position" || !change.position) return false;
        const node = nodes.find((n) => n.id === change.id);
        return (
          node &&
          (Math.abs(change.position.x - node.position.x) > 1 ||
            Math.abs(change.position.y - node.position.y) > 1)
        );
      });

      if (positionChanges.length > 0) {
        onNodesChange(changes);

        // Get the updated nodes after the change
        const updatedNodes = nodes.map((node) => {
          const change = changes.find((c) => c.id === node.id);
          return change && change.type === "position"
            ? { ...node, position: change.position }
            : node;
        });

        // Save the updated node positions
        saveNodePositions(updatedNodes);
      }
    },
    [onNodesChange, nodes, saveNodePositions]
  );

  const onNodeClickHandler = useCallback(
    (event, node) => {
      onNodeClick(node.id);
    },
    [onNodeClick]
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onInit = useCallback((instance) => {
    // instance.setViewport({ x: 0, y: 0, zoom: 1.0 });
    if (isMobile) {
      // Move the viewport more to the right and zoom out slightly
      instance.setViewport({ x: 8000, y: 0, zoom: 0.7 });

      // Use setTimeout to ensure the nodes are rendered before fitting the view
      setTimeout(() => {
        instance.fitView({
          padding: 0.2, // Slightly more padding for a bit more context
          duration: 1000, // Slower animation for more dramatic effect
          maxZoom: 0.5, // Limit the maximum zoom level
        });
        setReactFlowInstance(instance);
      }, 100);
    } else {
      instance.setViewport({ x: 0, y: 0, zoom: 1.0 });
      setReactFlowInstance(instance);
    }
  }, []);

  const handleZoomIn = () => {
    reactFlowInstance.zoomIn();
  };

  const handleZoomOut = () => {
    reactFlowInstance.zoomOut();
  };

  const handleReorganize = useCallback(() => {
    setNodes((nds) =>
      nds.map((node) => ({
        ...node,
        position: {
          x: Math.random() * 800,
          y: Math.random() * 600,
        },
      }))
    );
  }, [setNodes]);

  const handleFitView = () => {
    if (isMobile) {
      // For mobile, we need to adjust the padding and possibly the zoom level
      reactFlowInstance.fitView({
        padding: 0.2,
        includeHiddenNodes: false,
        maxZoom: 1.5, // Limit the maximum zoom level for better visibility on mobile
        duration: 800, // Add a smooth transition
      });
    } else {
      // For desktop, we can use the original settings
      reactFlowInstance.fitView({
        padding: 0.1,
        includeHiddenNodes: false,
        duration: 800,
      });
    }
    // reactFlowInstance.fitView({ padding: 0.0, includeHiddenNodes: false });
  };

  const handleTopSectionClick = () => {
    // if true alerady, set to 'fullWidth'
    if (expandedRightSidebar && expandedRightSidebar !== "fullWidth") {
      setExpandedRightSidebar("fullWidth");
    }
    // if false, set to true
    else if (!expandedRightSidebar) {
      setExpandedRightSidebar(true);
    }
    // if fullWidth, set to false
    else {
      setExpandedRightSidebar(false);
    }
  };

  return (
    <Box sx={styles.sidebar(expandedRightSidebar, darkTheme)}>
      {visitorView ? (
        !isMobile && <Box sx={styles.visitorHeader(darkTheme)}>
          <Fade in={true} timeout={1000}>
            <Button
              onClick={handleMakeYourOwnClick}
              variant="contained"
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                backgroundColor: "dodgerblue",
                color: COLORS.niceWhite,

                "&:hover": {
                  backgroundColor: COLORS.niceWhite,
                  color: COLORS.niceDeepBlack,
                },
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Create mind map</Typography>
            </Button>
          </Fade>
          {/* <Fade in={true} timeout={1000}>
            <Button
              onClick={() => handleSaveMapClick("save")}
              variant="contained"
              sx={{
                position: "absolute",
                right: "45%",
                top: 10,
                backgroundColor: COLORS.niceSyncingGreen,
                color: COLORS.niceWhite,

                "&:hover": {
                  backgroundColor: COLORS.niceWhite,
                  color: COLORS.niceDeepBlack,
                },
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>SAVE</Typography>
            </Button>
          </Fade> */}
          <Fade in={true} timeout={1000}>
            <Button
              onClick={() => handleSaveMapClick("edit")}
              variant="contained"
              sx={{
                position: "absolute",
                right: "39%",
                top: 10,
                backgroundColor: COLORS.niceWhite,
                color: COLORS.niceDeepBlack,

                "&:hover": {
                  backgroundColor: COLORS.niceDeepBlack,
                  color: COLORS.niceWhite,
                },
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>SAVE MIND MAP</Typography>
            </Button>
          </Fade>
        </Box>
      ) : (
        <Box sx={styles.header(darkTheme)}>
          <CustomTooltip
            title={
              expandedRightSidebar && expandedRightSidebar === "fullWidth"
                ? "Minimize"
                : "Expand"
            }
          >
            <Box sx={styles.topSection} onClick={handleTopSectionClick}>
              {expandedRightSidebar && expandedRightSidebar === "fullWidth" ? (
                <KeyboardDoubleArrowLeft sx={styles.topSectionIcon} />
              ) : (
                <KeyboardDoubleArrowRight sx={styles.topSectionIcon} />
              )}
            </Box>
          </CustomTooltip>
        </Box>
      )}
      {/* TOOL OPTIONS  */}
      {!visitorView && (
        <Box sx={styles.controls}>
          {expandedRightSidebar || expandedRightSidebar === "fullWidth" ? (
            // Expanded view
            <Box
              sx={{
                width: "95%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => setActiveRightSidebar("chat")}
                variant="text"
                sx={{
                  p: 0,
                  m: 0,
                  color:
                    activeRightSidebar === "chat"
                      ? "white"
                      : COLORS.niceSuperLightGrey,
                  fontWeight: "bold",
                  textDecoration:
                    activeRightSidebar === "chat" ? "underline" : "none",
                  textUnderlineOffset: "5px",
                }}
              >
                Chat
              </Button>
              <Button
                onClick={() => setActiveRightSidebar("mindMap")}
                variant="text"
                sx={{
                  color:
                    activeRightSidebar === "mindMap"
                      ? "white"
                      : COLORS.niceSuperLightGrey,
                  fontWeight: "bold",
                  textDecoration:
                    activeRightSidebar === "mindMap" ? "underline" : "none",
                  textUnderlineOffset: "5px",
                }}
              >
                Map
              </Button>
            </Box>
          ) : (
            // Collapsed view
            <Box sx={styles.collapsedControls}></Box>
          )}
        </Box>
      )}

      {/* MAIN CONTENT  */}
      <Box sx={{ height: "100%", width: "100%" }}>
        {expandedRightSidebar && activeRightSidebar === "mindMap" && (
          <ReactFlow
            // nodes={nodes} // TODO - fix bug this one works for nav
            nodes={nodes.map((node) => ({
              // TODO - fix this works for highlighting node / effects (combine them)
              ...node,
              data: {
                ...node.data,
                isHovered: node.id === hoveredNode,
                isConnected: connectedNodes.has(node.id),
              },
            }))}
            nodeTypes={{ custom: CustomNode }}
            edges={edges.map((edge) => ({
              ...edge,
              type: 'custom',
              data: {
                isHovered: hoveredNode === edge.source || hoveredNode === edge.target,
                isConnected: hoveredNode === edge.source || hoveredNode === edge.target,
              },
            }))}
            edgeTypes={{ custom: CustomEdge }}
            onInit={onInit}
            minZoom={0.1}
            maxZoom={4.0}
            zoom={2.0}
            defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
            layoutEngine="dagre"
            onNodeMouseEnter={onNodeMouseEnter}
            onNodeMouseLeave={onNodeMouseLeave}
            onEdgeMouseEnter={onEdgeMouseEnter}
            onEdgeMouseLeave={onEdgeMouseLeave}
            selectionOnDrag
            selectNodesOnDrag
            zoomOnDoubleClick={() => {
              // set zoom to 1.0
              reactFlowInstance.zoomTo(1.0);
            }}
            panOnScroll
            panOnScrollMode="free"
            onNodesChange={customOnNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={onNodeClickHandler}
            fitView
          >
            <Background color="#444" gap={16} />
            {/* <Controls /> */}
            <Panel
              position="bottom-left"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "3px",
                marginBottom: isMobile ? '5rem' : null,
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.08)",
              }}
            >
              <Box sx={styles.controlsContainer}>
                <IconButton
                  onClick={handleZoomIn}
                  size="small"
                  sx={styles.controlButtonTwo}
                >
                  <ZoomIn />
                </IconButton>
                <IconButton
                  onClick={handleZoomOut}
                  size="small"
                  sx={styles.controlButtonTwo}
                >
                  <ZoomOut />
                </IconButton>
                <CustomTooltip title="Center">
                  <IconButton
                    onClick={handleFitView}
                    size="small"
                    sx={styles.controlButtonTwo}
                  >
                    <CenterFocusStrong />
                  </IconButton>
                </CustomTooltip>
                <CustomTooltip title="Shufle">
                  <IconButton
                    onClick={handleReorganize}
                    size="small"
                    sx={styles.controlButtonTwo}
                  >
                    <Shuffle />
                  </IconButton>
                </CustomTooltip>
              </Box>
            </Panel>
          </ReactFlow>
        )}
        {expandedRightSidebar && activeRightSidebar === "chat" && (
          <ChatView
            user={user}
            currentFile={currentFile}
            files={files}
            ref={chatViewRef}
          />
        )}
      </Box>
    </Box>
  );
};

const styles = {
  sidebar: (expandedRightSidebar, darkTheme) => ({
    minWidth: expandedRightSidebar
      ? expandedRightSidebar === "fullWidth"
        ? "60rem"
        : "20rem"
      : "1rem",
    maxWidth: expandedRightSidebar
      ? expandedRightSidebar === "fullWidth"
        ? "60rem"
        : "20rem"
      : "5rem",
    height: "100vh",
    backgroundColor: darkTheme ? COLORS.niceDeepBlack : COLORS.niceDarkGray,
    transition: "all 0.3s ease",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  }),
  toggleContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "16px",
    padding: "4px 12px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
  toggleText: {
    color: COLORS.niceWhite,
    fontSize: "0.8rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  controlsContainer: {
    display: "flex",
    flexDirection: "column",
    "& > *:not(:last-child)": {
      marginBottom: "4px",
    },
  },
  controlButton: {
    padding: "4px",
    color: COLORS.niceSuperLightGrey, // Changed from '#555' to '#333' for a darker color
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  visitorButton: {
    position: "absolute",
    right: 10,
    top: 10,
    backgroundColor: "dodgerblue",
    color: COLORS.niceWhite,
    "&:hover": {
      backgroundColor: COLORS.niceWhite,
      color: COLORS.niceDeepBlack,
    },
  },
  saveButton: {
    position: "absolute",
    right: "45%",
    top: 10,
    backgroundColor: COLORS.niceSyncingGreen,
    color: COLORS.niceWhite,
    "&:hover": {
      backgroundColor: COLORS.niceWhite,
      color: COLORS.niceDeepBlack,
    },
  },
  editButton: {
    position: "absolute",
    right: "37%",
    top: 10,
    backgroundColor: COLORS.niceWhite,
    color: COLORS.niceDeepBlack,
    "&:hover": {
      backgroundColor: COLORS.niceDeepBlack,
      color: COLORS.niceWhite,
    },
  },
  controlButtonTwo: {
    padding: "4px",
    color: COLORS.niceDeepBlack,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },

  topSectionIcon: (expandedRightSidebar) => ({
    cursor: "pointer",
    color: COLORS.niceSuperLightGrey,
    transform: expandedRightSidebar ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  }),
  flowContainer: {
    flex: 1,
    width: "100%",
    height: "100%", // 'calc(100% - 50px)', // Subtract header height
  },
  menuList: {
    backgroundColor: "#2A2A2A", // Dark background for the dropdown
    color: "#FFFFFF", // White text color
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "2px",
      background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
    },
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Light hover effect
    },
  },
  header: (darkTheme) => ({
    minHeight: "53px",
    backgroundColor: darkTheme ? COLORS.niceDeepBlack : "#2a2a2a",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
  }),
  visitorHeader: (darkTheme) => ({
    minHeight: "53px",
    backgroundColor: darkTheme ? COLORS.niceDeepBlack : "#2a2a2a",
    width: "100%",
    left: 0,
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-end", // This aligns items to the right
    padding: "0 8px",
  }),
  searchContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#1e1e1e",
    borderRadius: "4px",
    padding: "4px 4px",
    width: "100%",
    height: "30px",
  },
  searchIcon: {
    color: "#6e6e6e",
    marginRight: "8px",
    fontSize: "1.2rem",
  },
  searchInput: {
    backgroundColor: "transparent",
    border: "none",
    color: "#ffffff",
    fontSize: "0.9rem",
    width: "100%",
    outline: "none",
    "&::placeholder": {
      color: "#6e6e6e",
    },
  },
  folderItem: (expandedRightSidebar) => ({
    padding: "12px 16px",
    cursor: "pointer",
    justifyContent: expandedRightSidebar ? "space-between" : "center",
    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
  }),
  nestedItem: (expandedRightSidebar) => ({
    padding: "8px 16px 8px 32px",
    cursor: "pointer",
    justifyContent: expandedRightSidebar ? "flex-start" : "center",
    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.05)" },
  }),
  list: { padding: 0, overflowY: "auto", flexGrow: 1 },
  listItem: (expandedRightSidebar) => ({
    cursor: "pointer",
    justifyContent: expandedRightSidebar ? "flex-start" : "center",
    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
    overflowY: "auto", // Ensure overflow is handled
    maxHeight: expandedRightSidebar ? "none" : "200px", // Set a max height when not expanded
  }),
  controls: {
    borderBottom: "1px solid #333",
    width: "100%",
    height: "50px",
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-around",
  },

  collapsedControls: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: "4px 0",
    },
  },

  icon: {
    width: "24px", // Reduced from 40px
    minWidth: "24px", // Added to ensure consistent width
    marginRight: "8px", // Added to create some space between icon and text
  },
  text: {
    "& .MuiListItemText-primary": { color: COLORS.niceDarkGrey },
    "& .MuiListItemText-secondary": { color: "#a0a0a0" },
  },
  titleText: { color: COLORS.niceSuperLightGrey, fontSize: ".9rem" },
};

export default RightSidebar;
