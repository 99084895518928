import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { COLORS } from "../utils/colors";
import { CircularProgress, Typography } from "@mui/material";
import { motion } from "framer-motion";
import {
  fetchUserInfo,
  getRandomBrightColor,
  guidGenerator,
} from "../utils/helperMethods";
import { firebase } from "../firebaseConfig";
import UserContext from "../context/userContext";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css"; // choose your theme

const Container = styled.div`
  background-color: ${COLORS.niceDeepBlack};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${COLORS.niceWhite};
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  text-align: center;
  z-index: 1;
  max-width: 800px;
  width: 100%;
`;

const Title = styled.h1`
  font-size: clamp(2rem, 5vw, 4rem);
  font-weight: 900;
  letter-spacing: -0.02em;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.h2`
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-weight: 400;
  color: ${COLORS.nicePurple};
  margin-bottom: 2rem;
  opacity: 0.8;
`;

const GradientButton = styled(motion.button)`
  background: linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  color: ${COLORS.niceWhite};
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
`;

const SummaryPage = () => {
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();
  const [videoId, setVideoId] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [userStatus, setUserStatus] = useState("loading"); // 'loading', 'visitor', 'non-premium', 'premium'
  const [authChecked, setAuthChecked] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        setUserStatus("visitor");
        setAuthChecked(true);
        return;
      }

      try {
        const userProfile = await fetchUserInfo(user.uid);
        setUser((prevUser) => ({ ...prevUser, ...userProfile }));

        if (userProfile.premiumUser?.isActive) {
          setUserStatus("premium");
          setUser({ ...userProfile });
          setUserProfile({ ...userProfile });
          setAuthChecked(true);
        } else {
          setUserStatus("non-premium");
          setUser({ ...userProfile });
          setAuthChecked(true);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        toast("Error loading user profile", { position: "top-right" });
        setUserStatus("non-premium"); // Assume non-premium on error
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!authChecked) return; // Wait until auth state is checked

    if (userStatus === "loading") return;

    if (userStatus === "visitor") {
      toast("Please login to use this feature", { position: "top-right" });
      navigate("/");
      return;
    }

    if (userStatus === "non-premium") {
      toast("Please upgrade to premium to use this feature", {
        position: "top-right",
      });
      navigate("/pricing");
      return;
    }
    if (!userProfile) {
      // if not logged in
      toast("Please login to use this feature", { position: "top-right" });
      navigate("/");
      return;
    }

    if (!userProfile.premiumUser?.isActive) {
      // user is logged in but is not premiumUser
      toast("Please upgrade to premium to use this feature", {
        position: "top-right",
      });
      navigate("/pricing");
      return;
    }

    const extractVideoId = (url) => {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[2].length === 11 ? match[2] : null;
    };

    const fullUrl = `https://${location.pathname.slice(1)}${location.search}`;

    const id = extractVideoId(fullUrl);
    if (id) {
      // TODO: handle youtube - call
      toast("Youtube summarizationcoming soon", { position: "top-right" });
      navigate("/");
      return
    } else {
      // WEB PAGE summarization
      const cleanedUrl = fullUrl.replace(/https?:\/\//, "");
      // call summarize web page with gpt 4o
      summarizeWebPage(cleanedUrl);
    }
  }, [authChecked, userProfile]);

  const summarizeWebPage = async (url) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        "https://us-central1-notedfm-f6490.cloudfunctions.net/summarizeWebPage",
        { url, userId: user.id }
      );

      const { summary } = response.data;
      // Format the structured summary into HTML
      const formattedContent = `
      <h1>${summary?.title || ""}</h1>
      <p><strong>SOURCE URL:</strong> <a href="${
        summary?.url || ""
      }" target="_blank" rel="noopener noreferrer">${summary?.url || ""}</a></p>
      <h2>Executive Summary</h2>
      <p>${summary?.executiveSummary || ""}</p>
      
      <h2>Main Takeaways</h2>
      <ul>
        ${
          summary?.mainTakeaways
            ?.map((takeaway) => `<li>${takeaway}</li>`)
            .join("") || ""
        }
      </ul>
      
      <h2>Key Concepts</h2>
      ${
        summary?.keyConcepts
          ?.map(
            (concept) => `
        <h3>${concept.concept}</h3>
        <p>${concept.explanation}</p>
      `
          )
          .join("") || ""
      }
      
      <h2>Supporting Details</h2>
      <ol>
        ${
          summary.supportingDetails
            .map((detail) => `<li>${detail}</li>`)
            .join("") || ""
        }
      </ol>
      
      <h2>Practical Applications</h2>
      <ul>
        ${
          summary.practicalApplications
            .map(
              (app) => `
          <li>
            ${typeof app === "string" ? app : app.application}
            ${
              app.relevance
                ? `<br><small>Relevance: ${app.relevance}</small>`
                : ""
            }
          </li>
        `
            )
            .join("") || ""
        }
      </ul>
      
      ${
        summary.criticalAnalysis
          ? `
        <h2>Critical Analysis</h2>
        <h3>Strengths</h3>
        <ul>
          ${
            summary.criticalAnalysis.strengths
              .map((strength) => `<li>${strength}</li>`)
              .join("") || ""
          }
        </ul>
        <h3>Limitations</h3>
        <ul>
          ${
            summary.criticalAnalysis.limitations
              .map((limitation) => `<li>${limitation}</li>`)
              .join("") || ""
          }
        </ul>
        <h3>Counterarguments</h3>
        <ul>
          ${
            summary.criticalAnalysis.counterarguments
              .map((counterargument) => `<li>${counterargument}</li>`)
              .join("") || ""
          }
        </ul>
      `
          : ""
      }
      
      ${
        summary.methodology
          ? `
        <h2>Methodology</h2>
        <p>${summary.methodology}</p>
      `
          : ""
      }
      
      ${
        summary.conclusions
          ? `
        <h2>Conclusions</h2>
        <p>${summary.conclusions}</p>
      `
          : ""
      }
      
      <h2>Further Reading</h2>
      <ol>
        ${
          summary.furtherReading
            .map(
              (source) =>
                `<li><a href="${source}" target="_blank" rel="noopener noreferrer">${source}</a></li>`
            )
            .join("") || ""
        }
      </ol>
    `;

      // Create a new note with the formatted HTML content
      const newNote = {
        id: guidGenerator(),
        title: summary.title,
        content: formattedContent, // Store the formatted HTML
        color: getRandomBrightColor(),
        slug: summary.title.toLowerCase().replace(/\s+/g, "-"),
        createdAt: Date.now(),
        updatedAt: Date.now(),
        pinned: false,
        summarizedContent: true
      };

      // Save the new note to the database
      await firebase
        .database()
        .ref(`notes/${user.id}/notes/${newNote.id}`)
        .set(newNote);

      // Notify the user
      toast("Summary added as a new note", { position: "top-right" });

      // Navigate to the home page or the new note
      window.location.href = "/";
    } catch (error) {
      console.error("Error summarizing web page:", error);
      setError("Failed to summarize web page. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // WORKS FOR SAVING TO FIREBASE STORAGE mp3 - YOUTUBE SUMMARIZATION
  const downloadAudio = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios({
        url: "https://us-central1-notedfm-f6490.cloudfunctions.net/downloadYTAudio",
        method: "POST",
        data: { videoId },
      });

      const { audioUrl, title } = response.data;

    } catch (error) {
      console.error("Error downloading audio:", error);
      setError("Failed to download audio. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading && !videoId) {
    return (
      <Container>
        <ContentWrapper>
          <CircularProgress
            size={60}
            thickness={4}
            style={{ color: COLORS.nicePink }}
          />
          <Typography
            variant="h6"
            style={{ marginTop: "1rem", color: COLORS.niceWhite }}
          >
            Summarizing...
          </Typography>
          <Typography
            style={{
              fontSize: "0.8rem",
              marginTop: "1rem",
              color: COLORS.niceSuperLightGrey,
            }}
          >
            Almost done, give me a few more seconds...
          </Typography>
        </ContentWrapper>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <ContentWrapper>
          <Typography variant="h6" style={{ color: COLORS.nicePink }}>
            {error}
          </Typography>
          <GradientButton
            onClick={() => navigate("/")}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{ marginTop: "1rem" }}
          >
            Go Back
          </GradientButton>
        </ContentWrapper>
      </Container>
    );
  }

  return <Container></Container>;
};

export default SummaryPage;
