import React from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import { AccountTree, AddCircle, HelpOutlineOutlined, Person } from "@mui/icons-material";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { COLORS } from "../utils/colors";
import CustomTooltip from "./CustomTooltip";
import LogoutModal from "./LogoutModal";
import { useNavigate } from "react-router-dom";

const LeftSidebar = ({
  user,
  setExpandedRightSidebar,
  expandedRightSidebar,
  expanded,
  setShowOnboardingModal,
  setExpanded,
  showProfile,
  setShowProfile,
  handleLogout,
}) => {
  const navigate = useNavigate();

  const sidebarItems = [
    {
      text: "Open Notes",
      icon: <AccountTree />,
      action: () => {
        setExpandedRightSidebar("fullWidth");
        setExpanded(false);
      },
    },
    {
      text: "Discover Mind Maps",
      icon: <AddCircle />,
      action: () => {
        navigate("/discover")
      },
    },

    // ... other sidebar items
  ];

  const handleTopSectionClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={styles.sidebar}>
      <LogoutModal
        user={user}
        open={showProfile}
        onClose={() => setShowProfile(false)}
        onConfirm={() => handleLogout()}
      />

      <CustomTooltip title={expanded ? "Collapse" : "Expand"}>
        <Box sx={styles.topSection} onClick={handleTopSectionClick}>
          <SplitScreenIcon sx={styles.topSectionIcon} />
        </Box>
      </CustomTooltip>

      <List sx={styles.list}>
        <Box sx={styles.topItems}>
          {sidebarItems.map((item) => (
            <CustomTooltip key={item.text} title={item.text}>
              <ListItem button sx={styles.listItem} onClick={item.action}>
                <ListItemIcon sx={styles.icon}>{item.icon}</ListItemIcon>
              </ListItem>
            </CustomTooltip>
          ))}
        </Box>

        <Box sx={styles.bottomItems}>
          <IconButton onClick={() => setShowOnboardingModal(true)}>
            <HelpOutlineOutlined sx={{ color: COLORS.lightGrey, fontSize: "2.0rem" }} />
          </IconButton>
          <Box
            sx={styles.userIcon}
            onClick={() => {
              setShowProfile(true);
            }}
          >
            <Person sx={styles.userIconInner} />
          </Box>
        </Box>
      </List>
    </Box>
  );
};

const SplitScreenIcon = (props) => <ViewQuiltIcon {...props} />;

const styles = {
  sidebar: {
    width: "64px",
    height: "100vh",
    backgroundColor: COLORS.niceDarkGray,
    display: "flex",
    flexDirection: "column",
  },
  topSection: {
    height: "50px",
    backgroundColor: "#2a2a2a",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  list: {
    padding: 0,
    borderRight: "1px solid #333",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "auto",
  },
  topItems: {
    display: "flex",
    flexDirection: "column",
  },
  bottomItems: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItem: {
    padding: "16px 0",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  icon: {
    color: COLORS.niceSuperLightGrey,
    justifyContent: "center",
  },
  userIcon: {
    width: 25,
    height: 25,
    cursor: "pointer",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "16px auto",
    background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
    padding: "2px",
  },
  userIconInner: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    backgroundColor: COLORS.niceDarkGray,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: COLORS.niceSuperLightGrey,
  },
  topSectionIcon: {
    color: COLORS.niceSuperLightGrey,
    width: "30px",
    height: "30px",
  },
};

export default LeftSidebar;