import React from "react";
import {
  Typography,
  Modal,
  Box,
  useTheme,
  useMediaQuery,
  alpha,
} from "@mui/material";
import { COLORS } from "../utils/colors";
import RedClose from "./RedClose";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  RedditIcon,
} from "react-share";

const ShareModal = ({ open, onClose, shareUrl, title }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const shareButtons = [
    { Button: FacebookShareButton, Icon: FacebookIcon },
    { Button: TwitterShareButton, Icon: TwitterIcon },
    { Button: LinkedinShareButton, Icon: LinkedinIcon },
    { Button: WhatsappShareButton, Icon: WhatsappIcon },
    { Button: RedditShareButton, Icon: RedditIcon },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#1E1E1E",
          borderRadius: "16px",
          width: isMobile ? "90%" : "400px",
          padding: "32px",
          boxShadow: "0 4px 30px rgba(0,0,0,0.3)",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <RedClose handleClose={onClose} />
        </Box>

        <Typography
          variant="h5"
          sx={{ mb: 3, fontWeight: 700, textAlign: "center", color: "#FFFFFF" }}
        >
          Share
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {shareButtons.map(({ Button, Icon }, index) => (
            <Button
              key={index}
              url={shareUrl}
              title={title}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
            >
              <Icon size={48} round />
            </Button>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareModal;