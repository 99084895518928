import { AVATARS } from "./constants";
import { firebase, firestore } from "../firebaseConfig";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import { generateJSON } from "@tiptap/html";
import StarterKit from "@tiptap/starter-kit";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebaseConfig";

export const generateAvatars = (numAvatars) => {
  const womenAvatars = AVATARS.filter((url) => url.includes("women"));
  const menAvatars = AVATARS.filter((url) => url.includes("men"));

  const totalWomen = Math.ceil(numAvatars * 0.8);
  const totalMen = numAvatars - totalWomen;

  const selectedWomen = womenAvatars.slice(0, totalWomen);
  const selectedMen = menAvatars.slice(0, totalMen);

  return [...selectedWomen, ...selectedMen];
};

export const sanitizeTitle = (title) => {
  return title
    .replace(/&/g, 'and')
    .replace(/[<>]/g, '')  // Remove < and > as they're not allowed in URLs
    .replace(/[^a-zA-Z0-9-_\s]/g, '-');  // Replace other special characters with hyphens
};

export function formatTranscription(wordTimestamps, newLineThreshold = 2.5) {
  let formattedText = "";
  let lastEndTime = 0;

  wordTimestamps.forEach((wordInfo, index) => {
    const { word, startTime, endTime } = wordInfo;
    const start = parseFloat(startTime);
    const end = parseFloat(endTime);

    // Add a new line if there's a gap larger than the threshold
    if (start - lastEndTime > newLineThreshold) {
      formattedText += "\n\n"; // Double newline for paragraph-like separation
    } else if (index > 0) {
      // Add a space before the word if it's not the first word
      formattedText += " ";
    }

    formattedText += word;
    lastEndTime = end;
  });

  return formattedText.trim(); // Remove any leading/trailing whitespace
}

export const typewriterEffect = (text, callback) => {
  let i = 0;

  const interval = setInterval(() => {
    if (i < text?.length) {
      callback(text.substring(0, i + 1));
      i++;
    } else {
      clearInterval(interval);
    }
  }, 50); // Adjust typing speed here
};

export const getLatestDataForEachWebhook = (items) => {
  const latestData = {};
  items.forEach((item) => {
    if (
      !latestData[item.webhookName] ||
      item.timestamp > latestData[item.webhookName].timestamp
    ) {
      latestData[item.webhookName] = item;
    }
  });
  return Object.values(latestData);
};

export const getRelativeTime = (timestamp) => {
  const now = new Date();
  const date = new Date(timestamp);
  const diffDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return "today";
  } else if (diffDays === 1) {
    return "yesterday";
  } else if (diffDays < 7) {
    return `${diffDays} days ago`;
  } else {
    return date.toLocaleDateString();
  }
};

export const fetchUserInfo = async (userId) => {
  try {
    const userDoc = await firestore.collection("users").doc(userId).get();
    if (userDoc.exists) {
      return { id: userDoc.id, ...userDoc.data() };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};

export const fetchAllAgents = async (userId) => {
  try {
    const agentsSnapshot = await firestore.collection("agents").get();
    if (!agentsSnapshot.empty) {
      const agents = agentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return agents;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching agents data:", error);
    return null;
  }
};

export const logoutFirebase = async () => {
  try {
    await firebase.auth().signOut();
    toast("Successfully logged out", { position: "top-right" });
  } catch (error) {
    console.error("Error logging out:", error);
  }
};

export const handleNav = (path) => {
  window.location.href = path;
};

export const guidGenerator = () => {
  const S4 = () =>
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return `${S4()}${S4()}${S4()}${S4()}${S4()}${S4()}${S4()}${S4()}`;
};

export const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, (l) => l.toUpperCase());
};

export const generateTiptapJSON = (html) => {
  return generateJSON(html, [StarterKit]);
};

export const formatTitle = (title) => {
  // Remove HTML tags and extract text content
  const div = document.createElement("div");
  div.innerHTML = title;
  return div.textContent || div.innerText || title;
};

export const sortFilesByPinned = (files) => {
  return [...files].sort((a, b) => {
    if (a.pinned === b.pinned) {
      // If both are pinned or both are not pinned, sort by updatedAt
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    }
    // Pinned notes come first
    return a.pinned ? -1 : 1;
  });
};

export const getRandomBrightColor = () => {
  const hue = Math.floor(Math.random() * 360); // Random hue between 0 and 360
  const saturation = 100; // Full saturation for bright colors
  const lightness = Math.floor(Math.random() * 40) + 50; // Lightness between 50% and 90%
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`; // Return HSL color
};

export const extractWikiLinks = (content) => {
  const regex = /\[\[(.*?)\]\]/g;
  const matches = content.match(regex);
  return matches ? matches.map((match) => match.slice(2, -2)) : [];
};

export const fetchNotesForFolders = async (folders, allNotes) => {
  const updatedFolders = { ...folders };

  for (const folderId in updatedFolders) {
    if (updatedFolders[folderId].notes) {
      const folderNotes = {};
      for (const noteId in updatedFolders[folderId].notes) {
        const fullNote = allNotes.find((note) => note.id === noteId);
        if (fullNote) {
          folderNotes[noteId] = fullNote;
        }
      }
      updatedFolders[folderId].notes = folderNotes;
    }
  }

  return updatedFolders;
};

export const isUserPremium = (user) => {
  if (user?.premiumUser && user?.premiumUser?.isActive) {
    return true;
  }
  return false;
};

export const handleSubmit = async (
  event,
  email,
  setLoading,
  setGlobalState,
  globalState,
  location,
  toast
) => {
  event.preventDefault();
  setLoading(true);

  try {
    // Check if the email already exists
    const signInMethods = await firebase
      .auth()
      .fetchSignInMethodsForEmail(email);

    if (signInMethods.length > 0) {
      // Email already exists
      toast("This email is already registered. Please sign in instead.");
      // You might want to switch to a sign-in modal here
      return;
    }

    const actionCodeSettings = {
      url: `${window.location.origin}/finishSignUp`,
      handleCodeInApp: true,
    };

    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);

    window.localStorage.setItem("emailForSignIn", email);
    // Store the current location
    window.localStorage.setItem("previousLocation", location.pathname);

    toast("Check your email inbox or spam for login link.");
    setGlobalState({ ...globalState, showSignupModal: false });
  } catch (error) {
    console.error("Error sending sign-in link to email:", error);
    toast(`Failed to send magic link. ${error.message}`);
  } finally {
    setLoading(false);
  }
};

export const uploadImage = async (file) => {
  const storageRef = ref(storage, `images/${Date.now()}_${file.name}`);
  await uploadBytes(storageRef, file);
  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};
