import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  useMediaQuery,
  Typography,
  Button,
} from "@mui/material";
import { COLORS } from "../utils/colors";
import UserContext from "../context/userContext";
import GlobalStateContext from "../context/globalStateContext";
import SignupModal from "../components/SignupModal";
import { STANDARD_BORDER_RADIUS } from "../utils/constants";
import {
  fetchNotesForFolders,
  fetchUserInfo,
  generateTiptapJSON,
  getRandomBrightColor,
  guidGenerator,
  logoutFirebase,
  sortFilesByPinned,
} from "../utils/helperMethods";
import OnboardingModal from "../components/OnboardingModal";
import { useTheme } from "@emotion/react";
import CancellationModal from "../components/CancellationModal";
import HowItWorksModal from "../components/HowItWorksModal.js";
import InvitePeopleModal from "../components/InvitePeopleModal.js";
import { useNavigate } from "react-router-dom";
import LeftSidebar from "../components/LeftSidebar.js";
import MainContent from "../components/MainContent.js";
import RightSidebar from "../components/RightSidebar.js";
import FileExplorer from "../components/FileExplorer.js";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css"; // choose your theme
import { database, firestore } from "../firebaseConfig.js";
import axios from "axios";
import { styled } from "@mui/system";
import DemoVideoModal from "../components/DemoVideoModal.js";

const MobileContainer = styled(Box)({
  minHeight: "100vh",
  backgroundColor: COLORS.niceDeepBlack,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "2rem",
  textAlign: "center",
  color: COLORS.niceWhite,
});

const Title = styled(Typography)({
  fontSize: "clamp(2rem, 8vw, 3rem)",
  fontWeight: 900,
  marginBottom: "1rem",
  background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});

const Subtitle = styled(Typography)({
  fontSize: "1.2rem",
  marginBottom: "2rem",
  opacity: 0.8,
});

const StyledButton = styled(Button)({
  background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.nicePink})`,
  color: COLORS.niceWhite,
  padding: "0.8rem 1.5rem",
  fontSize: "1rem",
  fontWeight: 600,
  borderRadius: "4px",
  textTransform: "none",
  "&:hover": {
    opacity: 0.9,
  },
});

const BACKGROUND_COLOR = "#F1F3F4";

const HomePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { user, setUser } = useContext(UserContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(true);
  const [expandedRightSidebar, setExpandedRightSidebar] = useState(true);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [activeRightSidebar, setActiveRightSidebar] = useState("mindMap");
  const [isEditMode, setIsEditMode] = useState(true);
  const [activeFolder, setActiveFolder] = useState(null);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [activeFile, setActiveFile] = useState(null);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  const [notesVersion, setNotesVersion] = useState(0);
  const [showShareMindmapModal, setShowShareMindmapModal] = useState(false);
  const [showFolderOptionsModal, setShowFolderOptionsModal] = useState(false);
  const [showDemoVideoModal, setShowDemoVideoModal] = useState(false);
  const [showFolderSettingsModal, setShowFolderSettingsModal] = useState(false);
  const [currentState, setCurrentState] = useState({
    openFiles: [],
    activeNoteId: null,
    history: [],
    historyIndex: -1,
  });
  const [showMindMapModal, setShowMindMapModal] = useState(false);
  const [isMindMapLoading, setIsMindMapLoading] = useState(false);
  const [mindMapProgress, setMindMapProgress] = useState(0);
  const folderNameRef = useRef("");
  const chatViewRef = useRef(null);
  const filesRef = useRef(null);

  useEffect(() => {
    const checkUser = async () => {
      if (user?.id) {
        if (!user.email) {
          // User ID exists but email is missing, fetch full profile
          await getUserProfile(user.id);
        }
        setIsLoading(false);
      } else if (!user?.id && !isLoading) {
        navigate("/");
      }
      setIsLoading(false);
    };

    checkUser();

    // Check if user has seen the demo video
    const seenDemoVideo = localStorage.getItem("seenDemoVideo");
    console.log("-----------", seenDemoVideo);
    // localStorage.clear()
    if (!seenDemoVideo) {
      setShowDemoVideoModal(true);
    }
  }, [user?.id, isLoading]);

  useEffect(() => {
    const checkUser = async () => {
      if (user?.id) {
        if (!user.email) {
          // User ID exists but email is missing, fetch full profile
          await getUserProfile(user.id);
        }
        setIsLoading(false);
      } else if (!user?.id && !isLoading) {
        navigate("/");
      }
      setIsLoading(false);
    };

    checkUser();
  }, [user?.id, isLoading]);

  useEffect(() => {
    const fetchNotes = async () => {
      if (!user?.id) return;
      const notes = await database.ref(`notes/${user?.id}/notes`).once("value");
      const notesData = notes.val();

      if (!notesData) return;
      // If notes exist, set them and make the first one active
      let notesArray = Object.values(notesData);
      notesArray = notesArray.map((note) => ({
        ...note,
        pinned: note.pinned || false,
      })); // Ensure all notes have a pinned property

      // All those not pinned, sort by date - latest first
      const notPinnedNotes = notesArray.filter((note) => !note.pinned);
      const sortedByDateNotPinned = notPinnedNotes.sort(
        (a, b) => b.createdAt - a.createdAt
      );
      const pinnedNotes = notesArray.filter((note) => note.pinned);
      const sortedNotesArray = [...pinnedNotes, ...sortedByDateNotPinned];

      setFiles(sortedNotesArray);
      filesRef.current = sortedNotesArray;
      setOpenFiles(sortedByDateNotPinned[0]);
      setActiveFile(sortedByDateNotPinned[0]);

      handleTabChange(
        sortedByDateNotPinned[0].id,
        sortedByDateNotPinned[0],
        sortedByDateNotPinned
      );
    };

    fetchNotes();
  }, [user?.id]);

  // Get Folders from db
  useEffect(() => {
    const getFolders = async () => {
      if (user?.id) {
        const folders = await database
          .ref(`notes/${user?.id}/folders`)
          .once("value");

        const foldersData = folders.val();
        const updatedFolders = await fetchNotesForFolders(
          foldersData,
          filesRef.current
        );
        setFolders(updatedFolders);
      }
    };
    getFolders();
  }, [user?.id, files]);

  // Gets user profile
  const getUserProfile = async (userId) => {
    let userData = await fetchUserInfo(userId);
    // Fetch user folders from Firebase Realtime Database
    const foldersRef = database.ref(`notes/${userId}/folders`);
    const foldersSnapshot = await foldersRef.once("value");
    const foldersData = foldersSnapshot.val();
    // Convert folders object to array if it exists
    const folders = foldersData
      ? Object.entries(foldersData).map(([id, folder]) => ({
          ...folder,
          id,
        }))
      : [];

    // Combine user data with folders
    userData = { ...userData, folders };
    setUser((prevUser) => ({ ...prevUser, ...userData }));
    setIsLoading(false);
  };

  const incrementNotesVersion = () => {
    setNotesVersion((prev) => prev + 1);
  };

  const openNote = (note) => {
    setCurrentState((prevState) => {
      const isAlreadyOpen = prevState.openFiles.some(
        (file) => file.id === note.id
      );
      let newOpenFiles = [...prevState.openFiles];

      if (!isAlreadyOpen) {
        newOpenFiles.push(note);
      }

      const newHistory = [
        ...prevState.history.slice(0, prevState.historyIndex + 1),
        note.id,
      ];

      return {
        openFiles: newOpenFiles,
        activeNoteId: note.id,
        history: newHistory,
        historyIndex: newHistory.length - 1,
      };
    });
  };

  const closeNote = (noteId) => {
    setCurrentState((prevState) => {
      const newOpenFiles = prevState.openFiles.filter(
        (file) => file.id !== noteId
      );
      const newActiveNoteId =
        prevState.activeNoteId === noteId
          ? newOpenFiles[newOpenFiles.length - 1]?.id || null
          : prevState.activeNoteId;

      if (newOpenFiles.length > 0) {
        setActiveFile(newOpenFiles[newOpenFiles.length - 1]);
      } else if (newOpenFiles.length === 0) {
        setActiveFile(null);
      }
      return {
        ...prevState,
        openFiles: newOpenFiles,
        activeNoteId: newActiveNoteId,
        // Optionally update history here
      };
    });
  };

  const updateNoteContent = async (noteId, newContent) => {
    if (!noteId) {
      console.error("--- note id is missing ---");
      return;
    }
    // Extract title from the first <h1> tag
    let title = newContent?.title || "";

    const updatedNote = {
      ...newContent,
      title,
      updatedAt: Date.now(),
    };

    // Check if the title has changed
    const oldNote = files.find((file) => file.id === noteId);
    if (oldNote && oldNote.title !== title) {
      incrementNotesVersion(); // Increment version if title has changed
    }

    // Update local state
    setCurrentState((prevState) => ({
      ...prevState,
      openFiles: prevState.openFiles?.map((file) =>
        file.id === noteId ? { ...file, ...updatedNote } : file
      ),
    }));

    // Update files state
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === noteId ? { ...file, ...updatedNote } : file
      )
    );

    // Save to database
    if (user?.id) {
      try {
        await database
          .ref(`notes/${user.id}/notes/${noteId}`)
          .update(updatedNote);
      } catch (error) {
        console.error("Error updating note in database:", error);
        // You might want to show an error message to the user here
      }
    }
  };

  const goBack = () => {
    if (currentState?.historyIndex > 0) {
      const newIndex = currentState?.historyIndex - 1;
      const newActiveNoteId = currentState?.history[newIndex];
      const newActiveFile =
        files && files?.find((file) => file.id === newActiveNoteId);

      setCurrentState((prevState) => ({
        ...prevState,
        activeNoteId: newActiveNoteId,
        historyIndex: newIndex,
      }));

      setActiveFile(newActiveFile);
    }
  };

  const goForward = () => {
    if (currentState.historyIndex < currentState.history.length - 1) {
      const newIndex = currentState.historyIndex + 1;
      const newActiveNoteId = currentState.history[newIndex];
      const newActiveFile = files.find((file) => file.id === newActiveNoteId);

      setCurrentState((prevState) => ({
        ...prevState,
        activeNoteId: newActiveNoteId,
        historyIndex: newIndex,
      }));

      setActiveFile(newActiveFile);
    }
  };

  const handleAIMapCreation = async (aiGenFeature, folder) => {
    setIsMindMapLoading(true);
    setMindMapProgress(10);
    // create root note
    const rootNote = await createAIRootNote(aiGenFeature);
    setMindMapProgress(20);

    // move root note to folder
    await handleSelectFolder(folder, rootNote);
    setMindMapProgress(30);

    // GPT RESPONSE - get back JSON of first order notes linked to root
    const response = await axios.post(
      "https://us-central1-notedfm-f6490.cloudfunctions.net/generateAIMindMap",
      {
        mapName: aiGenFeature?.mapName,
        description: aiGenFeature?.description,
        userId: user.id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let allNotes = response.data.related_notes.allNotes;
    setMindMapProgress(50);

    // Set initial positions
    const rootPosition = { x: 0, y: 0 };
    const firstOrderSpacing = -300; // Negative to position left of root
    const secondOrderSpacing = -500; // Negative to position left of first-order
    const verticalSpacing = 300;
    const secondOrderVerticalSpacing = 200; // Increased spacing for second-order nodes

    const nodeSize = 30;

    // Initialize nodePositions object
    const nodePositions = {
      [rootNote.id]: rootPosition,
    };

    // Helper function to check if two nodes overlap
    const nodesOverlap = (pos1, pos2) => {
      const dx = pos1.x - pos2.x;
      const dy = pos1.y - pos2.y;
      return Math.sqrt(dx * dx + dy * dy) < nodeSize * 2; // * 2 for some padding
    };

    // Helper function to find a non-overlapping position
    const findNonOverlappingPosition = (basePosition, existingPositions) => {
      let newPosition = { ...basePosition };
      let attempts = 0;
      const maxAttempts = 100; // Prevent infinite loop

      while (attempts < maxAttempts) {
        const overlapping = Object.values(existingPositions).some((pos) =>
          nodesOverlap(newPosition, pos)
        );

        if (!overlapping) {
          return newPosition;
        }

        // Adjust position slightly
        newPosition.y += (Math.random() - 0.5) * nodeSize * 2;
        newPosition.x += (Math.random() - 0.5) * nodeSize;
        attempts++;
      }

      return newPosition; // Return the last attempted position if we can't find a non-overlapping one
    };

    // Save first order notes
    await Promise.all(
      allNotes.map(async (note, index) => {
        const firstOrderNote = {
          ...note,
          id: guidGenerator(),
          createdAt: Date.now(),
          updatedAt: Date.now(),
          color: note.color || getRandomBrightColor(),
        };

        // link 1st order to root notes via <a> hyperlink (tiptap)
        firstOrderNote.content = addLinkToContent(
          firstOrderNote.content,
          rootNote
        );

        // Position first order notes to the right of the root
        let firstOrderPosition = {
          x: rootPosition.x + firstOrderSpacing,
          y: (index - (allNotes.length - 1) / 2) * verticalSpacing,
        };

        firstOrderPosition = findNonOverlappingPosition(
          firstOrderPosition,
          nodePositions
        );
        nodePositions[firstOrderNote.id] = firstOrderPosition;

        // Link 1st order to root notes via <a> hyperlink (tiptap)
        firstOrderNote.content = addLinkToContent(
          firstOrderNote.content,
          rootNote
        );

        // Save the new note to the database
        await database
          .ref(`notes/${user.id}/notes/${firstOrderNote.id}`)
          .set(firstOrderNote);

        // Add note to the folder
        await database
          .ref(
            `notes/${user.id}/folders/${folder.id}/notes/${firstOrderNote.id}`
          )
          .set({
            id: firstOrderNote.id,
          });

        // Add to files state
        setFiles((prevFiles) => {
          const updatedFiles = [firstOrderNote, ...prevFiles];
          return sortFilesByPinned(updatedFiles);
        });

        // Update the note content in the state and database
        await updateNoteContent(firstOrderNote.id, firstOrderNote);

        setMindMapProgress(
          50 + Math.floor(((index + 1) / allNotes.length) * 30)
        );

        // Generate and save second-order notes for this first-order note
        const secondOrderResponse = await axios.post(
          "https://us-central1-notedfm-f6490.cloudfunctions.net/generateSecondOrderNotes",
          {
            firstOrderNote: firstOrderNote,
            userId: user.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        let secondOrderNotes = secondOrderResponse.data.secondOrderNotes;

        secondOrderNotes = secondOrderNotes?.secondOrderNotes;

        // Save second-order notes
        await Promise.all(
          secondOrderNotes.map(async (secondOrderNote, secondIndex) => {
            const newSecondOrderNote = {
              ...secondOrderNote,
              id: guidGenerator(),
              createdAt: Date.now(),
              updatedAt: Date.now(),
              color: getRandomBrightColor(),
            };

            // Position second order notes to the left of the first order notes
            let secondOrderPosition = {
              x: firstOrderPosition.x + secondOrderSpacing,
              y:
                firstOrderPosition.y +
                (secondIndex - (secondOrderNotes.length - 1) / 2) *
                  (verticalSpacing * 0.7),
            };

            // Save position
            secondOrderPosition = findNonOverlappingPosition(
              secondOrderPosition,
              nodePositions
            );
            nodePositions[newSecondOrderNote.id] = secondOrderPosition;

            // Link 2nd order to 1st order notes via <a> hyperlink (tiptap)
            newSecondOrderNote.content = addLinkToContent(
              newSecondOrderNote.content,
              firstOrderNote
            );

            // link 2nd order to 1st order notes via <a> hyperlink (tiptap)
            newSecondOrderNote.content = addLinkToContent(
              newSecondOrderNote.content,
              firstOrderNote
            );

            // Save the new second-order note to the database
            await database
              .ref(`notes/${user.id}/notes/${newSecondOrderNote.id}`)
              .set(newSecondOrderNote);

            // Add second-order note to the folder
            await database
              .ref(
                `notes/${user.id}/folders/${folder.id}/notes/${newSecondOrderNote.id}`
              )
              .set({
                id: newSecondOrderNote.id,
              });

            // Add to files state
            setFiles((prevFiles) => {
              const updatedFiles = [newSecondOrderNote, ...prevFiles];
              return sortFilesByPinned(updatedFiles);
            });

            // Update the note content in the state and database
            await updateNoteContent(newSecondOrderNote.id, newSecondOrderNote);
          })
        );
      })
    );

    // Save node position for root note
    await database
      .ref(`notes/${user.id}/folders/${folder.id}/nodePositions`)
      .set(nodePositions);

    // Set progress to 100% after all updateNoteContent calls are finished
    setMindMapProgress(100);
    setIsMindMapLoading(false);
    setMindMapProgress(0);
    setShowMindMapModal(false);

    // custom node positions so it looks good out the gate

    setTimeout(() => {
      const reloadConfirmed = window.confirm(
        "Successfully created. Would you like to reload the page?"
      );
      if (reloadConfirmed) {
        window.location.reload();
      }
    }, 2000);
  };

  const createAIRootNote = async (values) => {
    const newNote = {
      id: guidGenerator(),
      title: values?.folderName,
      content: `<h1>${values?.folderName}</h1><p><br><br><string>AI Generated Prompt: </string>${values?.description}</p>`,
      slug: values?.folderName.toLowerCase().replace(/\s+/g, "-"),
      createdAt: Date.now(),
      updatedAt: Date.now(),
      color: getRandomBrightColor(),
    };

    // Save the new note to the database
    await database.ref(`notes/${user.id}/notes/${newNote.id}`).set(newNote);

    // add to files
    setFiles((prevFiles) => {
      const updatedFiles = [newNote, ...prevFiles];
      return sortFilesByPinned(updatedFiles);
    });

    return newNote;
  };

  const handleCreateFolder = async (event, aiGenFeature) => {
    event.preventDefault();

    // EDIT MODE
    if (!isEditMode) {
      toast("You are in view mode. Please switch to edit mode.", {
        position: "top-right",
      });
      return;
    }

    // get all user folders
    const allFolders = user?.folders || [];
    // check if folder already exists
    const folderExists = allFolders.some(
      (folder) => folder.name === folderNameRef.current
    );
    // if it does, show error
    if (folderExists) {
      toast("Folder already exists", {
        position: "top-right",
      });
      return;
    }
    // if it doesn't, create folder
    const newFolder = {
      id: guidGenerator(),
      name: aiGenFeature?.folderName || folderNameRef.current.value,
      slug: aiGenFeature?.folderName
        ? aiGenFeature?.folderName.toLowerCase().replace(/\s+/g, "-")
        : folderNameRef.current.value.toLowerCase().replace(/\s+/g, "-"),
      createdAt: new Date(),
      updatedAt: new Date(),
      folderColor: getRandomBrightColor(),
    };

    setUser((prevUser) => ({
      ...prevUser,
      folders: [...allFolders, newFolder],
    }));

    // save to db
    const folderRef = database.ref(`notes/${user.id}/folders/${newFolder.id}`);
    await folderRef.set(newFolder);

    // Update local state
    setUser((prevUser) => ({
      ...prevUser,
      folders: [...allFolders, { ...newFolder, id: folderRef.key }],
    }));

    // update state
    setFolders((prevFolders) => ({
      ...prevFolders,
      [newFolder.id]: newFolder,
    }));

    if (aiGenFeature?.folderName) {
      // if AI gen feature, take it to another function for more work
      handleAIMapCreation(aiGenFeature, newFolder);
      return;
    }

    toast("Folder created successfully", {
      position: "top-right",
    });

    // cleanup & close modal
    folderNameRef.current = "";
    setShowFolderModal(false);
  };

  const handleFileClick = (file) => {
    setActiveFile(file);
    openNote(file);
  };

  const handlePinNote = async (note) => {
    const updatedNote = { ...note, pinned: !note.pinned };

    // Update local state
    setFiles((prevFiles) =>
      prevFiles.map((file) => (file.id === note.id ? updatedNote : file))
    );

    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.map((file) =>
        file.id === note.id ? updatedNote : file
      );
      return sortFilesByPinned(updatedFiles);
    });

    // Save to database
    if (user?.id) {
      try {
        if (!note.id) {
          console.error("--- note id is missing ---");
          return;
        }
        await database
          .ref(`notes/${user.id}/notes/${note.id}`)
          .update({ pinned: updatedNote.pinned });
        toast(updatedNote.pinned ? "Note pinned" : "Note unpinned", {
          position: "top-right",
        });
      } catch (error) {
        console.error("Error updating note pinned status in database:", error);
        toast("Error updating note pinned status", {
          position: "top-right",
        });
      }
    }
  };

  const handleTabChange = (newActiveId, newNote, notesArr) => {
    // Find the file corresponding to the new active ID
    const newActiveFile =
      newNote || files.find((file) => file.id === newActiveId);
    if (newActiveFile) {
      setActiveFile(newActiveFile);
    }

    // chekc to see if the active file is already open
    const isAlreadyOpen = currentState?.openFiles.some(
      (file) => file.id === newActiveFile.id
    );

    // update open files
    let uOpenFiles = currentState?.openFiles
      ? [newActiveFile, ...currentState?.openFiles]
      : [newActiveFile];
    if (uOpenFiles[0] === undefined) uOpenFiles = [];

    if (uOpenFiles?.length > 0 && !isAlreadyOpen) {
      setOpenFiles(uOpenFiles, newActiveFile.id);
    } else if (newActiveFile && isAlreadyOpen) {
      setCurrentState((prevState) => ({
        ...prevState,
        activeNoteId: newActiveFile?.id,
      }));
    }
  };

  const setOpenFiles = (newOpenFiles, activeNoteId) => {
    setCurrentState((prevState) => ({
      ...prevState,
      activeNoteId: activeNoteId,
      openFiles: newOpenFiles, // Array.isArray(newOpenFiles) ? newOpenFiles : [newOpenFiles],
    }));
  };

  const handleSetShowFolderOptionsModal = (newShowFolderOptionsModal) => {
    setShowFolderOptionsModal(newShowFolderOptionsModal);
  };

  const handleDeleteFolder = async (folder) => {
    if (!user?.id || !folder?.id) {
      console.error("User ID or Folder ID is missing");
      return;
    }
    //TODO add a way to delete all the associated notes in the folder - easier cleanup
    // ask to confirm

    try {
      // Remove the folder from the database
      await database.ref(`notes/${user.id}/folders/${folder.id}`).remove();

      // Update local state
      setFolders((prevFolders) => {
        const updatedFolders = { ...prevFolders };
        delete updatedFolders[folder.id];
        return updatedFolders;
      });

      // Update user's folders array
      setUser((prevUser) => {
        if (!prevUser) return prevUser;
        const updatedFolders = prevUser.folders
          ? prevUser.folders.filter((f) => f.id !== folder.id)
          : [];
        return {
          ...prevUser,
          folders: updatedFolders,
        };
      });

      // If we've deleted the last folder, update the activeFolder state
      setActiveFolder((prevActiveFolder) => {
        if (prevActiveFolder && prevActiveFolder.id === folder.id) {
          return null;
        }
        return prevActiveFolder;
      });
      toast("Folder deleted successfully", {
        position: "top-right",
      });
    } catch (error) {
      console.error("Error deleting folder:", error);
      toast("Error deleting folder", {
        position: "top-right",
      });
    }
  };

  const handleDeleteNote = async (note) => {
    if (!user?.id) return;
    // Remove the note from all folders in the database
    const foldersRef = database.ref(`notes/${user.id}/folders`);
    const foldersSnapshot = await foldersRef.once("value");
    const foldersData = foldersSnapshot.val();

    if (foldersData) {
      Object.keys(foldersData).forEach(async (folderId) => {
        if (!note.id) {
          console.error("--- note id is missing ---");
          return;
        }
        const noteRef = database.ref(
          `notes/${user.id}/folders/${folderId}/notes/${note.id}`
        );
        await noteRef.remove();
      });
    }

    if (!note.id) {
      console.error("--- note id is missing ---");
      return;
    }
    // Remove from db
    await database.ref(`notes/${user.id}/notes/${note.id}`).set(null);

    // Remove the note from the local state
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== note.id));

    // Close the note if it's open
    closeNote(note.id);

    // Update the folders state to remove the note
    setFolders((prevFolders) => {
      const updatedFolders = { ...prevFolders };
      Object.keys(updatedFolders).forEach((folderId) => {
        if (updatedFolders[folderId].notes) {
          delete updatedFolders[folderId].notes[note.id];
        }
      });
      return updatedFolders;
    });

    toast("Note deleted successfully", {
      position: "top-right",
    });
  };

  const handleSelectFolder = async (folder, note) => {
    if (!note.id) {
      console.error("--- note id is missing ---");
      return;
    }
    // update folder in database
    const noteRef = database.ref(
      `notes/${user.id}/folders/${folder.id}/notes/${note.id}`
    );

    // Save the note ID to the selected folder
    // await noteRef.set(
    //   JSON.parse(
    //     JSON.stringify({
    //       id: note.id,
    //       title: note.title, // You can include other note properties if needed
    //       content: note.content,
    //       createdAt: note.createdAt,
    //       updatedAt: note.updatedAt,
    //     })
    //   )
    // );
    await noteRef.set(
      JSON.parse(
        JSON.stringify({
          id: note.id,
        })
      )
    );

    // updaet state
    setFolders((prevFolders) => ({
      ...prevFolders,
      [folder.id]: { ...folder, notes: { ...folder.notes, [note.id]: note } },
    }));

    toast(`Succesfully moved ${note?.title} note to ${folder?.name} folder`, {
      position: "top-right",
    });
  };

  const handleNodeClick = (nodeId) => {
    const clickedFile = files.find((file) => file.id === nodeId);
    if (clickedFile) {
      handleFileClick(clickedFile);
    }
  };

  const handleLogout = () => {
    logoutFirebase();
    setUser(null);
    setShowProfile(false);
  };

  const NoteSchema = {
    type: "object",
    properties: {
      id: { type: "string" },
      title: { type: "string" },
      content: { type: "string" },
      slug: { type: "string" },
      createdAt: { type: "number" },
      updatedAt: { type: "number" },
      color: { type: "string" },
    },
    required: [
      "id",
      "title",
      "content",
      "slug",
      "createdAt",
      "updatedAt",
      "color",
    ],
    additionalProperties: false,
  };

  const LinkedNotesSchema = {
    type: "object",
    properties: {
      linkedNotes: {
        type: "array",
        items: NoteSchema,
      },
    },
    required: ["linkedNotes"],
    additionalProperties: false,
  };

  const saveNote = async (noteId, updatedNote) => {
    await database.ref(`notes/${user.id}/notes/${noteId}`).update(updatedNote);
  };

  // Helper function to add a link to the note content
  const addLinkToContent = (existingContent, linkedNote) => {
    const linkToAdd = `<a href="[[${linkedNote.slug}]]" class="tiptap-link">${linkedNote.title}</a>`;
    return existingContent + linkToAdd;
  };

  const handleSetActiveRightSidebar = (newActiveRightSidebar) => {
    setActiveRightSidebar(newActiveRightSidebar);
  };

  const handleGenerateSmartLinks = async () => {
    const allNotes = files;
    const activeNote = activeFile;

    try {
      const response = await axios.post(
        "https://us-central1-notedfm-f6490.cloudfunctions.net/generateSmartLinks",
        {
          activeNote,
          allNotes,
          userId: user.id,
          responseSchema: LinkedNotesSchema,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // adding or removing links to the note
      const linkedNotes = response.data.related_notes.linkedNotes;

      linkedNotes.forEach((linkedNote) => {
        const existingNote = files.find(
          (note) => note.slug === linkedNote.slug
        );
        if (existingNote) {
          const updatedContent = addLinkToContent(
            existingNote.content,
            activeNote
          );

          // If we were updating Firebase, we would do something like this:
          saveNote(existingNote.id, {
            ...existingNote,
            content: updatedContent,
          });

          // update the note in the local state
          setFiles((prevFiles) =>
            prevFiles.map((file) =>
              file.id === existingNote.id
                ? { ...existingNote, content: updatedContent }
                : file
            )
          );
        } else {
          // console.log(`Note ${linkedNote.id} (${linkedNote.title}) not found in existing notes.`);
        }
      });

      // Update the active note with the new links
      const updatedContent = addLinksToContent(activeNote.content, linkedNotes);

      // Update the note in the local state and database
      // await updateNoteContent(activeNote.id, { ...activeNote, content: updatedContent });

      toast("Note links generated!", { position: "top-right" });
    } catch (error) {
      console.error("Error generating smart links:", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
      }
      toast(
        "Error generating smart links: " +
          (error.response?.data?.error || error.message),
        { position: "top-right" }
      );
    }
  };

  const addLinksToContent = (existingContent, linkedNotes) => {
    let updatedContent = existingContent;
    linkedNotes.forEach((linkedNote) => {
      const linkToAdd = `<a href="[[${linkedNote.slug}]]" class="tiptap-link">${linkedNote.title}</a>`;
      updatedContent += linkToAdd;
    });
    return updatedContent;
  };

  const handleCloseDemoVideoModal = () => {
    setShowDemoVideoModal(false);
    localStorage.setItem("seenDemoVideo", "true");
  };

  const updateFolderPublicStatus = async (folderId, isPublic) => {
    if (!user?.id || !folderId) {
      console.error("User ID or Folder ID is missing");
      return;
    }

    try {
      // Update the folder in the database
      const folderRef = database.ref(`notes/${user.id}/folders/${folderId}`);
      await folderRef.update({ public: isPublic });

      // Update local state
      setFolders((prevFolders) => ({
        ...prevFolders,
        [folderId]: {
          ...prevFolders[folderId],
          public: isPublic,
          username: user.username,
        },
      }));

      toast(
        `Folder ${isPublic ? "made public" : "made private"} successfully`,
        {
          position: "top-right",
        }
      );
    } catch (error) {
      console.error("Error updating folder public status:", error);
      toast("Error updating folder status", {
        position: "top-right",
      });
    }
  };

  const handleRemoveNoteFromFolder = async (folder, note) => {
    if (!note.id || !folder.id) {
      console.error("Note ID or Folder ID is missing");
      return;
    }

    // Remove note from folder in database
    const noteRef = database.ref(
      `notes/${user.id}/folders/${folder.id}/notes/${note.id}`
    );

    try {
      // Remove the note reference from the folder
      await noteRef.remove();

      // Update local state
      setFolders((prevFolders) => {
        const updatedFolder = { ...prevFolders[folder.id] };
        if (updatedFolder.notes) {
          delete updatedFolder.notes[note.id];
        }
        return {
          ...prevFolders,
          [folder.id]: updatedFolder,
        };
      });

      toast("Note removed from folder successfully", {
        position: "top-right",
      });
    } catch (error) {
      console.error("Error removing note from folder:", error);
      toast("Error removing note from folder", {
        position: "top-right",
      });
    }
  };

  if (isMobile) {
    return (
      <MobileContainer>
        <Title variant="h1">
          noted<span style={{ color: COLORS.nicePink }}>.</span>fm
        </Title>
        <Subtitle variant="h2">
          Glad to see you here! We're working on mobile version.
        </Subtitle>
        <Subtitle variant="h2">
          Should be out soon and we'll notify you, until then please use on
          desktop.
        </Subtitle>
      </MobileContainer>
    );
  }

  if (isLoading) {
    return (
      <Box sx={styles.loadingContainer}>
        <CircularProgress sx={{ color: COLORS.nicePink }} />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {showDemoVideoModal && (
        <DemoVideoModal
          open={showDemoVideoModal}
          onClose={handleCloseDemoVideoModal}
        />
      )}
      {globalState?.showInvitePeopleModal && <InvitePeopleModal />}
      {globalState?.showHowItWorksModal && <HowItWorksModal />}
      {globalState?.showCancellationModal && <CancellationModal />}
      {globalState?.showOnboardingModal && <OnboardingModal />}
      {globalState?.showSignupModal && <SignupModal />}
      <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
        <LeftSidebar
          user={user}
          expandedRightSidebar={expandedRightSidebar}
          setExpandedRightSidebar={setExpandedRightSidebar}
          expanded={expanded}
          setShowProfile={setShowProfile}
          handleLogout={handleLogout}
          setShowOnboardingModal={setShowOnboardingModal}
          showProfile={showProfile}
          setExpanded={setExpanded}
        />

        <FileExplorer
          user={user}
          setUser={setUser}
          files={files}
          showFolderSettingsModal={showFolderSettingsModal}
          setShowFolderSettingsModal={setShowFolderSettingsModal}
          showMindMapModal={showMindMapModal}
          handleDeleteFolder={handleDeleteFolder}
          setShowMindMapModal={setShowMindMapModal}
          isMindMapLoading={isMindMapLoading}
          setIsMindMapLoading={setIsMindMapLoading}
          mindMapProgress={mindMapProgress}
          setMindMapProgress={setMindMapProgress}
          activeFolder={activeFolder}
          updateFolder={updateFolderPublicStatus}
          setActiveFolder={setActiveFolder}
          handleRemoveNoteFromFolder={(folder, note) =>
            handleRemoveNoteFromFolder(folder, note)
          }
          currentState={currentState}
          folders={folders || []}
          setFolders={setFolders}
          folderNameRef={folderNameRef}
          handleCreateFolder={handleCreateFolder}
          setActiveFile={handleFileClick}
          activeFile={activeFile}
          setShowShareMindmapModal={setShowShareMindmapModal}
          showShareMindmapModal={showShareMindmapModal}
          expanded={expanded}
          setExpanded={setExpanded}
          handlePinNote={handlePinNote}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          setFiles={setFiles}
          handleTabChange={handleTabChange}
          showFolderModal={showFolderModal}
          setShowFolderModal={setShowFolderModal}
          folderName={folderName}
          setFolderName={setFolderName}
          showFolderOptionsModal={showFolderOptionsModal}
          setShowFolderOptionsModal={handleSetShowFolderOptionsModal}
          handleSelectFolder={handleSelectFolder}
          handleDeleteNote={handleDeleteNote}
        />

        <MainContent
          user={user}
          activeFile={activeFile}
          folders={folders}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          setActiveRightSidebar={setActiveRightSidebar}
          chatViewRef={chatViewRef}
          setActiveFile={setActiveFile}
          openFiles={currentState?.openFiles}
          handleGenerateSmartLinks={handleGenerateSmartLinks}
          activeNoteId={currentState?.activeNoteId}
          onOpenNote={openNote}
          onCloseNote={closeNote}
          onUpdateFile={updateNoteContent}
          onGoBack={goBack}
          onGoForward={goForward}
          onTabChange={handleTabChange}
          allNotes={files}
          setAllNotes={setFiles}
          setOpenFiles={setOpenFiles}
          handleDeleteNote={handleDeleteNote}
          handleSelectFolder={handleSelectFolder}
          currentState={currentState}
          setCurrentState={setCurrentState}
        />

        <RightSidebar
          user={user}
          activeNoteId={currentState?.activeNoteId}
          setExpanded={setExpanded}
          activeFolder={activeFolder}
          nodePositions={activeFolder?.nodePositions || {}}
          setExpandedRightSidebar={setExpandedRightSidebar}
          expandedRightSidebar={expandedRightSidebar}
          files={files}
          chatViewRef={chatViewRef}
          currentFile={activeFile}
          setActiveRightSidebar={handleSetActiveRightSidebar}
          activeRightSidebar={activeRightSidebar}
          notesVersion={notesVersion}
          onNodeClick={handleNodeClick}
        />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: COLORS.niceDeepBlack,
    transition: "background-color 0.3s ease",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: COLORS.niceDeepBlack,
  },
  content: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    transition: "background-color 0.3s ease",
  },
  mainArea: {
    // flex: 1,
    height: "95vh",
    backgroundColor: "transparent",
    borderRadius: STANDARD_BORDER_RADIUS,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
  },
  sidebar: {
    width: 240,
    height: "95vh",
    backgroundColor: "#FFFFFF", // Pure white for sidebar
    borderRadius: 8,
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
};

export default HomePage;
