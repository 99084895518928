import React, { useRef, useEffect } from 'react';
import { Modal, Box, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import { COLORS } from '../utils/colors';
import RedClose from './RedClose';

const DemoVideoModal = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', () => {
        // Set the current time to 1 second (or any desired time)
        videoRef.current.currentTime = 1;
      });
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(10px)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#1E1E1E',
          borderRadius: '16px',
          width: isMobile ? '90%' : '900px',
          maxWidth: '90%',
          maxHeight: '90%',
          padding: '32px',
          boxShadow: '0 4px 30px rgba(0,0,0,0.3)',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '4px',
            background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <RedClose handleClose={onClose} />
        </Box>

        <Typography
          variant="h5"
          sx={{ mb: 3, fontWeight: 700, textAlign: 'center', color: '#FFFFFF' }}
        >
          Watch demo video 
        </Typography>

        <Box sx={{ width: '100%', mb: 2, overflow: 'hidden', borderRadius: '8px' }}>
          <video
            ref={videoRef}
            width="100%"
            controls
            preload="metadata"
            style={{ display: 'block' }}
          >
            <source src="https://notedfm.s3.us-east-005.backblazeb2.com/notd_demo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={onClose}
            sx={{
              mt: 2,
              bgcolor: COLORS.niceSyncingGreen,
              color: 'black',
              fontWeight:'bold',
              px: 2,
              '&:hover': {
                bgcolor: COLORS.niceSyncingGreen,
              },
            }}
          >
            CLOSE
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DemoVideoModal;