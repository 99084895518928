import React, { useContext, useState } from "react";
import {
  Typography,
  Modal,
  Box,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/colors";
import RedClose from "./RedClose";
import { firebase } from "../firebaseConfig"; // Import firebase from your config file
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import { useLocation } from "react-router-dom";
import { handleSubmit } from "../utils/helperMethods";
import GoogleSignInButton from "./GoogleSignInButton";

const SignupModal = ({ setUser, user }) => {
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();
    handleSubmit(
      event,
      email,
      setLoading,
      setGlobalState,
      globalState,
      location,
      toast
    );
  };

  return (
    <Modal
      open={globalState?.showSignupModal}
      onClose={() => setGlobalState({ ...globalState, showSignupModal: false })}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#1E1E1E",
          borderRadius: "16px",
          width: isMobile ? "90%" : "400px",
          padding: "32px",
          boxShadow: "0 4px 30px rgba(0,0,0,0.3)",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <RedClose
            handleClose={() =>
              setGlobalState({ ...globalState, showSignupModal: false })
            }
          />
        </Box>

        <Typography
          variant="h4"
          sx={{ mb: 3, fontWeight: 700, textAlign: "center", color: "#FFFFFF" }}
        >
          Let's get started
        </Typography>

        <Typography
          variant="body1"
          sx={{ textAlign: "center", color: "#BBBBBB", my: 0.5 }}
        >
          Login or Create an account
        </Typography>

        <form onSubmit={handleClick}>
          <TextField
            label="Email"
            autoComplete="off"
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "rgba(255, 255, 255, 0.05)",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.1)",
              },
              "& .MuiInputLabel-root": {
                color: "#BBBBBB",
              },
              "& .MuiOutlinedInput-input": {
                color: "#FFFFFF",
              },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{
              marginTop: "1rem",
              fontWeight: "bold",
              color: "#FFFFFF",
              py: 1.5,
              borderRadius: "8px",
              transition: "all 0.3s ease",
              background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
              "&:hover": {
                background: `linear-gradient(45deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                transform: "translateY(-2px)",
                boxShadow: "0 4px 20px rgba(78, 205, 196, 0.4)",
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "GET STARTED"
            )}
          </Button>
        </form>

        {/* <GoogleSignInButton
          setUser={setUser}
          user={user}
          fullWidth={true}
          onClose={() => setGlobalState({ showSignupModal: false })}
        /> */}
        <GoogleSignInButton
          setUser={setUser}
          user={user}
          fullWidth={true}
          onClose={() => setGlobalState({ showSignupModal: false })}
          currentLocation={location.pathname}
        />

        <Typography
          variant="body2"
          sx={{ mt: 3, textAlign: "center", color: "#888888" }}
        >
          By signing up, you agree to our Terms of Service and Privacy Policy.
        </Typography>
      </Box>
    </Modal>
  );
};

export default SignupModal;
