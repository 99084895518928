import { useState } from "react";
import { Tooltip, Zoom } from "@mui/material";

const CustomTooltip = ({ title, children, paddingAmt }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <Tooltip
        title={title}
        placement="right"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 200 }}
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'black',
              color: 'white',
              fontSize: '0.875rem',
              fontWeight: "bold",
              padding: paddingAmt || "10px",
              borderRadius: '4px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            },
          },
        }}
      >
        {children}
      </Tooltip>
    );
  };

export default CustomTooltip;
