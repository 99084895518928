
import { createContext, useState, useEffect } from "react";
import { firebase, firestore } from "../firebaseConfig";
import toast from "react-simple-toasts";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await firestore.collection("users").doc(user.uid).get();
        setUser(userDoc);
      } else {
        // setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
