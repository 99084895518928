import React, { useRef } from "react";
import {
  Typography,
  Modal,
  Box,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { COLORS } from "../utils/colors";
import RedClose from "./RedClose";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css"; // choose your theme
import { useNavigate } from "react-router-dom";

const MindMapModal = ({
  user,
  open,
  onClose,
  onSubmit,
  isLoading,
  progress,
  mapName,
  setMapName,
  description,
  setDescription,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const mapNameRef = useRef("");
  const descriptionRef = useRef("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // if user is not premiumUser navigate to /pricing
    if (!user?.premiumUser || !user?.premiumUser?.isActive) {
      toast("Become a member to use this feature", {
        position: "top-right",
      });
      navigate("/pricing");
      return
    }
    onSubmit(e, {
      mapName: mapNameRef.current.value,
      description: descriptionRef.current.value,
    });
  };

  return (
    <Modal
      open={open}
      onClose={isLoading ? null : onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#1E1E1E",
          borderRadius: "16px",
          width: isMobile ? "90%" : "400px",
          padding: "32px",
          boxShadow: "0 4px 30px rgba(0,0,0,0.3)",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
          },
        }}
      >
        {!isLoading && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <RedClose handleClose={onClose} />
          </Box>
        )}

        <Typography
          variant="h5"
          sx={{ mb: 3, fontWeight: 700, textAlign: "center", color: "#FFFFFF" }}
        >
          Generate AI Mind Map
        </Typography>

        <form onSubmit={(e) => handleSubmit(e)}>
          <TextField
            label="Map Name:"
            autoComplete="off"
            inputRef={mapNameRef}
            required
            fullWidth
            sx={styles.textField}
          />

          <TextField
            label="What kind of mind map:"
            autoComplete="off"
            inputRef={descriptionRef}
            multiline
            required
            rows={4}
            fullWidth
            sx={{
              ...styles.textField,
              mt: 2,
            }}
          />

          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
              }}
            >
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ width: "100%", mb: 1 }}
              />
              <Typography variant="body2" color={COLORS.niceWhite}>
                {progress}% Complete
              </Typography>
            </Box>
          ) : (
            <Button
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                marginTop: "1rem",
                fontWeight: "bold",
                color: "#FFFFFF",
                py: 1.5,
                borderRadius: "8px",
                transition: "all 0.3s ease",
                background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                "&:hover": {
                  background: `linear-gradient(45deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                  transform: "translateY(-2px)",
                  boxShadow: "0 4px 20px rgba(78, 205, 196, 0.4)",
                },
              }}
            >
              Create Mind Map
            </Button>
          )}
        </form>
      </Box>
    </Modal>
  );
};

const styles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.1)",
    },
    "& .MuiInputLabel-root": {
      color: "#BBBBBB",
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
  },
};

export default MindMapModal;
