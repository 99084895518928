import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import axios from "axios"; // Make sure to install axios: npm install axios
import { functions } from "../firebaseConfig";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css";
import { httpsCallable } from "firebase/functions";
import JoinTheDiscord from "../components/JoinTheDiscord";
import GlobalStateContext from "../context/globalStateContext";
import {
  FAQuestions,
  PRICING_PLANS,
  quotes,
  YEARLY_PRICING_PLANS,
} from "../utils/constants";
import BillingToggle from "../components/BillingToggle";
import UserContext from "../context/userContext";
import { fetchAllAgents, fetchUserInfo } from "../utils/helperMethods";
import { COLORS } from "../utils/colors";
import Footer from "../components/Footer";
import styled, { keyframes, css } from "styled-components";
import Header from "../components/Header";
import FAQSection from "../components/FAQSection";
import { AnimatePresence, motion } from "framer-motion";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const GradientText = styled.span`
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: ${(props) => props.fontSize || "inherit"};
  margin-top: ${(props) => props.marginTop || "0"};
  opacity: 0.8;
    font-weight: ${(props) => props.fontWeight || "inherit"};
  text-align: "center"
  animation: ${fadeIn} 0.8s ease-out 0.4s both;
`;

const QuoteSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
  width: 100%;
  max-width: 800px;
`;

const QuoteContainer = styled(motion.div)`
  text-align: center;
`;

const QuoteText = styled(GradientText)`
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  color: ${COLORS.niceWhite};
`;

const QuoteAuthor = styled(Typography)`
  font-style: italic;
`;

const Pricing = () => {
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [intervalType, setIntervalType] = useState("monthly");
  const [loadingPlan, setLoadingPlan] = useState(null);
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [currentQuote, setCurrentQuote] = useState(0);

  // Change quote every 5 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentQuote((prev) => (prev + 1) % quotes.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const getUserProfile = async (userInfo) => {
    let userData = await fetchUserInfo(userInfo.id);

    setUser(userData);
  };

  useEffect(() => {
    if (user?.id && !user?.email) getUserProfile(user);
  }, [user]);

  // Function to create Stripe Checkout session
  const handleSubscription = async (plan) => {
    if (!user?.id) {
      toast("Please sign in to continue");
      return;
    }

    setLoadingPlan(plan.stripePriceId);

    
    // return
    try {
      const response = await axios.post(
        "https://us-central1-notedfm-f6490.cloudfunctions.net/createCheckoutSubscription",
        {
          priceId: plan.stripePriceId,
          userId: user.id,
          packageName: plan.title,
          interval: plan.interval,
        }
      );

      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
      }
      toast(
        "An error occurred while creating the checkout session. Please try again."
      );
    } finally {
      setLoadingPlan(null);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const renderPricingCard = (plan, index, isYearly) => {
    const displayPrice = isYearly ? (plan.price / 12).toFixed(2) : plan.price;
    const isLoading = loadingPlan === plan.stripePriceId;

    return (
      <Box key={index} sx={index === 2 ? styles.enterpriseCard : styles.card}>
        <Box sx={{ height: "90%" }}>
          <Typography sx={{ ...styles.cardTitle, color: COLORS.white }}>
            {plan.displayName}
          </Typography>
          <Typography
            sx={{
              ...styles.cardSubtitle,
              color:
                plan?.title === "Pro" ? COLORS.niceWhite : COLORS.lightGrey,
            }}
          >
            {plan.subtitle}
          </Typography>
          <Box sx={styles.priceContainer}>
            <Typography sx={styles.price}>${displayPrice}</Typography>
            <Typography sx={styles.period}>/ mo</Typography>
          </Box>
          {isYearly && (
            <Typography
              sx={{
                ...styles.description,
                color: index === 2 ? "white" : "black",
              }}
            >
              Billed annually as ${plan.price}
            </Typography>
          )}
          {isYearly && (
            <Typography sx={{ ...styles.savings, color: "green", pb: 2 }}>
              Save ${(displayPrice * 3).toFixed(2)} annually
            </Typography>
          )}
          {plan.features.map((feature, i) => (
            <Typography key={i} sx={styles.feature}>
              {feature}
            </Typography>
          ))}
        </Box>
        <Box sx={{ height: "10%" }}>
          <Button
            disabled={
              isLoading || user?.premiumUser?.packageName === plan.title
            }
            onClick={() => handleSubscription(plan)}
            variant={index === 2 ? "contained" : "outlined"}
            fullWidth
            sx={{
              ...styles.enterpriseButton,
              ":disabled": {
                color: "white",
              },
              backgroundColor:
                user?.premiumUser?.packageName === plan.title
                  ? COLORS.lightGrey
                  : "#007bff",
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : user?.premiumUser?.packageName === plan.title ? (
              "Current Plan"
            ) : (
              "SELECT"
            )}
          </Button>
        </Box>

        {plan.popular && <Box sx={styles.popularTag}>Most Popular</Box>}
      </Box>
    );
  };

  return (
    <Box sx={styles.container}>
      <Header
        user={user}
        globalState={globalState}
        setGlobalState={setGlobalState}
      />
      <Box sx={styles.content}>
        {/* <Typography variant="h3" sx={styles.title}> */}
        <GradientText marginBottom="1rem" marginTop="0">
          Pricing
        </GradientText>
        {/* </Typography> */}
        <Typography variant="h6" sx={styles.subtitle}>
          Unlock your potential
        </Typography>

        <Box sx={styles.planSection}>
          <Box sx={styles.choosePlan}>
            <Typography variant="h6" sx={styles.choosePlanTitle}>
              Choose plan
            </Typography>
            <BillingToggle
              intervalType={intervalType}
              setIntervalType={setIntervalType}
            />
          </Box>

          <Box sx={styles.pricingCards}>
            {(intervalType === "monthly"
              ? PRICING_PLANS
              : YEARLY_PRICING_PLANS
            ).map((plan, index) =>
              renderPricingCard(plan, index, intervalType === "yearly")
            )}
          </Box>
        </Box>
      </Box>

       {/* New Enterprise Plan Section */}
       <Box sx={styles.enterpriseSection}>
          <Typography variant="h6" sx={styles.enterpriseTitle}>
            Interested in Enterprise (per-user) plans?
          </Typography>
          <Button
            variant="contained"
            href="https://calendly.com/hello-noted/30min"
            target="_blank"
            rel="noopener noreferrer"
            sx={styles.scheduleButton}
          >
            Schedule a call
          </Button>
        </Box>

      {/* New Community Join Section */}
      {/* <JoinTheDiscord /> */}

      {/* TESTIMONIALS  */}
      <QuoteSection>
        <AnimatePresence mode="wait">
          <QuoteContainer
            key={currentQuote}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <QuoteText>
              {<GradientText>{quotes[currentQuote].text}</GradientText>}
            </QuoteText>
            <QuoteAuthor variant="h6" color={COLORS.niceWhite}>
              <GradientText>{quotes[currentQuote].author}</GradientText>
            </QuoteAuthor>
          </QuoteContainer>
        </AnimatePresence>
      </QuoteSection>

      {/* FAQ  */}
      <FAQSection
        FAQuestions={FAQuestions}
        expandedPanel={expandedPanel}
        handleAccordionChange={handleAccordionChange}
      />
      <Footer />
    </Box>
  );
};

const styles = {
  container: {
    height: "100%",
    bgcolor: COLORS.niceDeepBlack,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  content: {
    width: "80%",
    height: "100%",
    bgcolor: "transparent",
    borderRadius: 4,
    pt: "10%",
    px: "10%",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    mb: 1,
    fontSize: "3.5rem",
    color: COLORS.niceWhite,
  },
  subtitle: {
    textAlign: "center",
    mb: 12,
    color: COLORS.lightGrey,
    fontWeight: "normal",
    fontSize: "1.5rem",
  },
  choosePlanTitle: {
    fontWeight: "bold",
    mb: 2,
    color: COLORS.niceWhite,
  },
enterpriseSection: {
    textAlign: 'center',
    mt: 4,
    mb: 8,
  },
  enterpriseTitle: {
    color: COLORS.niceWhite,
    fontWeight: 'bold',
    mb: 2,
  },
  scheduleButton: {
    backgroundColor: COLORS.niceGreen,
    color: COLORS.niceWhite,
    '&:hover': {
      backgroundColor: COLORS.nicePink,
    },
  },
  communitySection: {
    textAlign: "center",
    my: 4,
  },
  communityTitle: {
    color: COLORS.niceWhite,
    fontWeight: "bold",
    mb: 2,
  },
  communityDescription: {
    color: COLORS.lightGrey,
    mb: 1,
  },
  membershipRequired: {
    color: COLORS.nicePink,
    fontWeight: "bold",
  },

  planSection: {
    display: "flex",
    mb: 4,
  },
  choosePlan: {
    mr: 4,
  },

  billingOptions: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
  },
  billingOption: {
    display: "flex",
    alignItems: "center",
  },
  pricingCards: {
    display: "flex",
    gap: 3,
    flex: 3,
  },
  card: {
    flex: 1,
    bgcolor: "white",
    p: 4,
    borderRadius: 2,
    border: "1px solid #E9ECEF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  },
  enterpriseCard: {
    flex: 1,
    bgcolor: "#212529",
    color: "white",
    p: 4,
    borderRadius: 2,
    position: "relative",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  cardTitle: {
    fontWeight: "bold",
    mb: 1,
    fontSize: "1.25rem",
  },
  cardSubtitle: {
    mb: 2,
    color: "text.secondary",
    fontSize: "0.875rem",
  },
  priceContainer: {
    display: "flex",
    alignItems: "baseline",
    mb: 1,
  },
  price: {
    fontWeight: "bold",
    fontSize: "2rem",
  },
  period: {
    ml: 0.5,
    fontSize: "0.875rem",
  },
  description: {
    mb: 2,
    color: "text.secondary",
    fontSize: "0.875rem",
  },
  feature: {
    display: "flex",
    alignItems: "center",
    mb: 1,
    fontSize: "0.875rem",
    "&::before": {
      content: '""',
      width: 6,
      height: 6,
      borderRadius: "50%",
      bgcolor: "grey.400",
      mr: 1,
      display: "inline-block",
    },
  },
  button: {
    mt: 2,
    color: "primary.main",
    borderColor: "primary.main",
    "&:hover": {
      bgcolor: "transparent",
    },
  },
  enterpriseButton: {
    mt: 2,
    color: "white",
    bgcolor: "#007bff",
    "&:hover": {
      bgcolor: "#0056b3",
    },
  },
  popularTag: {
    position: "absolute",
    top: 12,
    right: 12,
    bgcolor: "#FF00FF",
    color: "white",
    px: 1,
    py: 0.5,
    borderRadius: 1,
    fontSize: 12,
  },
};

export default Pricing;
