import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useEditor, EditorContent, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TableChartIcon from "@mui/icons-material/TableChart";
import { COLORS } from "../utils/colors";
import { keyframes } from "@emotion/react";
import Placeholder from "@tiptap/extension-placeholder";
import { Extension, Node, InputRule } from "@tiptap/core";
import Youtube from "@tiptap/extension-youtube";
import Link from "@tiptap/extension-link";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import { TextField, Popover, InputAdornment } from "@mui/material";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import LinkIcon from "@mui/icons-material/Link";
import CodeIcon from "@mui/icons-material/Code";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import AddIcon from "@mui/icons-material/Add";
import { TextSelection } from "@tiptap/pm/state";
import EditorMetrics from "./EditorMetrics";
import { v4 as uuidv4 } from "uuid";
import debounce from "lodash/debounce";
import Image from "@tiptap/extension-image";
import { sanitizeTitle, uploadImage } from "../utils/helperMethods";
import SearchIcon from "@mui/icons-material/Search";
import AutoLinkIcon from "@mui/icons-material/AutoAwesome";
import {
  ArrowBack,
  ArrowForward,
  Delete,
  DeleteOutline,
  Info,
  LinkOffOutlined,
} from "@mui/icons-material";
import CustomTooltip from "./CustomTooltip";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import TextAlign from "@tiptap/extension-text-align";
import { alpha } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import toast from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css";
import { formatTitle } from "../utils/helperMethods";
import SelectFolderModal from "./SelectFolderModal";
import SmartLinkButton from "./SmartLinkButton";
import AudioPlayerModal from "./AudioPlayerModal";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { lowlight } from "lowlight";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

// Define a balanced pop animation
const popAnimation = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  70% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: COLORS.niceDeepBlack,
    border: `1px solid ${COLORS.nicePurple}40`,
    borderRadius: "8px",
    overflow: "hidden",
  },
}));

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: COLORS.niceSuperLightGrey,
    backgroundColor: `${COLORS.nicePurple}10`,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: `${COLORS.nicePurple}20`,
    },
  },
  "& .MuiInputBase-input": {
    padding: "8px 12px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const SubmitButton = styled(IconButton)({
  color: COLORS.nicePink,
  "&:hover": {
    backgroundColor: `${COLORS.nicePink}20`,
  },
});

const LinkInputWrapper = styled(Box)({
  padding: "12px",
  display: "flex",
  alignItems: "center",
  background: `linear-gradient(45deg, ${COLORS.nicePurple}10, ${COLORS.nicePink}10)`,
});

const ScrollableEditor = styled.div`
  flex-grow: 1;
  flex-direction: column;
  height: 100%; // Take full height of parent
  overflow-y: auto;
  padding: 20px;

  /* Always show scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;

  /* Preserve existing text styling */
  & .tiptap-editor {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 100px; // Minimum height for the editor

    & table {
      border-collapse: collapse;
      margin: 0;
      overflow: hidden;
      table-layout: fixed;
      width: 100%;
    }

    & table td,
    & table th {
      border: 2px solid ${COLORS.niceSuperLightGrey};
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;
    }

    & table th {
      background-color: ${COLORS.niceDeepBlack};
      font-weight: bold;
      text-align: left;
    }

    & table .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    color: ${COLORS.niceSuperLightGrey};
    & a {
      color: ${(props) => props.file?.color || COLORS.niceSuperLightGrey};
      text-decoration: none;
      background-color: ${(props) =>
        `${props.file?.color || COLORS.niceBlue}20`};
      padding: 2px 4px;
      border-radius: 4px;
      transition: all 0.2s ease;

      &:hover {
        color: ${COLORS.nicePurple};
        background-color: ${COLORS.nicePurple}20;
      }
    }
    ${(props) => props.onClick && `cursor: text;`}

    font-size: 15px; // MAIN TEXT EDITOR SIZE
    & .is-empty::before {
      color: rgba(255, 255, 255, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
    & .ProseMirror {
      & > h1:first-child {
        font-size: 80px;
        font-weight: bold;
        margin-bottom: 24px;
        color: ${COLORS.nicePurple};
        border-bottom: 2px solid ${COLORS.nicePink};
        padding-bottom: 12px;
      }
    }

    & pre {
      background-color: ${COLORS.niceBlack};
      border-radius: 8px;
      padding: 16px;
      margin: 16px 0;
      overflow: auto;
      font-family: "Fira Code", monospace;
      font-size: 14px;
      line-height: 1.5;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border: 1px solid ${COLORS.nicePurple}40;
    }

    & code {
      background-color: ${COLORS.niceDeepBlack};
      color: ${COLORS.niceWhite};
      padding: 2px 4px;
      border-radius: 4px;
      font-family: "Fira Code", monospace;
      font-size: 0.9em;
    }
    &:focus {
      outline: none;
    }
    & strong {
      color: ${(props) => props.file?.color || COLORS.nicePink};
      font-weight: 700;
    }

    & ul[data-type="taskList"] {
      list-style-type: none;
      padding: 0;

      & li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.5em;

        & > label {
          margin-right: 0.5em;
          user-select: none;
        }

        & > div {
          flex: 1;
          > p {
            margin: 0;
          }
        }
      }
    }
  }
`;

const StyledTab = styled(Box)(({ theme, active, color }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "8px 10px",
  background: COLORS.niceDeepBlack,
  margin: "0 6px", // Add margin on both sides of each tab
  borderRadius: "4px 4px 0 0",
  cursor: "pointer",
  color: active ? COLORS.nicePink : COLORS.niceSuperLightGrey,
  borderBottom: active ? `2px solid ${color || COLORS.nicePink}` : "none",
  width: "150px",
  "&:hover": {
    color: COLORS.nicePink,
    background: `${COLORS.nicePink}10`,
  },
  flexShrink: 0,
  transition: "all 0.2s ease",
}));

const TabTitle = styled("span")(({ active, color, unsaved }) => ({
  fontSize: "14px",
  fontWeight: 500,
  maxWidth: "120px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: color || COLORS.niceSuperLightGrey,
}));

const CloseButton = styled(IconButton)(({ active, color }) => ({
  padding: 2,
  marginLeft: "8px",
  color: color || COLORS.niceSuperLightGrey,
  opacity: 0.6,
  transition: "all 0.2s ease",
  "&:hover": {
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

const TabsContainer = styled(Box)({
  display: "flex",
  overflowX: "auto",
  overflowY: "hidden",
  whiteSpace: "nowrap",
  width: "100%",
  padding: "8px 0px 0", // Reduced horizontal padding to compensate for tab margins
  "&::-webkit-scrollbar": {
    height: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: `${COLORS.nicePurple}40`,
    borderRadius: "2px",
  },
  backgroundColor: "#2a2a2a",
  borderBottom: `1px solid ${COLORS.nicePurple}20`,
});

const ForceH1 = Node.create({
  name: "forceH1",
  priority: 1000,
  addGlobalAttributes() {
    return [
      {
        types: ["heading"],
        attributes: {
          level: {
            default: 1,
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setFirstNodeToH1:
        () =>
        ({ tr, dispatch }) => {
          const firstNode = tr.doc.firstChild;
          if (firstNode && firstNode.type.name !== "heading") {
            if (dispatch) {
              const headingType = this.type.schema.nodes.heading;
              const headingAttrs = { level: 1 };

              // Create a new heading node with the content of the first node
              const newHeading = headingType.create(
                headingAttrs,
                firstNode.content
              );

              // Replace the first node with the new heading
              tr.replaceWith(0, 1, newHeading);
            }
            return true;
          }
          return false;
        },
    };
  },
});

const EditorTabs = ({
  openFiles,
  activeNoteId,
  handleTabChange,
  onCloseTab,
}) => {
  if (openFiles && openFiles?.length === 0) return null;

  return (
    <TabsContainer>
      {openFiles &&
        openFiles.map((file) => (
          <StyledTab
            key={file?.id}
            color={file?.color}
            active={file?.id === activeNoteId}
            onClick={() => handleTabChange(file.id, null, true)}
          >
            <TabTitle color={file.color} unsaved={file.unsaved}>
              {formatTitle(file.title)}
            </TabTitle>
            <CloseButton
              color={COLORS.niceSuperLightGrey}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                // if its the only one open, dont close it
                if (openFiles.length === 1) return;
                onCloseTab(file.id);
              }}
            >
              <CloseIcon fontSize="small" />
            </CloseButton>
          </StyledTab>
        ))}
    </TabsContainer>
  );
};

const LinkDropdown = ({
  showLinkDropdown,
  linkDropdownAnchorEl,
  filteredNotes,
  handleNoteSelection,
  allNotes,
  setShowLinkDropdown,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const listRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);

  const searchedNotes = useMemo(() => {
    return filteredNotes.filter((note) =>
      note.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [filteredNotes, searchTerm]);

  useEffect(() => {
    if (showLinkDropdown && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [showLinkDropdown]);

  const handleInputClick = (e) => {
    e.stopPropagation();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!showLinkDropdown) return;

      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) => (prevIndex + 1) % filteredNotes.length
          );
          break;
        case "ArrowUp":
          e.preventDefault();
          setSelectedIndex(
            (prevIndex) =>
              (prevIndex - 1 + filteredNotes.length) % filteredNotes.length
          );
          break;
        case "Enter":
          e.preventDefault();
          handleNoteSelection(filteredNotes[selectedIndex]);
          break;
        case "Escape":
          e.preventDefault();
          setShowLinkDropdown(false);
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [
    showLinkDropdown,
    filteredNotes,
    selectedIndex,
    handleNoteSelection,
    setShowLinkDropdown,
  ]);

  useEffect(() => {
    if (listRef.current) {
      const selectedElement = listRef.current.children[selectedIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: "nearest" });
      }
    }
  }, [selectedIndex]);

  return (
    <StyledPopover
      open={showLinkDropdown}
      anchorReference="anchorPosition"
      anchorPosition={linkDropdownAnchorEl}
      onClose={() => {
        setShowLinkDropdown(false);
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          backgroundColor: alpha(COLORS.niceDeepBlack, 0.95),
          borderRadius: "8px",
          width: "350px",
          overflow: "hidden",
          boxShadow: `0 4px 20px rgba(0, 0, 0, 0.3)`,
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: "8px",
            padding: "1px",
            background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
            mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            maskComposite: "exclude",
            pointerEvents: "none",
          },
        },
      }}
    >
      <StyledTextField
        fullWidth
        size="small"
        autoComplete="off"
        placeholder="Search notes..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        onClick={handleInputClick}
        onFocus={() => null}
        onBlur={() => null}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: COLORS.niceSuperLightGrey }} />
            </InputAdornment>
          ),
        }}
        inputRef={inputRef}
      />
      <List
        ref={listRef}
        sx={{
          maxHeight: "200px",
          overflowY: "auto",
          padding: "8px 0",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: alpha(COLORS.nicePurple, 0.5),
            borderRadius: "2px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: COLORS.nicePurple,
          },
        }}
      >
        {searchedNotes &&
          searchedNotes.map((note, index) => (
            <ListItem
              key={note.id}
              button
              selected={index === selectedIndex}
              onClick={() => handleNoteSelection(note)}
              sx={{
                padding: "4px 16px",
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor: alpha(COLORS.niceBlack, 0.1),
                  color: COLORS.niceBlack,
                  "& .MuiListItemText-primary": {
                    color: COLORS.niceSuperLightGrey,
                    fontWeight: 500,
                    fontSize: "14px",
                  },
                },
                "&.Mui-selected": {
                  backgroundColor: alpha(COLORS.niceBlack, 0.2),
                  color: note.color || COLORS.niceBlue,
                  "& .MuiListItemText-primary": {
                    color: note.color || COLORS.niceBlue,
                    fontWeight: 500,
                    fontSize: "14px",
                  },
                },
                "&.Mui-selected:hover": {
                  backgroundColor: alpha(COLORS.niceBlack, 0.3),
                  color: note.color || COLORS.niceBlue,
                  "& .MuiListItemText-primary": {
                    color: note.color || COLORS.niceBlue,
                    fontWeight: 500,
                    fontSize: "14px",
                  },
                },
              }}
            >
              <ListItemText
                primary={formatTitle(note.title)}
                primaryTypographyProps={{
                  noWrap: true,
                  sx: {
                    color: COLORS.niceSuperLightGrey,
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                }}
              />
            </ListItem>
          ))}
      </List>
    </StyledPopover>
  );
};

const ResourceLinks = ({
  referencingNotes,
  onNoteClick,
  handleUnlinkNote,
  setActiveFile,
  activeFile,
  onTabChange,
  openFiles,
  user,
  setOpenFiles,
  handleGenerateSmartLinks,
}) => {
  const handleNoteClick = (note) => {
    const isNoteOpen = openFiles.some((file) => file.id === note.id);

    if (!isNoteOpen) {
      setOpenFiles((prevOpenFiles) => [...prevOpenFiles, note]);
    }

    setActiveFile(note);
    onTabChange(note.id, note);
  };

  return (
    <Box sx={styles.resourceLinks}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={styles.resourceLinksHeader}>
            Referencing notes
          </Typography>
          <CustomTooltip title="Notes linking to this note" placement="top">
            <Info
              sx={{
                color: COLORS.niceSuperLightGrey,
                fontSize: "12px",
                marginLeft: "4px",
              }}
            />
          </CustomTooltip>
        </Box>

        <SmartLinkButton
          user={user}
          note={activeFile}
          handleGenerateSmartLinks={handleGenerateSmartLinks}
        />
      </Box>
      {referencingNotes.length === 0 ? (
        <Typography sx={styles.noLinksFound}>No referring notes</Typography>
      ) : (
        <Box sx={styles.linkContainer}>
          {referencingNotes.map((note) => (
            <Box
              key={note.id}
              component="span"
              onClick={() => handleNoteClick(note)}
              sx={{
                ...styles.resourceLink(note.color),
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">{note.title}</Typography>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleUnlinkNote(note);
                }}
                sx={{ p: 0, m: 0, color: COLORS.niceSuperLightGrey }}
              >
                <LinkOffOutlined fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const saveDebounced = debounce((onUpdateFile, noteId, updatedNote) => {
  onUpdateFile(noteId, updatedNote);
}, 2500);

const CodeBlockIndent = Extension.create({
  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        if (editor.isActive("codeBlock")) {
          editor.commands.insertContent("  "); // Insert two spaces for indentation
          return true;
        }
        return false;
      },
      "Shift-Tab": ({ editor }) => {
        if (editor.isActive("codeBlock")) {
          // Remove indentation (two spaces) if present at the start of the line
          const { from, to } = editor.state.selection;
          const text = editor.state.doc.textBetween(from, to);
          if (text.startsWith("  ")) {
            editor.commands.deleteRange({ from: from - 2, to: from });
            return true;
          }
        }
        return false;
      },
    };
  },
});

const customHorizontalRuleInputRule = new InputRule({
  find: /-{3,}$/, // Matches 3 or more hyphens at the end of the input
  handler: ({ state, range, match }) => {
    const { tr } = state;
    const start = range.from;
    const end = range.to;

    if (match[0].length >= 10) {
      // Only trigger for 10 or more hyphens
      tr.delete(start, end).replaceSelectionWith(
        state.schema.nodes.horizontalRule.create()
      );
      return tr;
    }
    return null;
  },
});

const MainContent = ({
  user,
  setActiveFile,
  activeFile,
  openFiles,
  setActiveRightSidebar,
  chatViewRef,
  activeNoteId,
  folders,
  onUpdateFile,
  onTabChange,
  handleGenerateSmartLinks,
  onCloseNote,
  onGoBack,
  handleDeleteNote,
  onGoForward,
  handleSelectFolder,
  allNotes,
  setAllNotes,
  setOpenFiles,
  isEditMode,
  setIsEditMode,
  currentState,
  setCurrentState,
}) => {
  const [content, setContent] = useState(activeFile?.content || null);
  const [showLinkDropdown, setShowLinkDropdown] = useState(false);
  const [linkDropdownAnchorEl, setLinkDropdownAnchorEl] = useState(null);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false); // Add this state
  const [tempContent, setTempContent] = useState("");
  const noteCreated = useRef(false);
  const debounceTimer = useRef(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [showNoteOptionsModal, setShowNoteOptionsModal] = useState(false);
  const activeFileRef = useRef(null);
  const editorRef = useRef(null);
  const [confirmUnlinkOpen, setConfirmUnlinkOpen] = useState(false);
  const [noteToUnlink, setNoteToUnlink] = useState(null);
  const [audioModalOpen, setAudioModalOpen] = useState(false);
  const [currentAudioUrl, setCurrentAudioUrl] = useState(null);

  useEffect(() => {
    if (allNotes) {
      setFilteredNotes(allNotes.filter((note) => note.id !== activeFile.id));
      if (editorRef.current) {
        checkForWikiLink(editorRef.current);
      }
    }
  }, [allNotes]);

  useEffect(() => {
    activeFileRef.current = activeFile;
  }, [activeFile]);

  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      .custom-horizontal-rule {
        border: none !important;
        height: 2px !important;
        background-color: ${COLORS.niceSuperLightGrey} !important;
        margin: 1em 0 !important;
        width: 100% !important;
        opacity: 0.5;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: {
          HTMLAttributes: {
            class: "custom-horizontal-rule",
          },
        },
      }),
      Underline,
      ForceH1,
      CodeBlockLowlight.configure({
        lowlight,
        HTMLAttributes: {
          class: "code-block-highlight",
        },
      }),
      {
        name: "customHorizontalRule",
        addInputRules() {
          return [customHorizontalRuleInputRule];
        },
      },
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableCell,
      TableHeader,
      CodeBlockIndent,
      Link.configure({
        openOnClick: false,
        autolink: true,
        linkOnPaste: true,
        HTMLAttributes: {
          class: "tiptap-link",
        },
        bubble: true,
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading" && node.attrs.level === 1) {
            return "Start writing...";
          }
          return "";
        },
        showOnlyCurrent: false,
        includeChildren: true,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      Youtube.configure({
        // Add this extension
        controls: false,
        nocookie: true,
        modestBranding: true,
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
    ],
    content: "<h1></h1>",
    editorProps: {
      attributes: {
        class: "tiptap-editor",
      },
      handlePaste: (view, event, slice) => {
        const text = event.clipboardData.getData("text/plain");

        const youtubeRegex =
          /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:.*[?&](?:t|start)=(\d+))?/;
        const match = text.match(youtubeRegex);

        if (match) {
          event.preventDefault();
          const videoId = match[1];
          const timestamp = match[2] ? parseInt(match[2]) : 0;
          const url = `https://www.youtube.com/embed/${videoId}?start=${timestamp}`;
          editor.commands.setYoutubeVideo({
            src: url,
            width: 640,
            height: 480,
          });
          return true;
        }

        // Youtube match above for inline playing
        const items = Array.from(event.clipboardData?.items || []);
        const imageItems = items.filter(
          (item) => item.type.indexOf("image") !== -1
        );
        if (imageItems.length > 0) {
          event.preventDefault();
          imageItems.forEach(async (item) => {
            const file = item.getAsFile();
            if (file) {
              try {
                const imageUrl = await uploadImage(file); // Using the uploadImage function from helperMethods.js
                editor.chain().focus().setImage({ src: imageUrl }).run();
              } catch (error) {
                console.error("Error uploading image:", error);
                toast("Error uploading image");
              }
            }
          });
          return true;
        }
        return false;
      },
      handleClick: (view, pos, event) => {
        const { state } = view;
        const { selection } = state;
        const { empty } = selection;

        if (empty) {
          view.focus();
          view.dispatch(
            state.tr.setSelection(TextSelection.create(state.doc, pos))
          );
        }
        return false;
      },
    },
    editorStyle: {
      height: "100%",
      color: COLORS.cursorShWhite,
      fontSize: "12px",
    },
    onUpdate: ({ editor }) => {
      editor.commands.setFirstNodeToH1();
      checkForWikiLink(editor);
    },

    editable: isEditMode,
  });

  useEffect(() => {
    if (editor) {
      editorRef.current = editor;
    }
  }, [editor]);

  // HOT KEY - CTRL + L
  const handleKeyDown = useCallback(
    (event) => {
      if (event.ctrlKey && event.key === "l") {
        event.preventDefault();
        setActiveRightSidebar("chat");
        chatViewRef.current?.focusEditor();
      }
    },
    [setActiveRightSidebar, chatViewRef]
  );
  // HOT KEY - CTRL + L
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const findReferencingNotes = useCallback((currentNote, allNotes) => {
    if (!currentNote || !currentNote.slug) return [];

    const searchPattern = `[[${currentNote.slug}]]`;

    return allNotes.filter(
      (note) =>
        note.id !== currentNote.id && // Exclude the current note
        note.content &&
        note.content.includes(searchPattern)
    );
  }, []);

  // Debounced save function
  const debouncedSave = useCallback(
    (note, content) => {
      setSaveLoading(true);
      const newTitle = editor.getText().split("\n")[0] || "Untitled";
      const sanitizedTitle = sanitizeTitle(newTitle);

      const updatedNote = {
        content,
        title: sanitizedTitle,
        ...note,
      };

      // Only update the slug if it doesn't exist and we have a meaningful title
      if (
        !activeFile.slug &&
        sanitizedTitle !== "Untitled" &&
        sanitizedTitle !== "Start writing..."
      ) {
        updatedNote.slug = sanitizedTitle.toLowerCase().replace(/\s+/g, "-");
        updatedNote.title = sanitizedTitle;
        updatedNote.content = content;
      } else if (activeFile?.slug && activeFile?.title) {
        // returning paragraph saving
        updatedNote.content = content;
      }

      saveDebounced(onUpdateFile, note?.id, updatedNote);
    },
    [onUpdateFile, editor, activeFile]
  );

  // Auto-save on content change
  useEffect(() => {
    if (editor && activeFile) {
      const handleUpdate = () => {
        const content = editor.getHTML();
        debouncedSave(activeFile, content);
      };
      setSaveLoading(true);

      editor.on("update", handleUpdate);
      setSaveLoading(false);

      return () => {
        editor.off("update", handleUpdate);
      };
    }
  }, [editor, activeFile, debouncedSave]);

  // Focus loss: Save when the user switches to a different window or tab
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && editor && activeFile) {
        setSaveLoading(true);
        const content = editor.getHTML();
        // TODO - bug here content.title needs to be present
        // onUpdateFile(activeFile.id, { content });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [editor, activeFile, onUpdateFile]);

  // App closing: Save when the user attempts to close the app
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (editor && activeFile) {
        setSaveLoading(true);
        const content = editor.getHTML();
        const title = editor.getText().split("\n")[0] || "Untitled Note"; // Extract title from the first line of text

        // TODO - bug here content.title needs to be present
        if (content?.title) onUpdateFile(activeFile.id, { content });
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [editor, activeFile, onUpdateFile]);

  // on mount - have cursor focus on editor
  useEffect(() => {
    if (editor) {
      editor.commands.focus("end");
    }
  }, [editor]);

  // Add this useEffect to reset saveLoading when saving is complete
  useEffect(() => {
    if (saveLoading) {
      const timer = setTimeout(() => {
        setSaveLoading(false);
      }, 1000); // Adjust this timeout as needed

      return () => clearTimeout(timer);
    }
  }, [saveLoading]);

  useEffect(() => {
    if (editor && activeFile) {
      editor.commands.setContent(activeFile.content || "<h1></h1>");
      setContent(activeFile.content || "<h1></h1>");
    }
  }, [activeFile, editor]);

  // Simulate syncing text
  useEffect(() => {
    const syncInterval = setInterval(() => {
      setIsSyncing(true);
    }, 1000);

    return () => clearInterval(syncInterval);
  }, []);

  const handleTabChange = (newActiveId, newNote, justTabChange) => {
    onTabChange(newActiveId, newNote, justTabChange); // Call the parent component's onTabChange function
  };

  const handleRenderedLinkClick = useCallback(
    (event) => {
      if (!isEditMode) {
        const target = event.target;
        if (target.tagName === "A") {
          event.preventDefault();
          const href = target.getAttribute("href");

          if (href.startsWith("[[") && href.endsWith("]]")) {
            // Handle wiki-style links
            const slug = href.slice(2, -2);

            const linkedNote = allNotes.find((note) => note.slug === slug);

            if (linkedNote) {
              // Check if the note is already open
              const isNoteOpen = openFiles.some(
                (file) => file.id === linkedNote.id
              );

              if (!isNoteOpen) {
                // If the note is not open, add it to openFiles
                setOpenFiles((prevOpenFiles) => [...prevOpenFiles, linkedNote]);
              }

              // Set the linked note as the active file
              setActiveFile(linkedNote);
              onTabChange(linkedNote.id, linkedNote);
            }
          } else {
            // Handle regular URLs
            let url = target.getAttribute("href"); // Get the original href attribute
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
              url = "https://" + url.replace(/^\/+/, "");
            }
            window.open(url, "_blank", "noopener,noreferrer");
          }
        }
      }
    },
    [allNotes, setActiveFile, isEditMode]
  );

  useEffect(() => {
    if (editor) {
      editor.setEditable(isEditMode);
    }
  }, [isEditMode, editor]);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const referencingNotes = useMemo(() => {
    if (!activeFile || !allNotes) return [];
    const refs = findReferencingNotes(activeFile, allNotes);

    return refs;
  }, [activeFile, allNotes, findReferencingNotes]);

  const checkForWikiLink = useCallback(
    (editor) => {
      const { selection } = editor.state;
      const { $from } = selection;
      const currentLine = $from.nodeBefore?.text || "";
      const lastTwoChars = currentLine.slice(-2);

      if (lastTwoChars === "[[") {
        const rect = editor.view.coordsAtPos($from.pos);
        setLinkDropdownAnchorEl({ top: rect.bottom, left: rect.left });
        setShowLinkDropdown(true);

        // setFilteredNotes(allNotes.filter(note => note.id !== activeFile.id));
      } else {
        setShowLinkDropdown(false);
      }
    },
    [allNotes]
  );

  const handleNoteSelection = (selectedNote) => {
    setShowLinkDropdown(false);
    if (editor) {
      const { state } = editor;
      const { selection } = state;
      const { $from } = selection;

      // Find the position of the last '[['
      const currentLine = $from.nodeBefore?.text || "";
      const lastBracketIndex = currentLine.lastIndexOf("[[");

      if (lastBracketIndex !== -1) {
        // Delete the '[[' characters
        editor
          .chain()
          .focus()
          .setTextSelection({ from: $from.pos - 2, to: $from.pos })
          .deleteSelection()
          .run();

        // Insert the note title (without brackets)
        const noteTitle = selectedNote.title;
        editor.chain().focus().insertContent(noteTitle).run();

        // Set the link on the inserted title
        const linkStart = $from.pos - 2;
        const linkEnd = linkStart + noteTitle.length;

        // Ensure the selectedNote has a slug
        const slug =
          selectedNote.slug ||
          selectedNote.title.toLowerCase().replace(/\s+/g, "-");

        editor
          .chain()
          .focus()
          .setTextSelection({ from: linkStart, to: linkEnd })
          .setLink({
            href: `[[${slug}]]`,
            color: selectedNote.color || COLORS.niceBlue,
          })
          .run();

        // Move the cursor to the end of the link
        editor.commands.setTextSelection(linkEnd);

        // Unset the selection to prevent further text from being part of the link
        editor.commands.unsetMark("link");
      }
    }
  };

  const handleEditorClick = useCallback(
    (event) => {
      if (editor && !editor.isActive("link")) {
        const editorView = editor.view;
        const pos = editorView.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        });
        if (pos) {
          //   editor.commands.setTextSelection(pos.pos);
          editor.commands.focus();
        }
      }
    },
    [editor]
  );

  const handleBack = () => {
    if (editor) {
      editor.commands.undo();
    }

    // last state
    if (openFiles.length > 1) {
      onGoBack();
    } else {
      onCloseNote(activeNoteId);
    }
  };

  const handleForward = () => {
    if (editor) {
      editor.commands.redo();
    }
    onGoForward();
  };

  const openNoteOptionsModal = (note, event) => {
    event.stopPropagation();
    setSelectedNote(note);
    setShowNoteOptionsModal(true);
  };

  // Add this function to the MainContent component
  const removeLinkFromContent = useCallback((content, slugToRemove) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const links = doc.querySelectorAll("a.tiptap-link");

    links.forEach((link) => {
      const href = link.getAttribute("href");
      if (href === `[[${slugToRemove}]]`) {
        // Remove the entire link element instead of just replacing it with its text content
        link.parentNode.removeChild(link);
      }
    });

    return doc.body.innerHTML;
  }, []);

  // Add this new function to handle the actual unlinking after confirmation
  const confirmUnlink = useCallback(() => {
    if (!activeFile || !activeFile.slug || !noteToUnlink) return;

    const updatedContent = removeLinkFromContent(
      noteToUnlink.content,
      activeFile.slug
    );

    // Update the referencing note's content
    onUpdateFile(noteToUnlink.id, {
      ...noteToUnlink,
      content: updatedContent,
    });

    // Update the local state of allNotes
    setAllNotes((prevNotes) =>
      prevNotes.map((note) =>
        note.id === noteToUnlink.id
          ? { ...note, content: updatedContent }
          : note
      )
    );

    setConfirmUnlinkOpen(false);
    setNoteToUnlink(null);
  }, [
    activeFile,
    noteToUnlink,
    onUpdateFile,
    removeLinkFromContent,
    setAllNotes,
  ]);

  const handleAudioClick = (event) => {
    if (event.target.tagName === "AUDIO") {
      event.preventDefault();
      const audioUrl = event.target.src;
      setCurrentAudioUrl(audioUrl);
      setAudioModalOpen(true);
    }
  };

  // Add this function to handle unlinking
  const handleUnlinkNote = useCallback((note) => {
    setNoteToUnlink(note);
    setConfirmUnlinkOpen(true);
  }, []);

  return (
    <Box sx={styles.mainContent}>
      <AudioPlayerModal
        open={audioModalOpen}
        onClose={() => setAudioModalOpen(false)}
        audioUrl={currentAudioUrl}
      />
      <SelectFolderModal
        deleteOnly={true}
        note={selectedNote}
        currentState={currentState}
        handleDeleteNote={handleDeleteNote}
        handleSelectFolder={handleSelectFolder}
        open={showNoteOptionsModal}
        onClose={() => {
          setShowNoteOptionsModal(false);
          setSelectedNote(null);
        }}
        folders={folders}
      />
      {/* TABS */}
      <Box sx={styles.topSection}>
        <EditorTabs
          openFiles={openFiles}
          activeNoteId={activeNoteId}
          handleTabChange={handleTabChange}
          onCloseTab={onCloseNote}
        />
      </Box>
      {/* NAVIGATION  */}
      <Box sx={styles.controls}>
        <Box sx={styles.controlsLeft}>
          <IconButton
            onClick={() => handleBack()}
            disabled={openFiles.length === 1}
          >
            <ArrowBack
              fontSize="small"
              sx={{ color: COLORS.niceSuperLightGrey }}
            />
          </IconButton>
          <IconButton
            onClick={() => handleForward()}
            disabled={openFiles.length === 1}
          >
            <ArrowForward
              fontSize="small"
              sx={{ color: COLORS.niceSuperLightGrey }}
            />
          </IconButton>
          {isSyncing && (
            <Box sx={styles.syncingContainer}>
              <Box sx={styles.syncingText}>
                {saveLoading ? "Pending" : "Synced"}
              </Box>
              <Box
                sx={{
                  ...styles.syncingDot,
                  backgroundColor: saveLoading ? COLORS.niceOrange : "#4CAF50",
                }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", pr: 1 }}>
          {/* Add this new button */}
          <IconButton onClick={toggleEditMode} sx={{}}>
            {isEditMode ? (
              <VisibilityIcon
                sx={{
                  p: 0,
                  m: 0,
                  color: COLORS.niceDarkGrey,
                  fontSize: "16px",
                }}
              />
            ) : (
              <EditIcon
                sx={{
                  p: 0,
                  m: 0,
                  color: COLORS.niceDarkGrey,
                  fontSize: "16px",
                }}
              />
            )}
          </IconButton>
        </Box>
      </Box>
      {/* EDITOR */}
      <ScrollableEditor file={activeFile} onClick={handleEditorClick}>
        {editor && <RenderBubbleMenu editor={editor} />}
        {!isEditMode && (
          <Box sx={styles.viewModeWarning}>You are in view mode</Box>
        )}
        {/* <EditorContent editor={editor} onClick={handleRenderedLinkClick} /> */}
        <EditorContent
          editor={editor}
          onClick={(event) => {
            handleRenderedLinkClick(event);
            handleAudioClick(event);
          }}
        />
        <LinkDropdown
          allNotes={allNotes}
          showLinkDropdown={showLinkDropdown}
          linkDropdownAnchorEl={linkDropdownAnchorEl}
          filteredNotes={filteredNotes}
          handleNoteSelection={handleNoteSelection}
          setShowLinkDropdown={setShowLinkDropdown}
        />
        <ResourceLinks
          user={user}
          referencingNotes={referencingNotes}
          handleUnlinkNote={handleUnlinkNote}
          handleGenerateSmartLinks={handleGenerateSmartLinks}
          onNoteClick={handleNoteSelection}
          activeFile={activeFile}
          setActiveFile={setActiveFile}
          onTabChange={onTabChange}
          openFiles={openFiles}
          setOpenFiles={setOpenFiles}
        />
      </ScrollableEditor>
      <EditorMetrics editor={editor} file={activeFile} />
      <Dialog
        open={confirmUnlinkOpen}
        onClose={() => setConfirmUnlinkOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Unlink"}</DialogTitle>
        <DialogContent>
          Are you sure you want to unlink this note?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmUnlinkOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmUnlink} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const RenderBubbleMenu = ({ editor }) => {
  const [linkUrl, setLinkUrl] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [alignment, setAlignment] = useState("left");
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const [tableAnchorEl, setTableAnchorEl] = useState(null);
  const [rows, setRows] = useState(3);
  const [cols, setCols] = useState(3);

  const handleTableClick = (event) => {
    event.preventDefault(); // Prevent default behavior
    event.stopPropagation(); // Stop event propagation
    setTableAnchorEl(event.currentTarget);
  };

  const handleTableClose = () => {
    setTableAnchorEl(null);
  };

  const createTable = () => {
    editor.chain().focus().insertTable({ rows, cols }).run();
    handleTableClose();
  };

  const insertRowBefore = () => {
    editor.chain().focus().addRowBefore().run();
    handleTableClose();
  };

  const insertRowAfter = () => {
    editor.chain().focus().addRowAfter().run();
    handleTableClose();
  };

  const insertColumnBefore = () => {
    editor.chain().focus().addColumnBefore().run();
    handleTableClose();
  };

  const insertColumnAfter = () => {
    editor.chain().focus().addColumnAfter().run();
    handleTableClose();
  };

  const deleteRow = () => {
    editor.chain().focus().deleteRow().run();
    handleTableClose();
  };

  const deleteColumn = () => {
    editor.chain().focus().deleteColumn().run();
    handleTableClose();
  };

  const deleteTable = () => {
    editor.chain().focus().deleteTable().run();
    handleTableClose();
  };

  const cycleAlignment = () => {
    const alignments = ["left", "center", "right"];
    const currentIndex = alignments.indexOf(alignment);
    const nextIndex = (currentIndex + 1) % alignments.length;
    const nextAlignment = alignments[nextIndex];
    setAlignment(nextAlignment);
    editor.chain().focus().setTextAlign(nextAlignment).run();
  };

  const handleCodeBlockToggle = () => {
    editor.chain().focus().toggleCodeBlock().run();
  };

  const handleLinkClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPopoverPosition({ top: rect.bottom, left: rect.left });
    setAnchorEl(event.currentTarget);
  };

  const handleLinkClose = () => {
    setAnchorEl(null);
    setLinkUrl("");
  };

  const handleLinkSubmit = () => {
    if (linkUrl) {
      editor.chain().focus().setLink({ href: linkUrl }).run();
    } else {
      editor.chain().focus().unsetLink().run();
    }
    handleLinkClose();
  };

  const open = Boolean(anchorEl);

  const isLinkActive = editor.isActive("link");

  return (
    <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
      <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
        <Box sx={styles.toolbar}>
          <IconButton
            onClick={() => editor.chain().focus().toggleBold().run()}
            size="small"
            sx={styles.toolbarButton}
          >
            <FormatBoldIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
            size="small"
            sx={styles.toolbarButton}
          >
            <FormatItalicIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            size="small"
            sx={styles.toolbarButton}
          >
            <FormatUnderlinedIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => editor.chain().focus().toggleStrike().run()}
            size="small"
            sx={styles.toolbarButton}
          >
            <StrikethroughSIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={handleLinkClick}
            size="small"
            sx={{
              ...styles.toolbarButton,
              backgroundColor: isLinkActive
                ? `${COLORS.nicePink}40`
                : "transparent",
            }}
          >
            <LinkIcon fontSize="small" />
          </IconButton>

          <IconButton
            onClick={() => editor.chain().focus().toggleTaskList().run()}
            size="small"
            sx={{
              ...styles.toolbarButton,
              backgroundColor: editor.isActive("taskList")
                ? `${COLORS.nicePink}40`
                : "transparent",
            }}
          >
            <PlaylistAddCheckIcon fontSize="small" />
          </IconButton>

          <IconButton
            onClick={handleCodeBlockToggle}
            size="small"
            sx={{
              ...styles.toolbarButton,
              backgroundColor: editor.isActive("codeBlock")
                ? `${COLORS.nicePink}40`
                : "transparent",
            }}
          >
            <CodeIcon fontSize="small" />
          </IconButton>

          <IconButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            size="small"
            sx={styles.toolbarButton}
          >
            <FormatListBulletedIcon fontSize="small" />
          </IconButton>

          <IconButton
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            size="small"
            sx={styles.toolbarButton}
          >
            <FormatQuoteIcon fontSize="small" />
          </IconButton>

          <IconButton
            onClick={handleTableClick}
            size="small"
            sx={styles.toolbarButton}
          >
            <TableChartIcon fontSize="small" />
          </IconButton>

          <CustomTooltip title={`Align ${alignment}`}>
            <IconButton
              onClick={cycleAlignment}
              size="small"
              sx={styles.toolbarButton}
            >
              {alignment === "left" && <FormatAlignLeftIcon fontSize="small" />}
              {alignment === "center" && (
                <FormatAlignCenterIcon fontSize="small" />
              )}
              {alignment === "right" && (
                <FormatAlignRightIcon fontSize="small" />
              )}
            </IconButton>
          </CustomTooltip>
        </Box>

        <Menu
        anchorEl={tableAnchorEl}
        open={Boolean(tableAnchorEl)}
        onClose={handleTableClose}
        PaperProps={{
          sx: {
            backgroundColor: "#1E1E1E",
            color: "#FFFFFF",
            borderRadius: "16px",
            boxShadow: "0 4px 30px rgba(0,0,0,0.3)",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: "4px",
              background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
            },
          },
        }}
      >
        <MenuItem>
          <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
            <Typography sx={{ marginRight: '8px', color: '#FFFFFF' }}>Rows:</Typography>
            <input 
              type="number" 
              value={rows} 
              onChange={(e) => setRows(Number(e.target.value))} 
              min="1" 
              style={{ 
                width: '40px', 
                marginRight: '16px',
                backgroundColor: '#333',
                color: '#FFFFFF',
                border: 'none',
                padding: '4px',
                borderRadius: '4px'
              }}
            />
            <Typography sx={{ marginRight: '8px', color: '#FFFFFF' }}>Cols:</Typography>
            <input 
              type="number" 
              value={cols} 
              onChange={(e) => setCols(Number(e.target.value))} 
              min="1" 
              style={{ 
                width: '40px', 
                marginRight: '16px',
                backgroundColor: '#333',
                color: '#FFFFFF',
                border: 'none',
                padding: '4px',
                borderRadius: '4px'
              }}
            />
            <Button 
              onClick={createTable} 
              size="small" 
              sx={{ 
                marginLeft: '8px',
                backgroundColor: alpha(COLORS.nicePurple, 0.2),
                color: COLORS.niceWhite,
                '&:hover': {
                  backgroundColor: alpha(COLORS.nicePurple, 0.3),
                }
              }}
            >
              Create
            </Button>
          </Box>
        </MenuItem>
        <MenuItem onClick={insertRowBefore} sx={{ color: '#FFFFFF', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>Insert Row Before</MenuItem>
        <MenuItem onClick={insertRowAfter} sx={{ color: '#FFFFFF', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>Insert Row After</MenuItem>
        <MenuItem onClick={insertColumnBefore} sx={{ color: '#FFFFFF', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>Insert Column Before</MenuItem>
        <MenuItem onClick={insertColumnAfter} sx={{ color: '#FFFFFF', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>Insert Column After</MenuItem>
        <MenuItem onClick={deleteRow} sx={{ color: '#FFFFFF', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>Delete Row</MenuItem>
        <MenuItem onClick={deleteColumn} sx={{ color: '#FFFFFF', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>Delete Column</MenuItem>
        <MenuItem onClick={deleteTable} sx={{ color: '#FFFFFF', '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}>Delete Table</MenuItem>
      </Menu>

        <StyledPopover
          open={open}
          anchorReference="anchorPosition"
          anchorPosition={popoverPosition}
          onClose={handleLinkClose}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <LinkInputWrapper>
            <StyledTextField
              size="small"
              value={linkUrl}
              onKeyPress={(e) => e.key === "Enter" && handleLinkSubmit()}
              onChange={(e) => setLinkUrl(e.target.value)}
              placeholder="Enter URL"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SubmitButton onClick={handleLinkSubmit} size="small">
                      <AddIcon fontSize="small" />
                    </SubmitButton>
                  </InputAdornment>
                ),
              }}
              inputRef={(input) => {
                if (input) {
                  setTimeout(() => input.focus(), 0);
                }
              }}
            />
          </LinkInputWrapper>
        </StyledPopover>
      </BubbleMenu>
    </BubbleMenu>
  );
};

const styles = {
  mainContent: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    backgroundColor: COLORS.niceDeepBlack,
  },
  topSection: {
    minHeight: "50px",
    backgroundColor: "#2a2a2a",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // cursor: "pointer",
  },
  controlsLeft: {
    display: "flex",
    alignItems: "center",
  },
  resourceLinks: {
    marginTop: "20px",
    borderTop: `1px solid ${COLORS.nicePurple}40`,
    paddingY: "20px",
  },
  resourceLinksHeader: {
    color: COLORS.niceSuperLightGrey,
    fontWeight: "bold",
    fontSize: "18px",
  },
  noLinksFound: {
    color: COLORS.niceSuperLightGrey,
    fontSize: "14px",
    fontWeight: 500,
  },
  linkContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  resourceLink: (color) => ({
    color: color || COLORS.niceSuperLightGrey,
    textDecoration: "none",
    backgroundColor: `${color || COLORS.niceBlue}20`,
    paddingTop: "6px",
    paddingBottom: "6px",
    borderRadius: "4px",
    transition: "all 0.2s ease",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 500,
    "&:hover": {
      color: color,
      backgroundColor: `${COLORS.niceSuperLightGrey}20`,
    },
  }),
  syncingContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
  },
  resourceLinkItem: {
    color: COLORS.niceSuperLightGrey,
    "&:hover": {
      backgroundColor: `${COLORS.nicePurple}20`,
    },
  },
  syncingDot: {
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    backgroundColor: "#4CAF50",
    marginLeft: "6px",
    animation: "flicker 1s infinite",
    "@keyframes flicker": {
      "0%, 100%": { opacity: 1 },
      "50%": { opacity: 0.5 },
    },
  },
  syncingText: {
    color: COLORS.niceSuperLightGrey,
    fontSize: "12px",
  },

  linkDropdown: {
    maxHeight: "200px",
    overflowY: "auto",
    backgroundColor: COLORS.niceDeepBlack,
    color: COLORS.niceSuperLightGrey,
    border: `1px solid ${COLORS.nicePurple}40`,
    borderRadius: "8px",
    "& .MuiListItem-root": {
      "&:hover": {
        backgroundColor: `${COLORS.nicePurple}20`,
      },
    },
  },
  controls: {
    // padding: "2.5px 0",
    height: "56px",
    borderBottom: "1px solid #333",
    width: "100%",
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "12px",
  },
  editorContainer: {
    flex: 1,
    padding: "20px",
    overflow: "auto",
    "& .tiptap-editor": {
      height: "100%",
      color: COLORS.niceSuperLightGrey,

      fontSize: "15px", // MAIN TEXT EDITOR SIZE
      "& .is-empty::before": {
        color: "rgba(255, 255, 255, 0.5)",
        content: "attr(data-placeholder)",
        float: "left",
        height: 0,
        pointerEvents: "none",
      },

      "& .ProseMirror": {
        "& > h1:first-child": {
          fontSize: "80px",
          fontWeight: "bold",
          marginBottom: "24px",
          color: COLORS.nicePurple,
          borderBottom: `2px solid ${COLORS.nicePink}`,
          paddingBottom: "12px",
        },
        "& .custom-horizontal-rule": {
          border: "none",
          height: "2px",
          backgroundColor: COLORS.niceSuperLightGrey,
          margin: "1em 0",
          width: "100%",
          opacity: 0.5,
        },
      },

      "& p": {
        margin: 0,
      },
      "&:focus": {
        outline: "none",
      },
    },
  },
  viewModeWarning: {
    position: "absolute",
    top: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: COLORS.niceDarkGrey,
    color: "#fff",
    padding: "8px 16px",
    borderRadius: "4px",
    zIndex: 10,
  },
  toolbar: {
    display: "flex",
    width: "500px",
    justifyContent: "space-between",

    backgroundColor: "rgba(51, 51, 51, 0.9)",
    borderRadius: "8px",
    padding: "4px 8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    position: "relative",
    whiteSpace: "nowrap",
    animation: `${popAnimation} 0.3s ease-out`,
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "8px",
      padding: "1px",
      background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink})`,
      mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      maskComposite: "exclude",
    },
  },
  toolbarButton: {
    color: "#FFFFFF",
    padding: "6px",
    margin: "0 2px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    "&:active": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
};

export default MainContent;
