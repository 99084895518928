import React, { useState } from 'react';
import {
  Typography,
  Modal,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  alpha,
} from '@mui/material';
import { COLORS } from '../utils/colors';
import RedClose from './RedClose';
import DeleteIcon from '@mui/icons-material/Delete';

const FolderOptionsModal = ({ open, onClose, onDeleteFolder, folder }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <Modal
      open={open}
      onClose={() => {
        setConfirmDelete(false);
        onClose();
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: 'blur(10px)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          maxHeight: '50%',
          flexDirection: 'column',
          backgroundColor: '#1E1E1E',
          borderRadius: '16px',
          width: isMobile ? '90%' : '400px',
          padding: '32px',
          boxShadow: '0 4px 30px rgba(0,0,0,0.3)',
          position: 'relative',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: alpha(COLORS.nicePurple, 0.5),
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: COLORS.nicePurple,
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '4px',
            background: `linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <RedClose
            handleClose={() => {
              setConfirmDelete(false);
              onClose();
            }}
          />
        </Box>

        <Typography
          variant="h5"
          sx={{ fontWeight: 700, textAlign: 'center', color: '#FFFFFF' }}
        >
          Folder Options
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ mb: 2, fontWeight: 500, textAlign: 'center', color: COLORS.lightGrey }}
        >
          {folder?.name}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => {
              if (confirmDelete) {
                onDeleteFolder(folder);
                setConfirmDelete(false);
                onClose();
              } else {
                setConfirmDelete(true);
              }
            }}
            sx={{
              color: confirmDelete ? COLORS.primaryRed : COLORS.primaryRed,
              marginTop: '1rem',
              borderColor: confirmDelete ? COLORS.primaryRed : COLORS.primaryRed,
              '&:hover': {
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                borderColor: COLORS.primaryRed,
              },
            }}
          >
            {confirmDelete ? 'Confirm Delete' : 'Delete Folder'}
          </Button>
        </Box>
        
        {confirmDelete && (
          <Typography
            sx={{
              textAlign: 'center',
              color: 'white',
              fontSize: '12px',
              marginTop: '8px',
            }}
          >
            Are you sure you want to delete this folder?
          </Typography>
        )}
        
        {/* Add more options here as needed */}
     
      </Box>
    </Modal>
  );
};

export default FolderOptionsModal;