import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { COLORS } from "../utils/colors";
import CustomTooltip from "./CustomTooltip";
import { keyframes } from "@emotion/react";

const EditorMetrics = ({ editor, file }) => {
  if (!editor) return null;

  const text = editor.getText();
  const words = text.split(/\s+/).filter((word) => word.length > 0);
  const wordCount = words.length;
  const charCount = text.length;
  const readingTime = Math.ceil(wordCount / 200);

  // Flesch-Kincaid Grade Level calculation
  const sentences = text
    .split(/[.!?]+/)
    .filter((sentence) => sentence.length > 0);
  const avgWordsPerSentence = wordCount / sentences.length;
  const avgSyllablesPerWord =
    words.reduce((sum, word) => {
      return sum + (word.match(/[aeiou]/gi) || []).length;
    }, 0) / wordCount;
  const readabilityScore =
    0.39 * avgWordsPerSentence + 11.8 * avgSyllablesPerWord - 15.59;

  const headingCount = editor
    .getJSON()
    .content.filter((node) => node.type === "heading").length;

  const selection = editor.state.selection;
  const selectedText = editor.state.doc.textBetween(
    selection.from,
    selection.to
  );
  const selectedWords = selectedText
    .split(/\s+/)
    .filter((word) => word.length > 0).length;

  return (
    <Box sx={styles.bottomBar}>
        
      <Typography variant="caption" sx={styles.metric}>
        Words: <span style={{ color: file?.color || COLORS.niceSuperLightGrey }}>{wordCount}</span>
      </Typography>
      <Typography variant="caption" sx={styles.metric}>
        Characters: <span style={{ color: file?.color || COLORS.niceSuperLightGrey }}>{charCount}</span>
      </Typography>
       
      {/* <CustomTooltip title="Flesch-Kincaid is a widely used measure of readability (lower is better)"> */}
        <Typography variant="caption" sx={styles.metric}>
        Selection: <span style={{ color: file?.color || COLORS.niceSuperLightGrey }}>{selectedWords} words</span>
        </Typography>
      {/* </CustomTooltip> */}

      <Typography variant="caption" sx={styles.metric}>
        Est reading time: <span style={{ color: file?.color || COLORS.niceSuperLightGrey }}>{readingTime} min</span>
      </Typography>
     
      {/* <Tooltip title="Highlighted text word count">
        <Typography variant="caption" sx={styles.metric}>
          Selected: {selectedWords} words
        </Typography>
      </Tooltip> */}
    </Box>
  );
};

const styles = {
  bottomBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    backgroundColor: COLORS.niceDeepBlack,
    borderTop: `1px solid ${COLORS.niceDeepBlack}`,
  },
  metric: {
    color: COLORS.niceSuperLightGrey,
    fontSize: "0.75rem",
  },
};

export default EditorMetrics;
