export const COLORS = {
    primaryGray: "#f8fafc",
    primaryLightGrey: "#FBFAF8",
    primaryPink: "#fc64c4",
    primaryBrown: "#fffcf4",
    primaryRed: "#ff4742",

    diamondBlue: "#2573da",
    indiehackersBlue: "#0e2439",
    darkBlue: "#282c34",
    gmailBlue: "#eaf1fb",
    lightBlue: "#c2e7ff",
    lightGrey: "#6b727a",

    niceDeepBlack: "#121212",
    niceBlack: "#201c1c",
    niceWhite: "#f8fafc",
    // niceLightGrey: "#f1f5f9",
    niceDarkPurple: "#6419e6",
    niceLightBlue: "#c2e7ff",
    niceDarkBlue: "#0e2439",
    asanaBlack: "#302c34",

    // primary colors
    nicePurple: "#bc90cf",
    niceLightPurple: "#f3e8ff",
    nicePink: "#e384a3",
    niceBlue: "#5f9ee3",
    niceLightGrey: "#282424",

    niceDarkGray: "#1e1e1e",
    niceMediumGray: "#2a2a2a",
    niceLightGray: "#363636",
    niceVeryLightGray: "#424242",
    niceDarkGrey: "#a0a0a0",
    niceSuperLightGrey: "#cccccc",
    niceGreen: "#2fc665",
    niceOrange: "#f59e0b",
    niceSyncingGreen: "#4CAF50",
}