import {
  Google,
  Apple,
  Microsoft,
  Amazon,
  Facebook,
  Twitter,
  LinkedIn,
  Instagram,
  GitHub,
  Airbnb,
} from "@mui/icons-material";

export const STANDARD_BORDER_RADIUS = "12px";
export const CHAT_HEIGHT = "85%";

export const PLACEHOLDER_COMMENTS = [
  {
    username: "NatureLover92",
    message: "Really loved the simplicity of this recipe!",
  },
  {
    username: "EcoMama101",
    message:
      "I tried this last night and it turned out great. Has anyone tried adding shea butter to this mix?",
  },
  {
    username: "GreenThumbGuy",
    message:
      "Could use a bit more scent, maybe more lavender oil next time. Any recommendations for a stronger scent?",
  },
  {
    username: "OrganicQueen",
    message:
      "Perfect for sensitive skin, thank you for sharing! Where do you buy your ingredients?",
  },
  {
    username: "HealthFirst78",
    message:
      "Was a bit too oily for my taste. Has anyone tried using less oil?",
  },
  {
    username: "WellnessWarrior",
    message:
      "Amazing recipe, everyone in the family loved it! I added some vitamin E for extra nourishment.",
  },
  {
    username: "DIYDad2023",
    message:
      "Not sure this is for me, didn’t feel as effective as expected. Any tips on how to improve it?",
  },
  {
    username: "BeautyGuru88",
    message:
      "Super easy to follow along, even for a beginner like me. Thanks for the clear instructions!",
  },
  {
    username: "CraftyCreator",
    message:
      "I appreciate the detailed tips, very helpful. I've been experimenting with different essential oils; rosemary was a hit.",
  },
  {
    username: "VeganVibes",
    message:
      "Took a bit longer to solidify than stated. Has anyone tried it with a quick-set hack?",
  },
  {
    username: "EarthFriendlyMom",
    message:
      "I substituted some ingredients and it still came out great! I used beeswax instead of regular wax, worked like a charm.",
  },
  {
    username: "SustainableSally",
    message:
      "This didn't work out for me at all, was disappointing. Could altitude affect how it sets?",
  },
  {
    username: "HerbalHelen",
    message:
      "Has anyone tried doubling the batch? Wondering how that affects the consistency.",
  },
  {
    username: "EcoWarrior101",
    message:
      "I’m trying to avoid using too many essential oils; does anyone have a suggestion for natural scents?",
  },
  {
    username: "SimpleLiving",
    message:
      "This was a fun weekend project, my kids even helped out. They loved choosing the scents!",
  },
  {
    username: "ConsciousConsumer",
    message: "Does anyone know how long these typically last before expiring?",
  },
  {
    username: "ZenMaster",
    message:
      "Found this to be a great stress-reliever. The making process is as calming as the product itself!",
  },
  {
    username: "BudgetMom",
    message:
      "Love how cost-effective these recipes are. Much cheaper than store-bought!",
  },
  {
    username: "AllergyAware",
    message:
      "Does anyone have a good substitute for coconut oil? Allergic over here.",
  },
  {
    username: "FragranceFanatic",
    message:
      "Experimented with ylang-ylang instead of lavender and it was fantastic!",
  },
  {
    username: "NoWasteWay",
    message:
      "Love these zero-waste options. Anyone else trying to go trash-free?",
  },
  {
    username: "EcoEthic",
    message:
      "It's great seeing so many people embracing sustainable alternatives.",
  },
  {
    username: "GreenGuru",
    message: "Anyone else incorporate solar-infused oils into their recipes?",
  },
  {
    username: "HolisticHolly",
    message:
      "I've been adding crystal chips into my batches for that extra energy boost. Anyone else?",
  },
  {
    username: "DetoxDiva",
    message:
      "Looking for a recipe that incorporates bentonite clay, any suggestions?",
  },
  {
    username: "PlantPower",
    message: "Has anyone created a recipe specifically for acne-prone skin?",
  },
  {
    username: "MinimalistMom",
    message:
      "Looking for the most minimal ingredient list possible. Simplicity is key!",
  },
  {
    username: "Craftsman",
    message: "Has anyone tried selling their homemade products?",
  },
  {
    username: "Trendsetter",
    message:
      "Just shared this recipe on my blog and my followers are loving it!",
  },
  {
    username: "HerbHaven",
    message: "This has inspired me to grow my own herbs for more DIY projects.",
  },
  {
    username: "BeautyBlogger",
    message:
      "Going to feature this recipe in my next vlog. Perfect for my skin care routine!",
  },
  {
    username: "EcoEngineer",
    message:
      "Tweaked the recipe for better consistency. Happy to share my findings!",
  },
  {
    username: "ThriftyThinker",
    message: "Anyone have tips for sourcing ingredients in bulk to save money?",
  },
  {
    username: "NatureNut",
    message:
      "Turned my kitchen into a mini lab with all these experiments. So much fun!",
  },
  {
    username: "GreenTechie",
    message:
      "Utilized a low-energy mixer for this recipe to keep it eco-friendly.",
  },
  {
    username: "SavvySurvivor",
    message:
      "These homemade remedies are lifesavers in remote living situations.",
  },
  {
    username: "IngredientInquirer",
    message:
      "Curious if anyone has info on the shelf life of these ingredients?",
  },
  {
    username: "YouthfulYogi",
    message:
      "Integrated this into my self-care Sunday routine, and it’s a hit!",
  },
  {
    username: "OptimalOrganic",
    message:
      "Ensuring all my ingredients are organic and ethically sourced. Any brand recommendations?",
  },
  {
    username: "VitalVegan",
    message: "Made sure all components were vegan. It's perfect!",
  },
  {
    username: "PreservativePatrol",
    message: "Avoiding preservatives makes a huge difference in skin health.",
  },
  {
    username: "CurativeCook",
    message:
      "Used this remedy for a skin ailment, and the results were surprising.",
  },
  {
    username: "PeacefulPrepper",
    message: "These recipes are great for building a natural first aid kit.",
  },
  {
    username: "ResourcefulRoofer",
    message:
      "Used leftover materials to create eco-friendly packaging for my batches.",
  },
  {
    username: "SkincareScientist",
    message: "Analyzing the pH balance of these recipes to ensure skin safety.",
  },
  {
    username: "EagerEducator",
    message:
      "Teaching my students about sustainable living through these DIYs.",
  },
  {
    username: "DynamicDIYer",
    message: "Always tweaking and improving the recipes to fit personal needs.",
  },
  {
    username: "GentleGardener",
    message: "Incorporated home-grown aloe vera for its soothing properties.",
  },
  {
    username: "HolisticHealer",
    message:
      "Combining these recipes with other natural therapies for holistic care.",
  },
  {
    username: "FrugalFan",
    message:
      "Appreciate how these recipes help save money and avoid wasteful packaging.",
  },
  {
    username: "EcoAdventurer",
    message: "These DIY products are perfect for my backpacking trips!",
  },
];

export const AVATARS = [
  "https://randomuser.me/api/portraits/men/32.jpg",
  "https://randomuser.me/api/portraits/women/44.jpg",
  "https://randomuser.me/api/portraits/men/45.jpg",
  "https://randomuser.me/api/portraits/women/65.jpg",
  "https://randomuser.me/api/portraits/men/22.jpg",
  "https://randomuser.me/api/portraits/women/30.jpg",
  "https://randomuser.me/api/portraits/men/33.jpg",
  "https://randomuser.me/api/portraits/women/40.jpg",
  "https://randomuser.me/api/portraits/men/38.jpg",
  "https://randomuser.me/api/portraits/women/50.jpg",
  "https://randomuser.me/api/portraits/men/52.jpg",
  "https://randomuser.me/api/portraits/women/28.jpg",
  "https://randomuser.me/api/portraits/men/18.jpg",
  "https://randomuser.me/api/portraits/women/24.jpg",
  "https://randomuser.me/api/portraits/men/27.jpg",
  "https://randomuser.me/api/portraits/women/32.jpg",
  "https://randomuser.me/api/portraits/men/41.jpg",
  "https://randomuser.me/api/portraits/women/60.jpg",
];

export const publicTopics = [
  { id: 1, name: "Startups" },
  { id: 8, name: "Business" },
  { id: 2, name: "History" },
  // { id: 3, name: "Art" },
  // { id: 4, name: "Chemistry" },
  // { id: 5, name: "Physics" },
  // { id: 6, name: "Biology" },
  { id: 3, name: 'Sciences'},
  // { id: 7, name: "Math" },
  { id: 6, name: "Others" },
  // Add more topics as needed
];

export const CURRENT_PRICE = 39;

export const PRICING_PLANS = [
  {
    title: "Free",
    displayName: "Hobby",
    subtitle: "Free",
    price: 0,
    stripePriceId: "price_1PiNRqHZMLByfjY0sIxCPAq9",
    period: "/ mo",
    interval: "monthly",
    description: "Billed monthly",
    features: ["Unlimited notes", "Unlimited mind maps", "Powerful Search"],
    action: "Get started",
    estCost: 1,
    estGrossMargin: 19,
    estGrossMarginPercent: 98,
  },
  {
    title: "Starter",
    displayName: "Starter",
    subtitle: "Perfect for students and solo workers",
    price: 29,
    // stripePriceId: "price_1PnBzeL1nj8LfslXnOsnzHwp",// test live $0.50
    stripePriceId: "price_1PmgbEL1nj8LfslXevp9XuuU", // LIVE
    period: "/ mo",
    interval: "monthly",
    description: "Billed monthly",
    features: [
      "Unlimited notes",
      "Unlimited mind map",
      "Powerful Search",
      // "Real-time Team collaboration",
      // "Discord community",
      "500 fast premium AI requests",
      "AI Auto link",
      "AI Chat",
      "AI Site Summarization",
      "Basic support",
    ],
    action: "Get started",
    estCost: 20,
    estGrossMargin: 29,
    estGrossMarginPercent: 59.2,
  },
  {
    title: "Pro",
    displayName: "Professional",
    subtitle: "Everything in Starter, plus...",
    stripePriceId: "price_1PmgbqL1nj8LfslXIqc8K0AT",
    price: 59,
    period: "/ mo",
    interval: "monthly",
    description: "Billed monthly",
    features: ["1500 fast premium chat requests", "Priority support"],
    popular: true,
    action: "Upgrade",
    estCost: 100,
    estGrossMargin: 99,
    estGrossMarginPercent: 49.7,
  },
  // {
  //   title: "Enterprise",
  //   displayName: "Enterprise",
  //   subtitle: "For large teams and organizations",
  //   price: 999,
  //   period: "/ mo",
  //   interval: 'monthly',
  //   description: "Billed monthly",
  //   features: [
  //     "Up to 15M tokens",
  //     "Hire AI talent (unlimited)",
  //     "Create AI talent (unlimited)",
  //     "Earn revenue from your AI talent",
  //     "Join to Discord community",
  //     "Dedicated Support Person",
  //     "API Access",
  //     "Product Roadmap Input",
  //     "Early Access to New Features",
  //   ],
  //   action: "Contact sales",
  //   estCost: 600,
  //   estGrossMargin: 399,
  //   estGrossMarginPercent: 39.9,
  //   estRevShareAmount: 179.55,
  // },
];

export const YEARLY_PRICING_PLANS = [
  {
    title: "Starter Yearly",
    displayName: "Starter",
    subtitle: "For individual users and small teams",
    price: 220,
    period: "/ yr",
    stripePriceId: "price_1PmgczL1nj8LfslXEJTXcDpY",
    interval: "yearly",
    description: `Billed annually as (3 months free)`,
    features: [
      "Unlimited notes",
      "Visual mind map",
      "Powerful Search",
      "Real-time Team collaboration",
      "Discord community",
      "500 fast premium chat requests",
      "AI-Auto link notes",
      "AI-Chat",
      "Basic support",
    ],
    action: "Get started",
    estCost: 1999,
    estGrossMargin: 201,
    estGrossMarginPercent: 45.6,
    estRevShareAmount: 90.45,
  },
  {
    title: "Pro Yearly",
    displayName: "Professional",
    subtitle: "Everything in Starter, plus...",
    stripePriceId: "price_1PmgdsL1nj8LfslXJb0Pr9iS",
    price: 7499,
    period: "/ yr",
    interval: "yearly",
    description: "Billed annually (3 months free)",
    features: ["1500 fast premium chat requests", "Priority support"],
    popular: true,
    action: "Upgrade",
    estCost: 1200,
    estGrossMargin: 591,
    estGrossMarginPercent: 33.0,
    estRevShareAmount: 265.95,
  },
  // {
  //   title: "Enterprise Yearly",
  //   displayName: "Enterprise",
  //   subtitle: "For large teams and organizations",
  //   price: 8991,
  //   period: "/ yr",
  //   interval: 'yearly',
  //   description: "Billed annually (3 months free)",
  //   features: [
  //     "Up to 15M tokens",
  //     "Hire AI talent (unlimited)",
  //     "Create AI talent (unlimited)",
  //     "Earn revenue from your AI talent",
  //     "Join to Discord community",
  //     "Dedicated Support Person",
  //     "API Access",
  //     "Product Roadmap Input",
  //     "Early Access to New Features",
  //   ],
  //   action: "Contact sales",
  //   estCost: 7200,
  //   estGrossMargin: 1791,
  //   estGrossMarginPercent: 19.9,
  //   estRevShareAmount: 805.95,
  // },
];

export const MOST_POWERFUL_AI_MODEL = "Chat GPT-4o";

export const videoFeatures = [
  // browse any mind map
  {
    title: "Discover mind maps",
    description:
      "Browse 100s of interactive mind maps on various subject matters made by other users. ",
    videoUrl: "https://notedfm.s3.us-east-005.backblazeb2.com/demov2_.mp4",
  },
   // create your own mind MAP
  {
    title: "Create mind maps",
    description:
      "Use AI to create an entire mind map in few seconds.. or... create each note and link them manually one at time",
    videoUrl: "https://notedfm.s3.us-east-005.backblazeb2.com/demo_newnote.mp4",
  },
  // LINKING / AUTO LINKING
  {
    title: "AI Auto link notes",
    description:
      "Use our AI to auto link your similar notes using hyperlinks. Like the world wide web for your note. The more links between your notes, the more valuable your mind map becomes. Manually hyperlinking also possible.",
    videoUrl: "https://notedfm.s3.us-east-005.backblazeb2.com/noted.+(3).mp4",
  },
 
  // ai chat - talk to your notes
  {
    title: "AI Chat to your notes",
    description:
      "Our AI Chat goes beyond chatting with notes. You mention specific other notes in your convo as part of the conversation context. A superpower for expanding research into topics.",
    videoUrl:
      "https://notedfm.s3.us-east-005.backblazeb2.com/Untitled+design+(3).mp4",
  },
  // summarize  - blogs, articles, research papers, meeting notes, pdfs, ebooks,tweets, twitter profiles, reddit posts, transcripts, forum discussions, any text based content..
  {
    title: "AI Summarize any url by adding noted.fm/",
    description:
      "Add 'noted.fm/' to the beginning of any url to summarize it as a new note. It immediately get's added to your mind map.",
    videoUrl:
      "https://notedfm.s3.us-east-005.backblazeb2.com/Untitled+design+(1).mp4",
  },
  // POWERFUL SEARCH
  {
    title: "Powerful search",
    description:
      "Retrieve any old note or mind map in seconds using words or phrases. It's super fast regardless of your notes count.",
    videoUrl:
      "https://notedfm.s3.us-east-005.backblazeb2.com/Untitled+design+(2).mp4",
  },
  // ai voice notes
  // {
  //   title: "Create voice notes",
  //   description: "Record your voice then we'll create a new note with a summary, audio link, and transcription of what you said. You can easily edit afterwards.",
  //   videoUrl:"https://notedfm.s3.us-east-005.backblazeb2.com/download.mp4"
  // },
];

export const companyLogos = [
  { name: "Google", Icon: Google },
  { name: "Apple", Icon: Apple },
  { name: "Microsoft", Icon: Microsoft },
  // { name: "Amazon", Icon: Amazon },
  { name: "Facebook", Icon: Facebook },
  { name: "Twitter", Icon: Twitter },
  { name: "LinkedIn", Icon: LinkedIn },
  { name: "Instagram", Icon: Instagram },
  { name: "GitHub", Icon: GitHub },
  // { name: "Airbnb", Icon: Airbnb },
  // { name: "Netflix", Icon: Netflix },
  // { name: "Spotify", Icon: Spotify },
  // { name: "Uber", Icon: Uber },
  // { name: "Slack", Icon: Slack },
  // { name: "Dropbox", Icon: Dropbox },
  // { name: "Adobe", Icon: Adobe },
  // { name: "Salesforce", Icon: Salesforce },
  // { name: "Intel", Icon: Intel },
  // { name: "Nvidia", Icon: Nvidia },
  // { name: "Tesla", Icon: Tesla },
  // { name: "SpaceX", Icon: SpaceX },
  // { name: "Zoom", Icon: Zoom },
  // { name: "PayPal", Icon: PayPal },
  // { name: "eBay", Icon: eBay },
  // { name: "Oracle", Icon: Oracle },
  // { name: "IBM", Icon: IBM },
  // { name: "Dell", Icon: Dell },
  // { name: "HP", Icon: HP },
  // { name: "Cisco", Icon: Cisco },
];

export const FAQuestions = [
  {
    question: "What is Noted.fm?",
    answer:
      "Noted.fm is a note-taking app designed for visual thinkers. It's built to enhance your creativity and help you organize your thoughts in a more intuitive way.",
  },
  {
    question: "How does the mind map feature work?",
    answer:
      "Our mind map feature allows you to visually connect your notes. You can manually link notes using markdown, or let our AI automatically hyperlink similar notes for you, creating a web of interconnected notes.",
  },
  {
    question: "What AI model does Noted.fm use?",
    answer: `We use ${MOST_POWERFUL_AI_MODEL}, which is currently one of the most powerful AI models available. This allows for advanced features like expanding upon your notes and answering questions about your content.`,
  },
  {
    question: "Is there a free trial available?",
    answer:
      "Yes, we offer a free plan so you can experience the capabilities of Noted.fm before committing to a subscription.",
  },
  {
    question: "Can I use Noted.fm on multiple devices?",
    answer:
      "Not yet, it's just desktop only for now. Mobile coming soon in a month.",
  },
  {
    question: "How secure are my notes?",
    answer:
      "We take the security of your data very seriously. All notes are encrypted and stored securely.",
  },
  {
    question: "Can I collaborate with others on Noted.fm?",
    answer:
      "While our current focus is on individual note-taking, we're exploring collaboration features for future updates. Stay tuned for announcements about shared notebooks and real-time collaboration.",
  },
  {
    question: "How does the AI-powered chat feature work?",
    answer:
      "Our AI chat feature allows you to interact with your notes using natural language. You can ask questions about your content, request summaries, or even brainstorm new ideas based on your existing notes.",
  },
  {
    question: "Is there a limit to how many notes I can create?",
    answer: "Only on the free plan. All paid plans have unlimited notes.",
  },
  {
    question: "Can I export my notes from Noted.fm?",
    answer:
      "Yes, although a manual email process, we will gladly export your notes for you in markdown format.",
  },
];

export const uniLogos = [
  {
    name: "University of California, Berkeley",
    // Icon:
  },
];

export const quotes = [
  {
    text: "Think I found the holy grail of note apps?",
    author: "Alex S, Student - UT-Austin",
  },
  {
    text: "The only note takin app that helps my ADHD brain and scattered thoughts.",
    author: "Saurel B, Student - UT-Austin",
  },
  {
    text: "Why didn't I find this sooner?? it's a game changer! My notes actually make sense now.",
    author: "Jess J, Researcher - UT-Austin",
  },
  {
    text: "Gotta admit, I was skeptical at first, but now im impressed",
    author: "TechBro_Alex, Software Engineer",
  },
  {
    text: "It's like having a second brain that understands how my imagination works.",
    author: "Quincy M,  Student - UT-Austin",
  },
  {
    text: "Finally, a note-taking app that gets how my ADHD brain works!",
    author: "Zoe K, Student -  UT-Austin",
  },
  {
    text: "The AI chat feature helped me break through writer's block countless times. Absolutely worth every penny!",
    author: "Priya P, Student -  UT-Austin",
  },
  {
    text: "I literally use this daily multiple times. The website summarization feature is brilliant.",
    author: "Bryan B, Student - UT-Austin",
  },
  {
    test: "I've never been able to connect ideas so effortlessly before",
    author: "Kai M, Student - UT-Austin",
  },
  {
    text: "As a writer it goes a long way to see all your notes in a visual.",
    author: "Emma L, Student - UT-Austin",
  },
  {
    text: "This app has completely changed my creative process.",
    author: "Emma L, Student - UT-Austin",
  },
  {
    text: "As a writer it goes a long way to see all your notes in a visual.",
    author: "Emma L, Student - UT-Austin",
  },
  {
    text: "Love!!! I used to use Evernote before but the amount of clutter on my notes was overwhelming. This is perfect.",
    author: "Sam E, Student - UT-Austin",
  },
  {
    text: "Thanks for making this!",
    author: "Dillon D, Software Engineer",
  },
  {
    text: "how did you know exactly what i needed???",
    author: "Tigest T, Student - UT-Austin",
  },
  {
    text: "Late night study session and noted.fm is keeping me sane.",
    author: "Callum W, Student - UT-Austin",
  },
  {
    text: "The AI chat thing is pretty neat, feels like having a study buddy lol",
    author: "Addis T, Student - UT-Austin",
  },
  {
    text: "As a long time Obsidian user, this is quite nice. Great job building this!",
    author: "David R, Student - UT-Austin",
  },
  {
    text: "The mind map feature helps me connect ideas I never would have before. Absolutely brilliant!",
    author: "Emily C, Researcher - UT-Austin",
  },
  {
    text: "As a creative writer, I'm in love with Noted.fm.",
    author: "Marcus J, MFA Creative Writing - NYU",
  },
  {
    text: "Can't imagine writing without it now.",
    author: "Paul M, Student - UT-Austin",
  },
  {
    text: "As a visual thinker, this is perfect! Thank you for making this",
    author: "Zara S, Student - UT-Austin",
  },
  {
    text: "I've been using Notion for years, but Noted.fm's intuitive design and powerful AI have won me over. It's streamlined my entire workflow.",
    author: "Liam O, Student - UT-Austin",
  },
  {
    text: "I love this app. Please release a mobile version",
    author: "Dhruvin D, Student - UT-Austin",
  },
  {
    text: "No matter how many notes I have, I can find exactly what I need in seconds.",
    author: "Liam O'Connor, Startup Founder",
  },
];
