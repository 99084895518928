import React, { useContext, useState } from "react";
import {
  Modal,
  Box,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Divider,
  Tooltip,
} from "@mui/material";
import GlobalStateContext from "../context/globalStateContext";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css";
import RedClose from "./RedClose";
import { COLORS } from "../utils/colors";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { firestore } from "../firebaseConfig";
import UserContext from "../context/userContext";

toastConfig({
  theme: "light",
  position: "top-center",
  maxWidth: "80%",
  duration: 5000,
});

const InviteModal = ({ agentUrl, agent }) => {
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [emails, setEmails] = useState([""]);
  const showCongrats = true; //  globalState?.showCongrats;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const filteredEmails = emails.filter((email) => email.trim() !== "");
  
      if (filteredEmails.length === 0) {
        toast("Please enter at least one valid email address.");
        return;
      }
  
      const inviteMeRef = firestore.collection("generalInvites").doc(user.id);
  
      // Create a new entry for this invitation
      await inviteMeRef.set({
        [`invitations.${Date.now()}`]: {
          emails: filteredEmails,
          agentUrl: agentUrl,
          agentId: agent.id,
          timestamp: new Date(),
          user: {
            id: user.id,
            name: user.name,
            email: user.email,
          },
        }
      }, { merge: true });
  
      toast("Invites sent successfully!");
      setEmails([""]);
      setGlobalState({ ...globalState, showInviteModal: false });
  
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Error sending invites:", error);
      toast("Error sending invites. Please try again.");
    }
  };

  const handleSocialShare = (platform) => {
    let shareUrl = "";
    const text = `I just created an AI talent you can add to your team. Check it out! https://www.teammembers.ai/hire/${agent.roleSlug}/${agent.username}`

    switch (platform) {
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          text
        )}&url=${encodeURIComponent(agentUrl)}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          agentUrl
        )}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          agentUrl
        )}`;
        break;
      default:
        break;
    }

    if (shareUrl) window.open(shareUrl, "_blank");
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(agentUrl);
    toast("URL copied to clipboard!");
  };

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = emails.filter((_, i) => i !== index);
    setEmails(newEmails);
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  return (
    <Modal
      open={globalState?.showInviteModal}
      onClose={() => setGlobalState({ ...globalState, showInviteModal: false })}
      sx={styles.modal}
    >
      <Box sx={styles.modalContent(isMobile)}>
        <RedClose
          handleClose={() =>{
            setGlobalState({ ...globalState, showInviteModal: false })
            window.location.reload()
         } }
        />

        {showCongrats && (
          <Box sx={styles.congratsContainer}>
            <Typography variant="h5" sx={styles.title}>
              Congratulations on creating your AI Talent!
            </Typography>
            <Typography variant="body2" sx={styles.congratsSubtitle}>
              Share your AI with the world and start earning
            </Typography>
          </Box>
        )}

        {!showCongrats && (
          <Typography variant="h5" sx={styles.title}>
            Share Your AI Talent
          </Typography>
        )}

        <Box sx={styles.urlContainer}>
          <TextField
            fullWidth
            value={agentUrl}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Copy URL">
                    <IconButton
                      onClick={handleCopyUrl}
                      edge="end"
                      size="small"
                      sx={styles.copyButton}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            sx={styles.urlField}
          />
        </Box>

        <Box sx={styles.socialContainer}>
          <Tooltip title="Share on Twitter">
            <IconButton
              onClick={() => handleSocialShare("twitter")}
              sx={styles.socialButton("#1DA1F2")}
            >
              <TwitterIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share on Facebook">
            <IconButton
              onClick={() => handleSocialShare("facebook")}
              sx={styles.socialButton("#4267B2")}
            >
              <FacebookIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share on LinkedIn">
            <IconButton
              onClick={() => handleSocialShare("linkedin")}
              sx={styles.socialButton("#0077B5")}
            >
              <LinkedInIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Divider sx={styles.divider}>
          <Typography variant="body2" sx={styles.dividerText}>
            Or Invite via Email
          </Typography>
        </Divider>

        <form onSubmit={handleSubmit}>
          {emails.map((email, index) => (
            <Box key={index} sx={styles.emailContainer}>
              <TextField
                fullWidth
                label={`Email ${index + 1}`}
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                required
                sx={styles.emailField}
              />
              <IconButton
                onClick={() => handleRemoveEmail(index)}
                sx={styles.removeButton}
                disabled={emails.length === 1}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddEmail}
            sx={styles.addButton}
          >
            Add Another Email
          </Button>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={styles.submitButton}
          >
            Send Invites
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(5px)",
  },
  modalContent: (isMobile) => ({
    bgcolor: "background.paper",
    borderRadius: isMobile ? "0px" : "16px",
    width: isMobile ? "100%" : "500px",
    maxHeight: "90%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    p: 4,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "4px",
      background: "linear-gradient(90deg, #FF6B6B, #4ECDC4)",
    },
  }),
  congratsContainer: {
    mb: 3,
    textAlign: "center",
    animation: "fadeIn 0.5s ease-out",
    "@keyframes fadeIn": {
      from: { opacity: 0, transform: "translateY(-20px)" },
      to: { opacity: 1, transform: "translateY(0)" },
    },
  },
  congratsTitle: {
    fontWeight: 700,
    color: COLORS.success,
    mb: 1,
  },
  congratsSubtitle: {
    color: COLORS.lightGrey,
  },
  title: {
    mb: 3,
    fontWeight: 700,
    textAlign: "center",
    background: "linear-gradient(45deg, #FF6B6B, #4ECDC4)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  urlContainer: {
    mb: 3,
    position: "relative",
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: COLORS.primary,
      },
    },
  },
  urlField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#f8f8f8",
      transition: "all 0.3s ease",
    },
  },
  copyButton: {
    color: COLORS.primary,
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  socialContainer: {
    display: "flex",
    justifyContent: "center",
    mb: 3,
  },
  socialButton: (color) => ({
    mx: 1,
    color: color,
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: `0 4px 8px ${color}40`,
    },
  }),
  divider: {
    my: 3,
  },
  dividerText: {
    px: 2,
    color: COLORS.lightGrey,
  },
  emailContainer: {
    display: "flex",
    alignItems: "center",
    mb: 2,
  },
  emailField: {
    flex: 1,
    mr: 1,
  },
  removeButton: {
    color: COLORS.error,
    transition: "all 0.2s ease",
    "&:hover": {
      transform: "rotate(90deg)",
    },
  },
  addButton: {
    mb: 2,
    color: COLORS.primary,
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "rgba(33, 150, 243, 0.08)",
      transform: "translateY(-2px)",
    },
  },
  submitButton: {
    background: "linear-gradient(45deg, #FF6B6B, #4ECDC4)",
    color: "#fff",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "linear-gradient(45deg, #4ECDC4, #FF6B6B)",
      transform: "translateY(-2px)",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
  },
};

export default InviteModal;
