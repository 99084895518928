import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box, Typography } from "@mui/material";
import { database, firebase, firestore } from "../firebaseConfig";
import toast from "react-simple-toasts";
import UserContext from "../context/userContext";
import styled, { keyframes } from "styled-components";
import { COLORS } from "../utils/colors";
import { guidGenerator, getRandomBrightColor } from "../utils/helperMethods";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  background-color: ${COLORS.niceDeepBlack};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${COLORS.niceWhite};
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  text-align: center;
  z-index: 1;
  animation: ${fadeIn} 0.8s ease-out;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${COLORS.nicePink};
`;

const StyledTypography = styled(Typography)`
  margin-top: 2rem;
  font-weight: 600;
  color: ${COLORS.nicePurple};
`;

const AccentShape = styled.div`
  position: absolute;
  background-color: ${COLORS.nicePurple};
  width: 300px;
  height: 300px;
  border-radius: 50%;
  opacity: 0.05;
  top: -10%;
  left: -10%;
  animation: ${fadeIn} 1s ease-in 0.8s both;
`;

const FinishSignUp = () => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const createWelcomeNote = async (userId) => {
    const content =
      "<h1>Welcome to Your First Note!</h1><p>Type [[ to link notes together</p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>";
    const newNote = {
      id: guidGenerator(),
      title: "Welcome to Your First Note!",
      content: content,
      color: getRandomBrightColor(),
      slug: "welcome-to-your-first-note",
      createdAt: Date.now(),
      updatedAt: Date.now(),
      pinned: false,
    };

    try {
      await database.ref(`notes/${userId}/notes/${newNote.id}`).set(newNote);
    } catch (error) {
      console.error("Error creating welcome note:", error);
    }
  };

  useEffect(() => {
    const completeSignIn = async () => {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          email = window.prompt("Please provide your email for confirmation");
        }
        try {
          const result = await firebase
            .auth()
            .signInWithEmailLink(email, window.location.href);
          window.localStorage.removeItem("emailForSignIn");

          const userRef = firestore.collection("users").doc(result.user.uid);
          const userDoc = await userRef.get();

          if (userDoc.exists) {
            // User already exists, update last sign-in
            await userRef.update({
              lastSignIn: new Date().toISOString(),
            });

            // Fetch existing user data
            const userData = userDoc.data();

            // You might want to store this data in your app's state or context
            setUser((prevUser) => {
              return { ...prevUser, ...userData };
            });
          } else {
            // New user, create document
            await userRef.set({
              email: result.user.email,
              id: result.user.uid,
              createdAt: new Date().toISOString(),
              lastSignIn: new Date().toISOString(),
            });
          }

          // Check if user has any notes
          const notesRef = database.ref(`notes/${result.user.uid}/notes`);
          const notesSnapshot = await notesRef.once("value");
          const notesData = notesSnapshot.val();

          if (!notesData || Object.keys(notesData).length === 0) {
            // User has no notes, create welcome note
            await createWelcomeNote(result.user.uid);
          }

          toast("✅ Successfully signed in!");

          // Check if the user was on the KnowledgeMapPage before signing in
          const previousLocation = localStorage.getItem("previousLocation");
          localStorage.removeItem("previousLocation");
          // console.log("previousLocation", previousLocation);
          if (previousLocation) {
            // console.log("in here now", previousLocation);
            if(previousLocation === "/") {
              window.location.href="/"
            } else {
              navigate(previousLocation)
            }
          } else {
            // window.location.href = "/";
          }
        } catch (error) {
          console.error("Error completing sign-in:", error);
          // toast(`🚨 Error: ${error.message}`);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        navigate("/"); // Redirect to home if not a valid sign-in link
      }
    };

    completeSignIn();
  }, [navigate]);

  return (
    <Container>
      <AccentShape />
      <ContentWrapper>
        {loading ? (
          <>
            <StyledCircularProgress size={60} />
            <StyledTypography variant="h5">
              Finishing sign-in...
            </StyledTypography>
          </>
        ) : (
          <StyledTypography variant="h5">
            Sign-in completed! Redirecting...
          </StyledTypography>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default FinishSignUp;
