import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
} from "@mui/material";
import UserContext from "../context/userContext";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/colors";
import InviteModal from "../components/InviteModal";

const Profile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [profileUser, setProfileUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfileData = async () => {}

    fetchProfileData();
  }, [username]);


  if (loading || !profileUser) {
    return (
      <Box sx={styles.loadingContainer}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {globalState?.showInviteModal && (
        <InviteModal
          agent={profileUser}
          agentUrl={`https://www.teammembers.ai/profile/${profileUser.username}`}
        />
      )}
      <Box sx={styles.content}>
       
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "100vh",
    maxWidth: "1200px",
    margin: "0 auto",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  leftColumn: {
    flex: "0 0 300px",
    position: "sticky",
    top: 0,
    height: "100%",
    overflowY: "auto",
    padding: "40px 20px",
  },
  leftColumnContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "sticky",
    top: 40,
  },
  avatar: {
    width: 200,
    height: 200,
    marginBottom: 2,
  },
  name: {
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: 1.2,
    marginBottom: 0.5,
  },
  username: {
    color: COLORS.darkGrey,
    fontSize: "1.2rem",
    marginBottom: 1,
  },
  role: {
    color: COLORS.darkGrey,
    fontSize: "1rem",
    marginBottom: 2,
  },
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 2,
  },
  ratingNumber: {
    fontWeight: 700,
    marginRight: 1,
    color: COLORS.primary,
    fontSize: "1.5rem",
  },
  reviewCount: {
    display: "flex",
    alignItems: "center",
    color: COLORS.darkGrey,
    cursor: "help",
    marginLeft: 1,
  },
  primaryButton: {
    background: "#22C55E",
    color: "#FFFFFF",
    textTransform: "none",
    padding: "10px 20px",
    fontWeight: 600,
    fontSize: "1rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(34, 197, 94, 0.2)",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "#16A34A",
      boxShadow: "0 4px 6px rgba(34, 197, 94, 0.3)",
      transform: "translateY(-2px)",
    },
    marginBottom: 2,
  },
  rightColumn: {
    flex: 1,
    overflowY: "auto",
    padding: "40px 20px",
  },
  loadingContainer: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionHeader: {
    fontWeight: 700,
    marginBottom: 3,
    color: COLORS.primary,
  },
  agentCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  agentRole: {
    marginBottom: 1,
  },
  agentDescription: {
    marginBottom: 2,
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: 1,
    marginBottom: 2,
  },
  chip: {
    backgroundColor: COLORS.lightGrey,
    color: "white",
    "&:hover": {
      backgroundColor: COLORS.grey,
    },
  },
  agentInfoContainer: {
    marginTop: 'auto',
  },
  agentInfo: {
    display: "flex",
    alignItems: "center",
    marginBottom: 0.5,
  },
  inlineIcon: {
    verticalAlign: "middle",
    marginRight: 1,
    color: COLORS.primary,
    fontSize: "1rem",
  },
};

export default Profile;